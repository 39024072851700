.white-bg {
    background: #ffffff
}

.gray-bg {
    background: #f5f5f5
}

.gray-bg {
    background: #f7f7fd
}

.white-bg {
    background: #fff
}

.black-bg {
    background: #16161a
}

.theme-bg {
    background: #f76c5e
}

.brand-bg {
    background: #f1f4fa
}

.testimonial-bg {
    background: #f9fafc
}

.white-color {
    color: #fff
}

.black-color {
    color: #16161a
}

.theme-color {
    color: #f76c5e
}

.boxed-btn {
    background: #fff;
    color: #fff !important;
    display: inline-block;
    padding: 18px 44px;
    font-family: "Josefin Sans", sans-serif;
    font-size: 14px;
    font-weight: 400;
    border: 0;
    border: 1px solid #f76c5e;
    letter-spacing: 3px;
    text-align: center;
    text-transform: uppercase;
    cursor: pointer
}

.boxed-btn:hover {
    background: #f76c5e;
    color: #000 !important;
    border: 1px solid #f76c5e
}

.boxed-btn:focus {
    outline: none
}

.boxed-btn.large-width {
    width: 220px
}

[data-overlay] {
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center
}

[data-overlay]::before {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    content: ""
}

[data-opacity="1"]::before {
    opacity: 0.1
}

[data-opacity="2"]::before {
    opacity: 0.2
}

[data-opacity="3"]::before {
    opacity: 0.3
}

[data-opacity="4"]::before {
    opacity: 0.4
}

[data-opacity="5"]::before {
    opacity: 0.5
}

[data-opacity="6"]::before {
    opacity: 0.6
}

[data-opacity="7"]::before {
    opacity: 0.7
}

[data-opacity="8"]::before {
    opacity: 0.8
}

[data-opacity="9"]::before {
    opacity: 0.9
}

body {
    font-family: "Roboto", sans-serif;
    font-weight: normal;
    font-style: normal
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Josefin Sans", sans-serif;
    color: #404040;
    margin-top: 0px;
    font-style: normal;
    font-weight: 500;
    text-transform: normal
}

p {
    font-family: "Roboto", sans-serif;
    color: #777;
    font-size: 16px;
    line-height: 30px;
    margin-bottom: 15px;
    font-weight: normal;
}

span {
    font-family: "Josefin Sans", sans-serif;
}

.bg-img-1 {
    background-image: url(/src/assets/img/slider/slider-img-1.jpg);
}

.bg-img-2 {
    background-image: url(/src/assets/img/background-img/bg-img-2.jpg);
}

.cta-bg-1 {
    background-image: url(/src/assets/img/background-img/bg-img-3.jpg);
}

.img {
    max-width: 100%;
    -webkit-transition: all .3s ease-out 0s;
    -moz-transition: all .3s ease-out 0s;
    -ms-transition: all .3s ease-out 0s;
    -o-transition: all .3s ease-out 0s;
    transition: all .3s ease-out 0s
}

.f-left {
    float: left
}

.f-right {
    float: right
}

.fix {
    overflow: hidden
}

.clear {
    clear: both
}

a,
.button {
    -webkit-transition: all .3s ease-out 0s;
    -moz-transition: all .3s ease-out 0s;
    -ms-transition: all .3s ease-out 0s;
    -o-transition: all .3s ease-out 0s;
    transition: all .3s ease-out 0s
}

a:focus,
.button:focus {
    text-decoration: none;
    outline: none
}

ul {
    list-style: none;
    margin: 0;
    padding: 0
}

a {
    color: #635c5c
}

a:hover {
    color: #fff
}

a:focus,
a:hover,
.portfolio-cat a:hover,
.footer -menu li a:hover {
    text-decoration: none
}

a,
button {
    color: #fff;
    outline: medium none
}

button:focus,
input:focus,
input:focus,
textarea,
textarea:focus {
    outline: 0
}

.uppercase {
    text-transform: uppercase
}

input:focus::-moz-placeholder {
    opacity: 0;
    -webkit-transition: .4s;
    -o-transition: .4s;
    transition: .4s
}

.capitalize {
    text-transform: capitalize
}

.theme-overlay {
    position: relative
}

.theme-overlay::before {
    background: #1696e7 none repeat scroll 0 0;
    content: "";
    height: 100%;
    left: 0;
    opacity: 0.6;
    position: absolute;
    top: 0;
    width: 100%
}

.overlay {
    position: relative;
    z-index: 0
}

.overlay::before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1
}

.overlay2 {
    position: relative;
    z-index: 0
}

.overlay2::before {
    position: absolute;
    content: "";
    background-color: #2E2200;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 0.5
}

.section-padding {
    padding-top: 120px;
    padding-bottom: 120px
}

.separator {
    border-top: 1px solid #f2f2f2
}

.mb-90 {
    margin-bottom: 90px
}

@media (max-width: 767px) {
    .mb-90 {
        margin-bottom: 30px
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .mb-90 {
        margin-bottom: 45px
    }
}

/* N3 carrossel */

.carrossel {
    width: 100%;
    overflow: hidden;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.imagens {
    display: flex;
    height: 300px;
    width: 100%;
    position: relative;
}

#radio1:checked~.primeiro {
    margin-left: 0px;
    transition: all 1s ease-in;
}

#radio2:checked~.primeiro {
    margin-left: -1330px;
    transition: all 1s ease-in;
}

#radio3:checked~.primeiro {
    margin-left: -2660px;
    transition: all 1s ease-in;
}

.div-img-carrossel {
    width: 1330px;
}

#radio1,
#radio2,
#radio3 {
    display: none;
}

.navigation label {
    border: 2px solid white;
    border-radius: 50%;
    padding: 3px;
    cursor: pointer;
    transition: all .4s ease-in-out;
}

.navigation {
    display: flex;
    position: absolute;
    text-align: center;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 10px;

}

.navigation label:not(:last-child) {
    margin-right: 10px;
}

.img-atual {
    background-color: #fff;
    transition: all .4s ease-in-out;
}

#radio1:checked~.navigation .auto-btn1 {
    background-color: #fff;
    transition: all .4s ease-in-out;
}

#radio2:checked~.navigation .auto-btn2 {
    background-color: #fff;
    transition: all .4s ease-in-out;
}

#radio3:checked~.navigation .auto-btn3 {
    background-color: #fff;
    transition: all .4s ease-in-out;
}

/* N3 fim carrossel */

.owl-carousel .owl-nav div {
    background: rgba(255, 255, 255, 0.8) none repeat scroll 0 0;
    height: 40px;
    left: 20px;
    line-height: 40px;
    font-size: 22px;
    color: #646464;
    opacity: 1;
    visibility: visible;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translateY(-50%);
    transition: all 0.3s ease 0s;
    width: 40px
}

.owl-carousel .owl-nav div.owl-next {
    left: auto;
    right: -30px
}

.owl-carousel .owl-nav div.owl-next i {
    position: relative;
    right: 0;
    top: 1px
}

.owl-carousel .owl-nav div.owl-prev i {
    position: relative;
    right: 1px;
    top: 0px
}

.owl-carousel:hover .owl-nav div {
    opacity: 1;
    visibility: visible
}

.owl-carousel:hover .owl-nav div:hover {
    color: #fff;
    background: #ff3500
}

.btn {
    background: #f76c5e;
    font-family: "Josefin Sans", sans-serif;
    text-transform: uppercase;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.03em;
    padding: 30px 28px;
    border-radius: 0px;
    display: inline-block;
    line-height: 0;
    cursor: pointer;
    margin-bottom: 0;
    margin: 10px;
    cursor: pointer;
    transition: color 0.4s linear;
    position: relative;
    z-index: 1;
    -moz-user-select: none;
    border: 0;
    overflow: hidden;
    margin: 0
}

.btn::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #4a4a4b;
    z-index: 1;
    transition: transform 0.5s;
    transition-timing-function: ease;
    transform-origin: 0 0;
    transition-timing-function: cubic-bezier(0.5, 1.6, 0.4, 0.7);
    transform: scaleX(0);
    border-radius: 0px
}

.btn:hover::before {
    transform: scaleX(1);
    color: #fff !important;
    z-index: -1
}

.header-btn {
    padding: 25px 28px !important
}

@media (min-width: 1200px) and (max-width: 1400px) {
    .header-btn {
        padding: 25px 21px !important
    }
}

.btn.focus,
.btn:focus {
    outline: 0;
    box-shadow: none
}

.hero-btn {
    padding: 25px 29px;
    background: #4a4a4b
}

.hero-btn::before {
    background: #f76c5e
}

.black-btn {
    background: #000;
    box-shadow: 0px 20px 50px 0px rgba(22, 22, 22, 0.2);
    transform: translateY(0px);
    cursor: pointer;
    display: inline-block;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 1px;
    line-height: 0;
    margin-bottom: 0;
    padding: 27px 44px;
    border-radius: 25px
}

.black-btn:hover {
    box-shadow: 0px 20px 50px 0px rgba(12, 9, 9, 0.2);
    transform: translateY(-3px)
}

.shop1-btn {
    box-shadow: 0px 20px 50px 0px rgba(37, 119, 253, 0.2)
}

.border-btn {
    background: none;
    -moz-user-select: none;
    border: 2px solid #f76c5e;
    padding: 18px 38px;
    margin: 10px;
    text-transform: capitalize;
    color: #f76c5e;
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1px;
    margin-bottom: 0;
    border-radius: 5px;
    position: relative;
    transition: color 0.4s linear;
    position: relative;
    overflow: hidden;
    margin: 0
}

.border-btn::before {
    border: 2px solid transparent;
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #f76c5e;
    z-index: -1;
    transition: transform 0.5s;
    transition-timing-function: ease;
    transform-origin: 0 0;
    transition-timing-function: cubic-bezier(0.5, 1.6, 0.4, 0.7);
    transform: scaleY(0)
}

.border-btn:hover::before {
    transform: scaleY(1);
    order: 2px solid transparent
}

.border-btn.border-btn2 {
    padding: 17px 52px
}

.send-btn {
    background: #f76c5e;
    color: #fff;
    font-size: 14px;
    width: 100%;
    height: 55px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: color 0.4s linear;
    position: relative;
    overflow: hidden;
    z-index: 1
}

.send-btn::before {
    border: 2px solid transparent;
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #f76c5e;
    color: #e63d3d;
    z-index: -1;
    transition: transform 0.5s;
    transition-timing-function: ease;
    transform-origin: 0 0;
    transition-timing-function: cubic-bezier(0.5, 1.6, 0.4, 0.7);
    transform: scaleX(0)
}

.send-btn:hover::before {
    transform: scaleY(1);
    order: 2px solid transparent;
    color: red
}

.breadcrumb>.active {
    color: #888
}

#scrollUp {
    background: #f76c5e;
    height: 50px;
    width: 50px;
    right: 31px;
    bottom: 18px;
    color: #fff;
    font-size: 20px;
    text-align: center;
    border-radius: 50%;
    line-height: 48px;
    border: 2px solid transparent
}

@media (max-width: 575px) {
    #scrollUp {
        right: 16px
    }
}

#scrollUp:hover {
    color: #fff
}

.sticky-bar {
    left: 0;
    margin: auto;
    position: fixed;
    top: 0;
    width: 100%;
    -webkit-box-shadow: 0 10px 15px rgba(25, 25, 25, 0.1);
    box-shadow: 0 10px 15px rgba(25, 25, 25, 0.1);
    z-index: 9999;
    -webkit-animation: 300ms ease-in-out 0s normal none 1 running fadeInDown;
    animation: 300ms ease-in-out 0s normal none 1 running fadeInDown;
    -webkit-box-shadow: 0 10px 15px rgba(25, 25, 25, 0.1);
    background: #fff
}

.mt-5 {
    margin-top: 5px
}

.mt-10 {
    margin-top: 10px
}

.mt-15 {
    margin-top: 15px
}

.mt-20 {
    margin-top: 20px
}

.mt-25 {
    margin-top: 25px
}

.mt-30 {
    margin-top: 30px
}

.mt-35 {
    margin-top: 35px
}

.mt-40 {
    margin-top: 40px
}

.mt-45 {
    margin-top: 45px
}

.mt-50 {
    margin-top: 50px
}

.mt-55 {
    margin-top: 55px
}

.mt-60 {
    margin-top: 60px
}

.mt-65 {
    margin-top: 65px
}

.mt-70 {
    margin-top: 70px
}

.mt-75 {
    margin-top: 75px
}

.mt-80 {
    margin-top: 80px
}

.mt-85 {
    margin-top: 85px
}

.mt-90 {
    margin-top: 90px
}

.mt-95 {
    margin-top: 95px
}

.mt-100 {
    margin-top: 100px
}

.mt-105 {
    margin-top: 105px
}

.mt-110 {
    margin-top: 110px
}

.mt-115 {
    margin-top: 115px
}

.mt-120 {
    margin-top: 120px
}

.mt-125 {
    margin-top: 125px
}

.mt-130 {
    margin-top: 130px
}

.mt-135 {
    margin-top: 135px
}

.mt-140 {
    margin-top: 140px
}

.mt-145 {
    margin-top: 145px
}

.mt-150 {
    margin-top: 150px
}

.mt-155 {
    margin-top: 155px
}

.mt-160 {
    margin-top: 160px
}

.mt-165 {
    margin-top: 165px
}

.mt-170 {
    margin-top: 170px
}

.mt-175 {
    margin-top: 175px
}

.mt-180 {
    margin-top: 180px
}

.mt-185 {
    margin-top: 185px
}

.mt-190 {
    margin-top: 190px
}

.mt-195 {
    margin-top: 195px
}

.mt-200 {
    margin-top: 200px
}

.mb-5 {
    margin-bottom: 5px
}

.mb-10 {
    margin-bottom: 10px
}

.mb-15 {
    margin-bottom: 15px
}

.mb-20 {
    margin-bottom: 20px
}

.mb-25 {
    margin-bottom: 25px
}

.mb-30 {
    margin-bottom: 30px
}

.mb-35 {
    margin-bottom: 35px
}

.mb-40 {
    margin-bottom: 40px
}

.mb-45 {
    margin-bottom: 45px
}

.mb-50 {
    margin-bottom: 50px
}

.mb-55 {
    margin-bottom: 55px
}

.mb-60 {
    margin-bottom: 60px
}

.mb-65 {
    margin-bottom: 65px
}

.mb-70 {
    margin-bottom: 70px
}

.mb-75 {
    margin-bottom: 75px
}

.mb-80 {
    margin-bottom: 80px
}

.mb-85 {
    margin-bottom: 85px
}

.mb-90 {
    margin-bottom: 90px
}

.mb-95 {
    margin-bottom: 95px
}

.mb-100 {
    margin-bottom: 100px
}

.mb-105 {
    margin-bottom: 105px
}

.mb-110 {
    margin-bottom: 110px
}

.mb-115 {
    margin-bottom: 115px
}

.mb-120 {
    margin-bottom: 120px
}

.mb-125 {
    margin-bottom: 125px
}

.mb-130 {
    margin-bottom: 130px
}

.mb-135 {
    margin-bottom: 135px
}

.mb-140 {
    margin-bottom: 140px
}

.mb-145 {
    margin-bottom: 145px
}

.mb-150 {
    margin-bottom: 150px
}

.mb-155 {
    margin-bottom: 155px
}

.mb-160 {
    margin-bottom: 160px
}

.mb-165 {
    margin-bottom: 165px
}

.mb-170 {
    margin-bottom: 170px
}

.mb-175 {
    margin-bottom: 175px
}

.mb-180 {
    margin-bottom: 180px
}

.mb-185 {
    margin-bottom: 185px
}

.mb-190 {
    margin-bottom: 190px
}

.mb-195 {
    margin-bottom: 195px
}

.mb-200 {
    margin-bottom: 200px
}

.ml-5 {
    margin-left: 5px
}

.ml-10 {
    margin-left: 10px
}

.ml-15 {
    margin-left: 15px
}

.ml-20 {
    margin-left: 20px
}

.ml-25 {
    margin-left: 25px
}

.ml-30 {
    margin-left: 30px
}

.ml-35 {
    margin-left: 35px
}

.ml-40 {
    margin-left: 40px
}

.ml-45 {
    margin-left: 45px
}

.ml-50 {
    margin-left: 50px
}

.ml-55 {
    margin-left: 55px
}

.ml-60 {
    margin-left: 60px
}

.ml-65 {
    margin-left: 65px
}

.ml-70 {
    margin-left: 70px
}

.ml-75 {
    margin-left: 75px
}

.ml-80 {
    margin-left: 80px
}

.ml-85 {
    margin-left: 85px
}

.ml-90 {
    margin-left: 90px
}

.ml-95 {
    margin-left: 95px
}

.ml-100 {
    margin-left: 100px
}

.ml-105 {
    margin-left: 105px
}

.ml-110 {
    margin-left: 110px
}

.ml-115 {
    margin-left: 115px
}

.ml-120 {
    margin-left: 120px
}

.ml-125 {
    margin-left: 125px
}

.ml-130 {
    margin-left: 130px
}

.ml-135 {
    margin-left: 135px
}

.ml-140 {
    margin-left: 140px
}

.ml-145 {
    margin-left: 145px
}

.ml-150 {
    margin-left: 150px
}

.ml-155 {
    margin-left: 155px
}

.ml-160 {
    margin-left: 160px
}

.ml-165 {
    margin-left: 165px
}

.ml-170 {
    margin-left: 170px
}

.ml-175 {
    margin-left: 175px
}

.ml-180 {
    margin-left: 180px
}

.ml-185 {
    margin-left: 185px
}

.ml-190 {
    margin-left: 190px
}

.ml-195 {
    margin-left: 195px
}

.ml-200 {
    margin-left: 200px
}

.mr-5 {
    margin-right: 5px
}

.mr-10 {
    margin-right: 10px
}

.mr-15 {
    margin-right: 15px
}

.mr-20 {
    margin-right: 20px
}

.mr-25 {
    margin-right: 25px
}

.mr-30 {
    margin-right: 30px
}

.mr-35 {
    margin-right: 35px
}

.mr-40 {
    margin-right: 40px
}

.mr-45 {
    margin-right: 45px
}

.mr-50 {
    margin-right: 50px
}

.mr-55 {
    margin-right: 55px
}

.mr-60 {
    margin-right: 60px
}

.mr-65 {
    margin-right: 65px
}

.mr-70 {
    margin-right: 70px
}

.mr-75 {
    margin-right: 75px
}

.mr-80 {
    margin-right: 80px
}

.mr-85 {
    margin-right: 85px
}

.mr-90 {
    margin-right: 90px
}

.mr-95 {
    margin-right: 95px
}

.mr-100 {
    margin-right: 100px
}

.mr-105 {
    margin-right: 105px
}

.mr-110 {
    margin-right: 110px
}

.mr-115 {
    margin-right: 115px
}

.mr-120 {
    margin-right: 120px
}

.mr-125 {
    margin-right: 125px
}

.mr-130 {
    margin-right: 130px
}

.mr-135 {
    margin-right: 135px
}

.mr-140 {
    margin-right: 140px
}

.mr-145 {
    margin-right: 145px
}

.mr-150 {
    margin-right: 150px
}

.mr-155 {
    margin-right: 155px
}

.mr-160 {
    margin-right: 160px
}

.mr-165 {
    margin-right: 165px
}

.mr-170 {
    margin-right: 170px
}

.mr-175 {
    margin-right: 175px
}

.mr-180 {
    margin-right: 180px
}

.mr-185 {
    margin-right: 185px
}

.mr-190 {
    margin-right: 190px
}

.mr-195 {
    margin-right: 195px
}

.mr-200 {
    margin-right: 200px
}

.pt-5 {
    padding-top: 5px
}

.pt-10 {
    padding-top: 10px
}

.pt-15 {
    padding-top: 15px
}

.pt-20 {
    padding-top: 20px
}

.pt-25 {
    padding-top: 25px
}

.pt-30 {
    padding-top: 30px
}

.pt-35 {
    padding-top: 35px
}

.pt-40 {
    padding-top: 40px
}

.pt-45 {
    padding-top: 45px
}

.pt-50 {
    padding-top: 50px
}

.pt-55 {
    padding-top: 55px
}

.pt-60 {
    padding-top: 60px
}

.pt-65 {
    padding-top: 65px
}

.pt-70 {
    padding-top: 70px
}

.pt-75 {
    padding-top: 75px
}

.pt-80 {
    padding-top: 80px
}

.pt-85 {
    padding-top: 85px
}

.pt-90 {
    padding-top: 90px
}

.pt-95 {
    padding-top: 95px
}

.pt-100 {
    padding-top: 100px
}

.pt-105 {
    padding-top: 105px
}

.pt-110 {
    padding-top: 110px
}

.pt-115 {
    padding-top: 115px
}

.pt-120 {
    padding-top: 120px
}

.pt-125 {
    padding-top: 125px
}

.pt-130 {
    padding-top: 130px
}

.pt-135 {
    padding-top: 135px
}

.pt-140 {
    padding-top: 140px
}

.pt-145 {
    padding-top: 145px
}

.pt-150 {
    padding-top: 150px
}

.pt-155 {
    padding-top: 155px
}

.pt-160 {
    padding-top: 160px
}

.pt-165 {
    padding-top: 165px
}

.pt-170 {
    padding-top: 170px
}

.pt-175 {
    padding-top: 175px
}

.pt-180 {
    padding-top: 180px
}

.pt-185 {
    padding-top: 185px
}

.pt-190 {
    padding-top: 190px
}

.pt-195 {
    padding-top: 195px
}

.pt-200 {
    padding-top: 200px
}

.pt-260 {
    padding-top: 260px
}

.pb-5 {
    padding-bottom: 5px
}

.pb-10 {
    padding-bottom: 10px
}

.pb-15 {
    padding-bottom: 15px
}

.pb-20 {
    padding-bottom: 20px
}

.pb-25 {
    padding-bottom: 25px
}

.pb-30 {
    padding-bottom: 30px
}

.pb-35 {
    padding-bottom: 35px
}

.pb-40 {
    padding-bottom: 40px
}

.pb-45 {
    padding-bottom: 45px
}

.pb-50 {
    padding-bottom: 50px
}

.pb-55 {
    padding-bottom: 55px
}

.pb-60 {
    padding-bottom: 60px
}

.pb-65 {
    padding-bottom: 65px
}

.pb-70 {
    padding-bottom: 70px
}

.pb-75 {
    padding-bottom: 75px
}

.pb-80 {
    padding-bottom: 80px
}

.pb-85 {
    padding-bottom: 85px
}

.pb-90 {
    padding-bottom: 90px
}

.pb-95 {
    padding-bottom: 95px
}

.pb-100 {
    padding-bottom: 100px
}

.pb-105 {
    padding-bottom: 105px
}

.pb-110 {
    padding-bottom: 110px
}

.pb-115 {
    padding-bottom: 115px
}

.pb-120 {
    padding-bottom: 120px
}

.pb-125 {
    padding-bottom: 125px
}

.pb-130 {
    padding-bottom: 130px
}

.pb-135 {
    padding-bottom: 135px
}

.pb-140 {
    padding-bottom: 140px
}

.pb-145 {
    padding-bottom: 145px
}

.pb-150 {
    padding-bottom: 150px
}

.pb-155 {
    padding-bottom: 155px
}

.pb-160 {
    padding-bottom: 160px
}

.pb-165 {
    padding-bottom: 165px
}

.pb-170 {
    padding-bottom: 170px
}

.pb-175 {
    padding-bottom: 175px
}

.pb-180 {
    padding-bottom: 180px
}

.pb-185 {
    padding-bottom: 185px
}

.pb-190 {
    padding-bottom: 190px
}

.pb-195 {
    padding-bottom: 195px
}

.pb-200 {
    padding-bottom: 200px
}

.pl-5 {
    padding-left: 5px
}

.pl-10 {
    padding-left: 10px
}

.pl-15 {
    padding-left: 15px
}

.pl-20 {
    padding-left: 20px
}

.pl-25 {
    padding-left: 25px
}

.pl-30 {
    padding-left: 30px
}

.pl-35 {
    padding-left: 35px
}

.pl-40 {
    padding-left: 40px
}

.pl-45 {
    padding-left: 45px
}

.pl-50 {
    padding-left: 50px
}

.pl-55 {
    padding-left: 55px
}

.pl-60 {
    padding-left: 60px
}

.pl-65 {
    padding-left: 65px
}

.pl-70 {
    padding-left: 70px
}

.pl-75 {
    padding-left: 75px
}

.pl-80 {
    padding-left: 80px
}

.pl-85 {
    padding-left: 85px
}

.pl-90 {
    padding-left: 90px
}

.pl-95 {
    padding-left: 95px
}

.pl-100 {
    padding-left: 100px
}

.pl-105 {
    padding-left: 105px
}

.pl-110 {
    padding-left: 110px
}

.pl-115 {
    padding-left: 115px
}

.pl-120 {
    padding-left: 120px
}

.pl-125 {
    padding-left: 125px
}

.pl-130 {
    padding-left: 130px
}

.pl-135 {
    padding-left: 135px
}

.pl-140 {
    padding-left: 140px
}

.pl-145 {
    padding-left: 145px
}

.pl-150 {
    padding-left: 150px
}

.pl-155 {
    padding-left: 155px
}

.pl-160 {
    padding-left: 160px
}

.pl-165 {
    padding-left: 165px
}

.pl-170 {
    padding-left: 170px
}

.pl-175 {
    padding-left: 175px
}

.pl-180 {
    padding-left: 180px
}

.pl-185 {
    padding-left: 185px
}

.pl-190 {
    padding-left: 190px
}

.pl-195 {
    padding-left: 195px
}

.pl-200 {
    padding-left: 200px
}

.pr-5 {
    padding-right: 5px
}

.pr-10 {
    padding-right: 10px
}

.pr-15 {
    padding-right: 15px
}

.pr-20 {
    padding-right: 20px
}

.pr-25 {
    padding-right: 25px
}

.pr-30 {
    padding-right: 30px
}

.pr-35 {
    padding-right: 35px
}

.pr-40 {
    padding-right: 40px
}

.pr-45 {
    padding-right: 45px
}

.pr-50 {
    padding-right: 50px
}

.pr-55 {
    padding-right: 55px
}

.pr-60 {
    padding-right: 60px
}

.pr-65 {
    padding-right: 65px
}

.pr-70 {
    padding-right: 70px
}

.pr-75 {
    padding-right: 75px
}

.pr-80 {
    padding-right: 80px
}

.pr-85 {
    padding-right: 85px
}

.pr-90 {
    padding-right: 90px
}

.pr-95 {
    padding-right: 95px
}

.pr-100 {
    padding-right: 100px
}

.pr-105 {
    padding-right: 105px
}

.pr-110 {
    padding-right: 110px
}

.pr-115 {
    padding-right: 115px
}

.pr-120 {
    padding-right: 120px
}

.pr-125 {
    padding-right: 125px
}

.pr-130 {
    padding-right: 130px
}

.pr-135 {
    padding-right: 135px
}

.pr-140 {
    padding-right: 140px
}

.pr-145 {
    padding-right: 145px
}

.pr-150 {
    padding-right: 150px
}

.pr-155 {
    padding-right: 155px
}

.pr-160 {
    padding-right: 160px
}

.pr-165 {
    padding-right: 165px
}

.pr-170 {
    padding-right: 170px
}

.pr-175 {
    padding-right: 175px
}

.pr-180 {
    padding-right: 180px
}

.pr-185 {
    padding-right: 185px
}

.pr-190 {
    padding-right: 190px
}

.pr-195 {
    padding-right: 195px
}

.pr-200 {
    padding-right: 200px
}

.bounce-animate {
    animation-name: float-bob;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    -moz-animation-name: float-bob;
    -moz-animation-duration: 2s;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
    -ms-animation-name: float-bob;
    -ms-animation-duration: 2s;
    -ms-animation-iteration-count: infinite;
    -ms-animation-timing-function: linear;
    -o-animation-name: float-bob;
    -o-animation-duration: 2s;
    -o-animation-iteration-count: infinite;
    -o-animation-timing-function: linear
}

@-webkit-keyframes float-bob {
    0% {
        -webkit-transform: translateY(10px);
        transform: translateY(-10px)
    }

    50% {
        -webkit-transform: translateY(-5px);
        transform: translateY(-5px)
    }

    100% {
        -webkit-transform: translateY(-10px);
        transform: translateY(-10px)
    }
}

.heartbeat {
    animation: heartbeat 1s infinite alternate
}

@-webkit-keyframes heartbeat {
    to {
        -webkit-transform: scale(1.03);
        transform: scale(1.03)
    }
}

.rotateme {
    -webkit-animation-name: rotateme;
    animation-name: rotateme;
    -webkit-animation-duration: 30s;
    animation-duration: 30s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear
}

@keyframes rotateme {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
    }

    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@-webkit-keyframes rotateme {
    from {
        -webkit-transform: rotate(0deg)
    }

    to {
        -webkit-transform: rotate(360deg)
    }
}

.preloader {
    background-color: #f7f7f7;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999999;
    -webkit-transition: .6s;
    -o-transition: .6s;
    transition: .6s;
    margin: 0 auto
}

.preloader .preloader-circle {
    width: 100px;
    height: 100px;
    position: relative;
    border-style: solid;
    border-width: 1px;
    border-top-color: #f76c5e;
    border-bottom-color: transparent;
    border-left-color: transparent;
    border-right-color: transparent;
    z-index: 10;
    border-radius: 50%;
    -webkit-box-shadow: 0 1px 5px 0 rgba(35, 181, 185, 0.15);
    box-shadow: 0 1px 5px 0 rgba(35, 181, 185, 0.15);
    background-color: #ffffff;
    -webkit-animation: zoom 2000ms infinite ease;
    animation: zoom 2000ms infinite ease;
    -webkit-transition: .6s;
    -o-transition: .6s;
    transition: .6s
}

.preloader .preloader-circle2 {
    border-top-color: #0078ff
}

.preloader .preloader-img {
    position: absolute;
    top: 50%;
    z-index: 200;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
    display: inline-block;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    padding-top: 6px;
    -webkit-transition: .6s;
    -o-transition: .6s;
    transition: .6s
}

.preloader .preloader-img img {
    max-width: 55px
}

.preloader .pere-text strong {
    font-weight: 800;
    color: #dca73a;
    text-transform: uppercase
}

@-webkit-keyframes zoom {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: .6s;
        -o-transition: .6s;
        transition: .6s
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
        -webkit-transition: .6s;
        -o-transition: .6s;
        transition: .6s
    }
}

@keyframes zoom {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: .6s;
        -o-transition: .6s;
        transition: .6s
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
        -webkit-transition: .6s;
        -o-transition: .6s;
        transition: .6s
    }
}

.section-padding2 {
    padding-top: 200px;
    padding-bottom: 200px
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
    .section-padding2 {
        padding-top: 200px;
        padding-bottom: 200px
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .section-padding2 {
        padding-top: 200px;
        padding-bottom: 200px
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .section-padding2 {
        padding-top: 100px;
        padding-bottom: 100px
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .section-padding2 {
        padding-top: 50px;
        padding-bottom: 50px
    }
}

@media (max-width: 575px) {
    .section-padding2 {
        padding-top: 50px;
        padding-bottom: 50px
    }
}

.padding-bottom {
    padding-bottom: 250px
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
    .padding-bottom {
        padding-bottom: 250px
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .padding-bottom {
        padding-bottom: 150px
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .padding-bottom {
        padding-bottom: 40px
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .padding-bottom {
        padding-bottom: 10px
    }
}

@media (max-width: 575px) {
    .padding-bottom {
        padding-bottom: 10px
    }
}

.lf-padding {
    padding-left: 60px;
    padding-right: 60px
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .lf-padding {
        padding-left: 60px;
        padding-right: 60px
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .lf-padding {
        padding-left: 30px;
        padding-right: 30px
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .lf-padding {
        padding-left: 15px;
        padding-right: 15px
    }
}

@media (max-width: 575px) {
    .lf-padding {
        padding-left: 15px;
        padding-right: 15px
    }
}

.team-padding {
    padding-top: 160px;
    padding-bottom: 130px
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
    .team-padding {
        padding-top: 160px;
        padding-bottom: 130px
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .team-padding {
        padding-top: 110px;
        padding-bottom: 80px
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .team-padding {
        padding-top: 100px;
        padding-bottom: 80px
    }
}

@media (max-width: 575px) {
    .team-padding {
        padding-top: 100px;
        padding-bottom: 80px
    }
}

.section-padding30 {
    padding-top: 30px;
    padding-bottom: 30px
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
    .section-padding30 {
        padding-top: 30px;
        padding-bottom: 30px
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .section-padding30 {
        padding-top: 30px;
        padding-bottom: 30px
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .section-padding30 {
        padding-top: 30px;
        padding-bottom: 30px
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .section-padding30 {
        padding-top: 60px;
        padding-bottom: 28px
    }
}

@media (max-width: 575px) {
    .section-padding30 {
        padding-top: 60px;
        padding-bottom: 28px
    }
}

.section-paddingt2 {
    padding-bottom: 170px
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
    .section-paddingt2 {
        padding-bottom: 170px
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .section-paddingt2 {
        padding-bottom: 120px
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .section-paddingt2 {
        padding-bottom: 70px
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .section-paddingt2 {
        padding-bottom: 28px
    }
}

@media (max-width: 575px) {
    .section-paddingt2 {
        padding-bottom: 28px
    }
}

.section-padd-top30 {
    padding-top: 170px;
    padding-bottom: 200px
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
    .section-padd-top30 {
        padding-top: 170px;
        padding-bottom: 200px
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .section-padd-top30 {
        padding-top: 170px;
        padding-bottom: 200px
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .section-padd-top30 {
        padding-top: 100px;
        padding-bottom: 160px
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .section-padd-top30 {
        padding-top: 50px;
        padding-bottom: 90px
    }
}

@media (max-width: 575px) {
    .section-padd-top30 {
        padding-top: 35px;
        padding-bottom: 90px
    }
}

.latest-padding {
    padding-top: 190px;
    padding-bottom: 135px
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
    .latest-padding {
        padding-top: 190px;
        padding-bottom: 135px
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .latest-padding {
        padding-top: 190px;
        padding-bottom: 135px
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .latest-padding {
        padding-top: 60px;
        padding-bottom: 5px
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .latest-padding {
        padding-top: 60px;
        padding-bottom: 5px
    }
}

@media (max-width: 575px) {
    .latest-padding {
        padding-top: 60px;
        padding-bottom: 5px
    }
}

.footer-padding {
    padding-top: 30px;
    padding-bottom: 15px
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
    .footer-padding {
        padding-top: 50px;
        padding-bottom: 0px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .footer-padding {
        padding-top: 50px;
        padding-bottom: 0px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .footer-padding {
        padding-top: 50px;
        padding-bottom: 0px;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .footer-padding {
        padding-top: 50px;
        padding-bottom: 0px;
    }
}

@media (max-width: 575px) {
    .footer-padding {
        padding-top: 50px;
        padding-bottom: 0px;
    }
}

.footer-padding2 {
    padding-bottom: 120px
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
    .footer-padding2 {
        padding-bottom: 120px
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .footer-padding2 {
        padding-bottom: 70px
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .footer-padding2 {
        padding-bottom: 70px
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .footer-padding2 {
        padding-bottom: 70px
    }
}

@media (max-width: 575px) {
    .footer-padding2 {
        padding-bottom: 70px
    }
}

.message_submit_form:focus {
    outline: none
}

input:hover,
input:focus {
    outline: none !important
}

.gray_bg {
    background-color: #f4f4f4
}

.section_padding {
    padding: 15px 0px
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .section_padding {
        padding: 100px 0px
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .section_padding {
        padding: 70px 0px
    }
}

@media (max-width: 576px) {
    .section_padding {
        padding: 70px 0px
    }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
    .section_padding {
        padding: 70px 0px
    }
}

.single_padding_top {
    padding-top: 200px !important
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .single_padding_top {
        padding-top: 70px !important
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .single_padding_top {
        padding-top: 70px !important
    }
}

@media (max-width: 576px) {
    .single_padding_top {
        padding-top: 70px !important
    }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
    .single_padding_top {
        padding-top: 100px !important
    }
}

.padding_top {
    padding-top: 200px
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .padding_top {
        padding-top: 100px
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .padding_top {
        padding-top: 70px
    }
}

@media (max-width: 576px) {
    .padding_top {
        padding-top: 70px
    }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
    .padding_top {
        padding-top: 70px
    }
}

.padding_bottom {
    padding-bottom: 200px
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .padding_bottom {
        padding-bottom: 100px
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .padding_bottom {
        padding-bottom: 70px
    }
}

@media (max-width: 576px) {
    .padding_bottom {
        padding-bottom: 70px
    }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
    .padding_bottom {
        padding-bottom: 70px
    }
}

.section_tittle {
    margin-bottom: 80px
}

@media (max-width: 991px) {
    .section_tittle {
        margin-bottom: 20px
    }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .section_tittle {
        margin-bottom: 40px
    }
}

.section_tittle h2 {
    font-size: 36px;
    color: #4B3049;
    line-height: 27px;
    font-weight: 700;
    position: relative;
    margin-bottom: 28px
}

@media (max-width: 576px) {
    .section_tittle h2 {
        font-size: 25px;
        line-height: 35px;
        margin-bottom: 15px
    }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
    .section_tittle h2 {
        font-size: 25px;
        line-height: 35px;
        margin-bottom: 15px
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .section_tittle h2 {
        font-size: 30px;
        line-height: 40px;
        margin-bottom: 15px
    }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .section_tittle h2 {
        font-size: 35px;
        line-height: 40px
    }
}

.section_tittle p {
    color: #556172;
    text-transform: capitalize;
    line-height: 30px;
    font-family: "Poppins", sans-serif
}

@media (max-width: 576px) {
    .section_tittle p {
        margin-bottom: 10px
    }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
    .section_tittle p {
        margin-bottom: 10px
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .section_tittle p {
        margin-bottom: 10px
    }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .section_tittle p {
        margin-bottom: 10px
    }
}

.mb_110 {
    margin-bottom: 110px
}

@media (max-width: 576px) {
    .mb_110 {
        margin-bottom: 220px
    }
}

.mt_130 {
    margin-top: 130px
}

@media (max-width: 576px) {
    .mt_130 {
        margin-top: 70px
    }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
    .mt_130 {
        margin-top: 70px
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .mt_130 {
        margin-top: 70px
    }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .mt_130 {
        margin-top: 70px
    }
}

.mb_130 {
    margin-bottom: 140px
}

@media (max-width: 991px) {
    .mb_130 {
        margin-bottom: 70px
    }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .mb_130 {
        margin-bottom: 100px
    }
}

.padding_less_40 {
    margin-bottom: -50px
}

.z_index {
    z-index: 9 !important;
    position: relative
}

@media only screen and (min-width: 1200px) and (max-width: 3640px) {
    .container {
        max-width: 1170px
    }
}

@media (max-width: 1200px) {
    [class*="hero-ani-"] {
        display: none !important
    }
}

.form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #ddd;
    outline: 0;
    box-shadow: none
}

.pageination .page-link {
    border: 0px solid transparent;
    font-weight: 500;
    font-size: 18px;
    color: #979797;
    padding: 10px 8px
}

.pageination .page-link:hover {
    background-color: transparent;
    color: #000
}

.pageination i {
    font-size: 12px
}

.sidebar_box_shadow {
    box-shadow: 0px 10px 10px 0px rgba(153, 153, 153, 0.1)
}

.border_top {
    border-top: 1px solid #eee;
    max-width: 1110px;
    margin: 0 auto
}

.section_bg {
    background-color: #f4edf278
}

.section-bg {
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: center center !important
}

.white-bg {
    background: #ffffff
}

.gray-bg {
    background: #f5f5f5
}

.gray-bg {
    background: #f7f7fd
}

.white-bg {
    background: #fff
}

.black-bg {
    background: #16161a
}

.theme-bg {
    background: #f76c5e
}

.brand-bg {
    background: #f1f4fa
}

.testimonial-bg {
    background: #f9fafc
}

.white-color {
    color: #fff
}

.black-color {
    color: #16161a
}

.theme-color {
    color: #f76c5e
}

.header-area .main-header {
    padding: 0px 120px
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
    .header-area .main-header {
        padding: 0px 10px
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .header-area .main-header {
        padding: 0px 10px
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .header-area .main-header {
        padding: 22px 0px
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .header-area .main-header {
        padding: 22px 0px
    }
}

@media (max-width: 575px) {
    .header-area .main-header {
        padding: 22px 0px
    }
}

.header-area .main-header .menu-wrapper {
    display: flex;
    align-content: center;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center
}

.header-area .main-header .menu-wrapper .main-menu ul li {
    display: inline-block;
    position: relative;
    z-index: 1
}

.header-area .main-header .menu-wrapper .main-menu ul li a {
    font-size: 16px;
    font-family: "Josefin Sans", sans-serif;
    color: #595959;
    font-weight: 500;
    padding: 41px 21px;
    display: inline-block;
    text-transform: capitalize;
    display: block;
    -webkit-transition: all .3s ease-out 0s;
    -moz-transition: all .3s ease-out 0s;
    -ms-transition: all .3s ease-out 0s;
    -o-transition: all .3s ease-out 0s;
    transition: all .3s ease-out 0s
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .header-area .main-header .menu-wrapper .main-menu ul li a {
        padding: 41px 15px
    }
}

.header-area .main-header .menu-wrapper .main-menu ul li:hover>a {
    color: #f76c5e
}

.header-area .main-header .menu-wrapper .main-menu ul li.hot {
    position: relative
}

.header-area .main-header .menu-wrapper .main-menu ul li.hot::before {
    position: absolute;
    content: "HOT";
    background: #ff003c;
    color: #fff;
    text-align: center;
    border-radius: 8px;
    font-size: 10px;
    top: 19px;
    right: -13px;
    -webkit-transition: all .2s ease-out 0s;
    -moz-transition: all .2s ease-out 0s;
    -ms-transition: all .2s ease-out 0s;
    -o-transition: all .2s ease-out 0s;
    transition: all .2s ease-out 0s;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    padding: 3px 8px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 500
}

.header-area .main-header .menu-wrapper .main-menu ul li:hover>ul.submenu {
    visibility: visible;
    opacity: 1;
    top: 100%
}

.header-area .main-header .menu-wrapper .main-menu ul li:hover>ul.submenu::before {
    top: -8px
}

.header-area .main-header .menu-wrapper .main-menu ul ul.submenu {
    position: absolute;
    width: 170px;
    background: #fff;
    left: 0;
    top: 90%;
    visibility: hidden;
    opacity: 0;
    box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.05);
    padding: 17px 0;
    border-top: 3px solid #f76c5e;
    border-radius: 7px 7px 3px 3px;
    -webkit-transition: all .2s ease-out 0s;
    -moz-transition: all .2s ease-out 0s;
    -ms-transition: all .2s ease-out 0s;
    -o-transition: all .2s ease-out 0s;
    transition: all .2s ease-out 0s
}

.header-area .main-header .menu-wrapper .main-menu ul ul.submenu>li {
    margin-left: 7px;
    display: block
}

.header-area .main-header .menu-wrapper .main-menu ul ul.submenu>li>a {
    padding: 6px 10px !important;
    font-size: 16px;
    color: #0b1c39;
    text-transform: capitalize
}

.header-area .main-header .menu-wrapper .main-menu ul ul.submenu>li>a:hover {
    color: #f76c5e;
    background: none
}

.header-area .main-header .menu-wrapper .main-menu ul ul.submenu::before {
    border-style: solid;
    border-width: 0 6px 6px 6px;
    border-color: transparent transparent red transparent;
    content: "";
    top: -5px;
    left: 13%;
    position: absolute;
    transition: .3s;
    z-index: -1;
    overflow: hidden;
    -webkit-transition: all .3s ease-out 0s;
    -moz-transition: all .3s ease-out 0s;
    -ms-transition: all .3s ease-out 0s;
    -o-transition: all .3s ease-out 0s;
    transition: all .3s ease-out 0s
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .header-area .main-header .menu-wrapper .header-right {
        margin-right: 82px
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .header-area .main-header .menu-wrapper .header-right {
        margin-right: 73px
    }
}

@media (max-width: 575px) {
    .header-area .main-header .menu-wrapper .header-right {
        position: relative;
        bottom: -73px;
        z-index: 3;
        right: 5px
    }
}

.header-area .main-header .menu-wrapper .header-right ul {
    display: flex !important;
    justify-content: space-between
}

.header-area .main-header .menu-wrapper .header-right .nav-search {
    display: inline-block
}

.header-area .main-header .menu-wrapper .header-right span {
    color: #000000;
    font-size: 16px;
    cursor: pointer;
    padding: 31px 19px
}

.header-area .main-header .menu-wrapper .header-right span:hover {
    color: #f76c5e
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .header-area .main-header .menu-wrapper .header-right span {
        padding: 21px 19px
    }
}

@media (max-width: 575px) {
    .header-area .main-header .menu-wrapper .header-right span {
        padding: 6px 9px
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .header-sticky.sticky-bar.sticky {
        padding: 22px 0px
    }
}

@media (max-width: 575px) {
    .header-sticky.sticky-bar.sticky .header-right {
        display: none
    }
}

.mobile_menu {
    position: absolute;
    right: 0px;
    width: 100%;
    z-index: 99
}

.mobile_menu .slicknav_menu {
    background: transparent;
    margin-top: 0px !important
}

.mobile_menu .slicknav_menu .slicknav_btn {
    top: -25px
}

.mobile_menu .slicknav_menu .slicknav_btn .slicknav_icon-bar {
    background-color: #f76c5e !important
}

.mobile_menu .slicknav_menu .slicknav_nav {
    margin-top: 16px !important
}

.mobile_menu .slicknav_menu .slicknav_nav a:hover {
    background: transparent;
    color: #f76c5e
}

.mobile_menu .slicknav_menu .slicknav_nav a {
    font-size: 15px;
    padding: 7px 10px
}

.mobile_menu .slicknav_menu .slicknav_nav .slicknav_item a {
    padding: 0 !important
}

.search-model-box {
    display: none;
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: #000;
    z-index: 99999
}

.search-model-box .search-model-form {
    padding: 0 15px
}

.search-model-box .search-model-form input {
    width: 500px;
    font-size: 40px;
    border: none;
    border-bottom: 2px solid #333;
    background: none;
    color: #999
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .search-model-box .search-model-form input {
        width: 251px;
        font-size: 26px
    }
}

@media (max-width: 575px) {
    .search-model-box .search-model-form input {
        width: 251px;
        font-size: 26px
    }
}

.search-model-box .search-close-btn {
    position: absolute;
    width: 50px;
    height: 50px;
    background: #333;
    color: #fff;
    text-align: center;
    border-radius: 50%;
    font-size: 28px;
    line-height: 28px;
    top: 30px;
    cursor: pointer;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.slider-height {
    height: 400px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
    .slider-height {
        min-height: 750px
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .slider-height {
        min-height: 500px
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .slider-height {
        min-height: 500px
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .slider-height {
        min-height: 400px
    }
}

@media (max-width: 575px) {
    .slider-height {
        min-height: 430px
    }
}

.slider-height2 {
    background: #ec4737 !important;
    height: 180px !important;
}

@media (max-width: 575px) {
    .slider-height2 {
        height: 360px !important;
    }
}

.slider-area {
    overflow: hidden
}

.slider-area .hero__img {
    position: relative
}

@media (min-width: 1200px) and (max-width: 1400px) {
    .slider-area .hero__img {
        left: -104px
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .slider-area .hero__img img {
        height: 500px
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .slider-area .hero__img img {
        height: 500px
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .slider-area .hero__img img {
        height: 400px
    }
}

.slider-area .hero__caption {
    overflow: hidden
}

@media (max-width: 575px) {
    .slider-area .hero__caption {
        padding-top: 38px;
        padding-left: 22px
    }
}

.slider-area .hero__caption h1 {
    text-transform: normal;
    font-size: 96px;
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: 26px
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .slider-area .hero__caption h1 {
        font-size: 60px;
        line-height: 1.2
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .slider-area .hero__caption h1 {
        font-size: 50px;
        line-height: 1.2
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .slider-area .hero__caption h1 {
        font-size: 35px;
        line-height: 1.2;
        margin-bottom: 26px
    }
}

@media (max-width: 575px) {
    .slider-area .hero__caption h1 {
        font-size: 33px;
        line-height: 1.2;
        margin-bottom: 26px
    }
}

.slider-area .hero__caption p {
    font-size: 17px;
    line-height: 1.6;
    font-weight: 400;
    margin-bottom: 39px;
    color: #212025;
    margin-bottom: 60px;
    padding-right: 50px
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .slider-area .hero__caption p {
        padding-right: 0px;
        margin-bottom: 30px
    }
}

@media (max-width: 575px) {
    .slider-area .hero__caption p {
        margin-bottom: 30px;
        padding-right: 0px;
        margin-bottom: 30px
    }
}

.slider-area .hero-cap h2 {
    font-size: 50px;
    font-weight: 700;
    color: #fff !important;
}

@media (max-width: 575px) {
    .slider-area .hero-cap h2 {
        font-size: 28px
    }
}

.slide-bg {
    background: #f0f0f2
}

.slide-bg2 {
    background: #fd8275
}

.slider-active button.slick-arrow {
    position: absolute;
    top: 50%;
    left: 80px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    background: none;
    border: 0;
    padding: 0;
    z-index: 2;
    opacity: 0;
    visibility: hidden;
    height: 60px;
    width: 60px;
    border-radius: 50%;
    cursor: pointer;
    background: #f76c5e;
    -webkit-transition: .4s;
    -o-transition: .4s;
    transition: .4s
}

.slider-active button.slick-arrow i {
    font-size: 20px;
    line-height: 60px
}

.slider-active button.slick-next {
    left: auto;
    right: 80px
}

.slider-active button.slick-arrow .secondary-img {
    right: 26px
}

.slider-active button:hover img {
    opacity: 0
}

.slider-active button:hover .secondary-img {
    opacity: 1
}

.slider-active:hover button.slick-prev {
    left: 100px
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
    .slider-active:hover button.slick-prev {
        left: 20px
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .slider-active:hover button.slick-prev {
        left: auto;
        right: 10px
    }
}

.slider-active:hover button.slick-next {
    right: 100px
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
    .slider-active:hover button.slick-next {
        right: 20px
    }
}

.slider-active:hover button {
    opacity: 1;
    visibility: visible
}

.slider-active button:hover {
    background: #002d5b
}

.slider-active .secondary-img {
    position: absolute;
    opacity: 0
}

.single-new-pro .product-img {
    overflow: hidden;
    margin-bottom: 30px
}

.single-new-pro .product-img img {
    width: 100%;
    transform: scale(1);
    -webkit-transition: all .4s ease-out 0s;
    -moz-transition: all .4s ease-out 0s;
    -ms-transition: all .4s ease-out 0s;
    -o-transition: all .4s ease-out 0s;
    transition: all .4s ease-out 0s
}

.single-new-pro .product-caption h3 a {
    color: #444444;
    font-weight: 700;
    font-size: 24px;
    margin-bottom: 12px
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .single-new-pro .product-caption h3 a {
        font-size: 17px
    }
}

@media (max-width: 575px) {
    .single-new-pro .product-caption h3 a {
        font-size: 20px
    }
}

.single-new-pro .product-caption span {
    color: #f76c5e;
    font-size: 18px;
    font-weight: 500;
    display: block
}

.single-new-pro:hover .product-img img {
    transform: scale(1.04)
}

.gallery-area .single-gallery {
    position: relative;
    overflow: hidden
}

.gallery-area .single-gallery::before {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    content: "";
    background-color: rgba(1, 10, 28, 0.3);
    opacity: 0;
    z-index: 1;
    -webkit-transition: all .4s ease-out 0s;
    -moz-transition: all .4s ease-out 0s;
    -ms-transition: all .4s ease-out 0s;
    -o-transition: all .4s ease-out 0s;
    transition: all .4s ease-out 0s
}

.gallery-area .single-gallery:hover::before {
    opacity: 1;
    visibility: visible
}

.gallery-area .single-gallery .gallery-img {
    transform: scale(1.06);
    -webkit-transition: all .4s ease-out 0s;
    -moz-transition: all .4s ease-out 0s;
    -ms-transition: all .4s ease-out 0s;
    -o-transition: all .4s ease-out 0s;
    transition: all .4s ease-out 0s
}

.gallery-area .single-gallery .big-img {
    position: relative;
    height: 690px;
    background-size: cover;
    background-repeat: no-repeat
}

.gallery-area .single-gallery .small-img {
    position: relative;
    height: 330px;
    background-size: cover;
    background-repeat: no-repeat
}

.single-gallery:hover .gallery-img {
    transform: scale(1)
}

.popular-items .single-popular-items .popular-img {
    overflow: hidden;
    border-bottom: 3px solid #f76c5e;
    margin-bottom: 19px;
    position: relative
}

.popular-items .single-popular-items .popular-img img {
    width: 100%
}

.popular-items .single-popular-items .popular-img .img-cap {
    position: absolute;
    bottom: -20%;
    width: 100%;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all .4s ease-out 0s;
    -moz-transition: all .4s ease-out 0s;
    -ms-transition: all .4s ease-out 0s;
    -o-transition: all .4s ease-out 0s;
    transition: all .4s ease-out 0s
}

.popular-items .single-popular-items .popular-img .img-cap span {
    color: #fff;
    background: #f81f1f;
    padding: 20px 0;
    display: block;
    cursor: pointer
}

.popular-items .single-popular-items .popular-img .favorit-items {
    position: absolute;
    top: 12px;
    right: 20px;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all .4s ease-out 0s;
    -moz-transition: all .4s ease-out 0s;
    -ms-transition: all .4s ease-out 0s;
    -o-transition: all .4s ease-out 0s;
    transition: all .4s ease-out 0s
}

.popular-items .single-popular-items .popular-img .favorit-items span {
    color: #333333;
    font-size: 30px;
    cursor: pointer
}

.popular-items .single-popular-items .popular-caption h3 a {
    color: #444444;
    font-weight: 700;
    font-size: 24px;
    margin-bottom: 12px
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .popular-items .single-popular-items .popular-caption h3 a {
        font-size: 17px
    }
}

@media (max-width: 575px) {
    .popular-items .single-popular-items .popular-caption h3 a {
        font-size: 20px
    }
}

.popular-items .single-popular-items .popular-caption span {
    color: #444444;
    font-size: 18px;
    font-weight: 500;
    display: block
}

.popular-items .nav-tabs {
    margin-bottom: 15px;
    padding-bottom: 0px;
    position: relative;
    border: 0;
    display: flex;
    justify-content: center
}

.popular-items .nav-tabs .nav-link {
    border: 0;
    border-bottom: 2px solid transparent
}

@media (max-width: 575px) {
    .popular-items .nav-tabs .nav-link {
        padding: .5rem .6rem
    }
}

.popular-items .nav-tabs .nav-item {
    padding-bottom: 10px;
    display: block;
    color: #1b1b1b;
    text-transform: capitalize;
    font-size: 16px
}

.popular-items .nav-tabs .nav-item.show .nav-link,
.popular-items .nav-tabs .nav-link.active {
    background: none;
    border-bottom: 2px solid #ff5757;
    z-index: 5;
    position: relative
}

.popular-items .nice-select {
    background-color: #f2f2f2;
    border-radius: 30px;
    border: none;
    color: #333333;
    height: 31px;
    line-height: 31px;
    padding-right: 47px
}

.popular-items .nice-select::after {
    border-bottom: 1px solid #333333;
    border-right: 1px solid #333333;
    height: 7px;
    width: 7px;
    margin-top: -4px;
    right: 17px
}

.popular-items .nice-select .list {
    background-color: #fff;
    border-radius: 0;
    width: 100%
}

.single-popular-items:hover .popular-img .img-cap {
    bottom: 0;
    opacity: 1;
    visibility: visible
}

.single-popular-items:hover .popular-img .favorit-items {
    opacity: 1;
    visibility: visible
}

.video-area {
    background-image: url(/src/assets/img/gallery//sectin_bg1.png);
    width: 100%;
    height: 700px;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    position: relative
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .video-area {
        height: 400px
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .video-area {
        height: 400px
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .video-area {
        height: 320px
    }
}

@media (max-width: 575px) {
    .video-area {
        height: 400px
    }
}

.video-area::before {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    content: "";
    background-color: rgba(2, 5, 12, 0.5);
    opacity: 0;
    z-index: 0;
    -webkit-transition: all .4s ease-out 0s;
    -moz-transition: all .4s ease-out 0s;
    -ms-transition: all .4s ease-out 0s;
    -o-transition: all .4s ease-out 0s;
    transition: all .4s ease-out 0s
}

.video-area .play-btn {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    right: 0;
    text-align: center
}

.video-area .play-btn a {
    width: 85px;
    height: 85px;
    display: inline-block;
    line-height: 85px;
    text-align: center;
    color: #fff;
    border-radius: 50%;
    font-size: 24px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    padding-left: 5px;
    padding-top: 1px;
    border: 1px solid #fff;
    transform: scale(1)
}

.video-area .play-btn a:hover {
    background: #fff;
    color: #f76c5e;
    border: 1px solid transparent
}

.video-area:hover::before {
    opacity: 1
}

.video-area:hover .play-btn a {
    transform: scale(1.1)
}

.thumb-content-box {
    position: absolute;
    bottom: 0px;
    left: 0px;
    z-index: 2;
    width: 34%
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .thumb-content-box {
        width: 28%
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .thumb-content-box {
        width: 32%
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .thumb-content-box {
        width: 40%
    }
}

@media (max-width: 575px) {
    .thumb-content-box {
        width: 60%
    }
}

.thumb-content-box .thumb-content {
    position: absolute;
    bottom: 0;
    align-items: flex-end;
    background-image: url(/src/assets/img/gallery/vidoe_more.png);
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    justify-content: space-between;
    padding: 60px 143px 60px 80px;
    width: 100%;
    line-height: 1
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .thumb-content-box .thumb-content {
        padding: 21px 56px 25px 9px
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .thumb-content-box .thumb-content {
        padding: 21px 56px 25px 9px
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .thumb-content-box .thumb-content {
        padding: 11px 49px 14px 9px
    }
}

@media (max-width: 575px) {
    .thumb-content-box .thumb-content {
        padding: 11px 49px 14px 9px
    }
}

.thumb-content-box .thumb-content h3 {
    color: #ffffff;
    font-weight: 600;
    font-size: 16px;
    margin: 0;
    text-transform: uppercase
}

.thumb-content-box .thumb-content a {
    color: #fff;
    display: block;
    position: relative;
    right: -34px;
    font-size: 30px
}

.watch-area .watch-details h2 {
    color: #000;
    font-size: 56px;
    font-weight: 700;
    line-height: 1;
    margin-bottom: 40px
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .watch-area .watch-details h2 {
        font-size: 46px
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .watch-area .watch-details h2 {
        font-size: 33px
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .watch-area .watch-details h2 {
        font-size: 29px
    }
}

@media (max-width: 575px) {
    .watch-area .watch-details h2 {
        font-size: 29px
    }
}

.watch-area .watch-details P {
    margin-bottom: 50px;
    font-size: 17px
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .watch-area .watch-details P {
        font-size: 16px
    }
}

.watch-area .choice-watch-img img {
    width: 100%
}

.padding-130 {
    padding-bottom: 130px
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .padding-130 {
        padding-bottom: 120px
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .padding-130 {
        padding-bottom: 100px
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .padding-130 {
        padding-bottom: 0px
    }
}

@media (max-width: 575px) {
    .padding-130 {
        padding-bottom: 0px
    }
}

.shop-method-area .method-wrapper {
    background: #f75646
}

.shop-method-area .method-wrapper .single-method {
    padding: 43px 20px 0 45px
}

.shop-method-area .method-wrapper .single-method i {
    font-size: 40px;
    color: #ffff;
    margin-bottom: 25px;
    display: block
}

.shop-method-area .method-wrapper .single-method h6 {
    color: #fff;
    font-size: 20px;
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: 12px;
    font-family: "Roboto", sans-serif
}

.shop-method-area .method-wrapper .single-method p {
    font-size: 16px;
    color: #d3d1d1
}

.category-area {
    padding-left: 45px;
    padding-right: 45px
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .category-area {
        padding-left: 0px;
        padding-right: 0px
    }
}

@media (max-width: 575px) {
    .category-area {
        padding-left: 0px;
        padding-right: 0px
    }
}

.category-area .single-category .category-img {
    position: relative
}

.category-area .single-category .category-img img {
    width: 100%;
    border-radius: 6px
}

.category-area .single-category .category-img .category-caption {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 52px
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
    .category-area .single-category .category-img .category-caption {
        right: 13px
    }
}

@media (max-width: 575px) {
    .category-area .single-category .category-img .category-caption {
        right: 13px
    }
}

.category-area .single-category .category-img .category-caption h2 {
    color: #1f1f1f;
    text-transform: capitalize;
    font-size: 40px;
    font-weight: 700;
    line-height: 1.1;
    text-transform: capitalize;
    margin-bottom: 13px
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
    .category-area .single-category .category-img .category-caption h2 {
        font-size: 26px
    }
}

@media (max-width: 575px) {
    .category-area .single-category .category-img .category-caption h2 {
        font-size: 22px;
        margin-bottom: 0px
    }
}

.category-area .single-category .category-img .category-caption .best a {
    color: #000000;
    text-transform: capitalize;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.1;
    text-transform: capitalize;
    background: #ffce25;
    padding: 13px 24px;
    border-radius: 30px;
    display: inline-block
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
    .category-area .single-category .category-img .category-caption .best a {
        padding: 13px 15px
    }
}

@media (max-width: 575px) {
    .category-area .single-category .category-img .category-caption .best a {
        padding: 13px 15px;
        font-size: 12px
    }
}

.category-area .single-category .category-img .category-caption .collection {
    display: block;
    color: #0071fe;
    text-transform: capitalize;
    font-size: 30px;
    line-height: 1.1;
    text-transform: capitalize;
    font-weight: 600;
    text-shadow: 0px -3px 0px #fff;
    margin-top: -8px
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
    .category-area .single-category .category-img .category-caption .collection {
        font-size: 23px
    }
}

@media (max-width: 575px) {
    .category-area .single-category .category-img .category-caption .collection {
        font-size: 20px
    }
}

.category-area .single-category .category-img .category-caption p {
    color: #000000
}

.section-tittle h2 {
    color: #4e4e4e;
    font-size: 48px;
    font-weight: 700;
    line-height: 1
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .section-tittle h2 {
        font-size: 37px
    }
}

@media (max-width: 575px) {
    .section-tittle h2 {
        font-size: 33px
    }
}

.best-product-area {
    position: relative
}

.best-product-area .shape {
    position: absolute;
    right: 145px;
    bottom: 64px;
    opacity: .8
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .best-product-area .shape {
        right: 84px;
        bottom: 18px
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .best-product-area .shape {
        right: 84px;
        bottom: 18px
    }
}

.best-product-area .bg-height {
    background-repeat: no-repeat;
    width: 100%;
    background-size: cover
}

.best-product-area .best-product-caption {
    padding-top: 150px;
    padding-bottom: 130px
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .best-product-area .best-product-caption {
        padding-top: 150px;
        padding-bottom: 130px
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .best-product-area .best-product-caption {
        padding-top: 80px;
        padding-bottom: 75px
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .best-product-area .best-product-caption {
        padding-top: 80px;
        padding-bottom: 75px
    }
}

@media (max-width: 575px) {
    .best-product-area .best-product-caption {
        padding-top: 80px;
        padding-bottom: 75px
    }
}

.best-product-area .best-product-caption h2 {
    color: #000;
    font-size: 55px;
    font-weight: 700;
    line-height: 1.3;
    margin-bottom: 19px
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .best-product-area .best-product-caption h2 {
        font-size: 45px
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .best-product-area .best-product-caption h2 {
        font-size: 45px
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .best-product-area .best-product-caption h2 {
        font-size: 22px
    }
}

@media (max-width: 575px) {
    .best-product-area .best-product-caption h2 {
        font-size: 21px
    }
}

.best-product-area .best-product-caption p {
    color: #373b41;
    font-size: 20px;
    margin-bottom: 55px
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .best-product-area .best-product-caption p {
        font-size: 17px
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .best-product-area .best-product-caption p {
        font-size: 17px
    }
}

@media (max-width: 575px) {
    .best-product-area .best-product-caption p {
        font-size: 17px
    }
}

.best-product-area .product-man {
    left: 9%;
    bottom: 0;
    z-index: 1
}

.best-product-area .vertical-text span {
    text-orientation: 150deg;
    writing-mode: vertical-lr;
    writing-mode: vertical-lr;
    transform: rotate(180deg);
    font-size: 117px;
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    -webkit-text-stroke: 1px #e6dfc6;
    -webkit-text-fill-color: transparent;
    position: relative;
    z-index: 0
}

.best-collection-area .best-left-cap h2 {
    color: #000;
    font-size: 45px;
    font-weight: 700;
    line-height: 1.3;
    margin-bottom: 19px
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .best-collection-area .best-left-cap h2 {
        font-size: 33px
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .best-collection-area .best-left-cap h2 {
        font-size: 33px
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .best-collection-area .best-left-cap h2 {
        font-size: 33px
    }
}

@media (max-width: 575px) {
    .best-collection-area .best-left-cap h2 {
        font-size: 33px
    }
}

.best-collection-area .best-left-cap p {
    color: #373b41;
    font-size: 20px;
    margin-bottom: 55px
}

.best-collection-area .best-left-img {
    padding-top: 130px
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .best-collection-area .best-left-img {
        padding-top: 30px
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .best-collection-area .best-left-img {
        padding-top: 30px
    }
}

@media (max-width: 575px) {
    .best-collection-area .best-left-img {
        padding-top: 30px
    }
}

.best-collection-area .best-right-cap {
    float: right
}

.best-collection-area .best-right-cap .best-single {
    display: flex;
    align-items: flex-end;
}

.best-collection-area .best-right-cap .best-single .single-cap {
    text-align: right;
}

.best-collection-area .best-right-cap .best-single .single-cap.active {
    background: #f9503f;
    padding: 10px 30px;
    border-radius: 10px
}

.best-collection-area .best-right-cap .best-single .single-cap.active h4 {
    color: #fff;
    font-family: "Roboto", sans-serif
}

.best-collection-area .best-right-cap .best-single .single-cap h4 {
    color: #000000;
    font-size: 18px;
    line-height: 1.6;
    font-family: "Roboto", sans-serif
}

.best-collection-area .best-right-cap .best-single .single-img {
    padding-left: 25px
}

.latest-height {
    height: 400px;
    background-repeat: no-repeat;
    background-size: cover
}

.latest-wrapper .latest-area {
    position: relative
}

.latest-wrapper .latest-area .latest-caption {
    position: relative;
    z-index: 1
}

.latest-wrapper .latest-area .latest-caption h2 {
    color: #000;
    font-size: 45px;
    font-weight: 700;
    line-height: 1.3;
    margin-bottom: 19px
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .latest-wrapper .latest-area .latest-caption h2 {
        font-size: 36px
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .latest-wrapper .latest-area .latest-caption h2 {
        font-size: 35px
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .latest-wrapper .latest-area .latest-caption h2 {
        font-size: 29px
    }
}

@media (max-width: 575px) {
    .latest-wrapper .latest-area .latest-caption h2 {
        font-size: 27px
    }
}

.latest-wrapper .latest-area .latest-caption p {
    color: #373b41;
    font-size: 20px;
    margin-bottom: 55px
}

.latest-wrapper .latest-area .latest-subscribe form {
    position: relative
}

.latest-wrapper .latest-area .latest-subscribe form input {
    height: 60px;
    width: 100%;
    padding: 10px 32px;
    border: 1px solid #fff;
    border-radius: 30px
}

@media (max-width: 575px) {
    .latest-wrapper .latest-area .latest-subscribe form input {
        padding: 10px 17px
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .latest-wrapper .latest-area .latest-subscribe form input {
        padding: 10px 17px
    }
}

.latest-wrapper .latest-area .latest-subscribe form button {
    padding: 20px 47px;
    background: #f9503f;
    font-size: 14px;
    font-weight: 300;
    position: absolute;
    right: 0;
    border: 0;
    cursor: pointer;
    top: 0;
    color: #fff;
    border-radius: 30px
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .latest-wrapper .latest-area .latest-subscribe form button {
        padding: 20px 33px
    }
}

@media (max-width: 575px) {
    .latest-wrapper .latest-area .latest-subscribe form button {
        padding: 20px 14px
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .latest-wrapper .latest-area .latest-subscribe form button {
        padding: 20px 14px
    }
}

.latest-wrapper .latest-area .man-shape {
    position: absolute;
    left: 34px;
    bottom: -77px
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
    .latest-wrapper .latest-area .man-shape {
        display: none
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .latest-wrapper .latest-area .man-shape {
        display: none
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .latest-wrapper .latest-area .man-shape {
        display: none
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .latest-wrapper .latest-area .man-shape {
        display: none
    }
}

@media (max-width: 575px) {
    .latest-wrapper .latest-area .man-shape {
        display: none
    }
}

.gallery-wrapper .gallery-area .gallery-items {
    display: flex;
    width: 20%
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .gallery-wrapper .gallery-area .gallery-items {
        width: 50%
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .gallery-wrapper .gallery-area .gallery-items {
        width: 50%
    }
}

@media (max-width: 575px) {
    .gallery-wrapper .gallery-area .gallery-items {
        width: 50%
    }
}

.footer-area {
    background: #222 !important;
}

.footer-area .footer-logo {
    margin-bottom: 40px
}

.footer-area .footer-pera p {
    color: #EEE;
    font-size: 16px;
    margin-bottom: 50px;
    line-height: 1.8
}

.footer-area .footer-pera b {
    color: #FFF;
}

.footer-area .footer-pera.footer-pera2 p {
    padding: 0
}

.footer-area .footer-tittle h4 {
    color: #FFF;
    font-size: 20px;
    margin-bottom: 29px;
    font-weight: bold;
    text-transform: capitalize;
    font-family: "Roboto", sans-serif
}

.footer-area .footer-tittle ul li {
    color: #012f5f;
    margin-bottom: 15px
}

.footer-area .footer-tittle ul li a {
    color: #EEE;
    font-weight: 300
}

.footer-area .footer-tittle ul li a:hover {
    color: #f76c5e;
    padding-left: 5px
}

.footer-copy-right {
    padding-top: 50px
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .footer-copy-right {
        padding-top: 0px;
        float: left
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .footer-copy-right {
        padding-top: 0px;
        float: left
    }
}

@media (max-width: 575px) {
    .footer-copy-right {
        padding-top: 0px;
        float: left
    }
}

.footer-copy-right p {
    color: #EEE;
    font-weight: 300;
    font-size: 16px;
    line-height: 2;
    margin-bottom: 20px
}

.footer-copy-right p i {
    color: #f76c5e
}

.footer-copy-right p a {
    color: #f76c5e
}

.footer-social a:last-child {
    padding-right: 0
}

.footer-social a i {
    color: #EEE;
    font-size: 14px;
    padding: 5px 10px;
    -webkit-transition: all .4s ease-out 0s;
    -moz-transition: all .4s ease-out 0s;
    -ms-transition: all .4s ease-out 0s;
    -o-transition: all .4s ease-out 0s;
    transition: all .4s ease-out 0s;
    transform: rotateY(0deg);
    -webkit-transform: rotateY(0deg);
    -moz-transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
    -o-transform: rotateY(0deg)
}

.footer-social a i:hover {
    color: #AAA;
    transform: rotateY(180deg);
    -webkit-transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
    -ms-transform: rotateY(180deg);
    -o-transform: rotateY(180deg)
}

.hero-caption span {
    color: #fff;
    font-size: 16px;
    display: block;
    margin-bottom: 24px;
    font-weight: 600;
    padding-left: 95px;
    position: relative
}

.hero-caption span::before {
    position: absolute;
    content: "";
    width: 75px;
    height: 3px;
    background: #f76c5e;
    left: 0;
    top: 52%;
    transform: translateY(-50%)
}

.hero-caption h2 {
    color: #fff;
    font-size: 50px;
    font-weight: 700
}

.glyphicon {
    margin-right: 5px
}

.thumbnail {
    margin-bottom: 20px;
    padding: 0px;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    border-radius: 0px
}

.item.list-group-item {
    float: none;
    width: 100%;
    background-color: #fff;
    margin-bottom: 10px
}

.item.list-group-item:nth-of-type(odd):hover,
.item.list-group-item:hover {
    background: #428bca
}

.item.list-group-item .list-group-image {
    margin-right: 10px
}

.item.list-group-item .thumbnail {
    margin-bottom: 0px
}

.item.list-group-item .caption {
    padding: 9px 9px 0px 9px
}

.item.list-group-item:nth-of-type(odd) {
    background: #eeeeee
}

.item.list-group-item:before,
.item.list-group-item:after {
    display: table;
    content: " "
}

.item.list-group-item img {
    float: left
}

.item.list-group-item:after {
    clear: both
}

.list-group-item-text {
    margin: 0 0 11px
}

.latest-blog-area .area-heading {
    margin-bottom: 70px
}

.blog_area a {
    color: "Josefin Sans", sans-serif !important;
    text-decoration: none;
    transition: .4s
}

.blog_area a:hover,
.blog_area a :hover {
    background: -webkit-linear-gradient(131deg, #f76c5e 0%, #f76c5e 99%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-decoration: none;
    transition: .4s
}

.single-blog {
    overflow: hidden;
    margin-bottom: 30px
}

.single-blog:hover {
    box-shadow: 0px 10px 20px 0px rgba(42, 34, 123, 0.1)
}

.single-blog .thumb {
    overflow: hidden;
    position: relative
}

.single-blog .thumb:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0;
    -webkit-transition: all .3s ease-out 0s;
    -moz-transition: all .3s ease-out 0s;
    -ms-transition: all .3s ease-out 0s;
    -o-transition: all .3s ease-out 0s;
    transition: all .3s ease-out 0s
}

.single-blog h4 {
    border-bottom: 1px solid #dfdfdf;
    padding-bottom: 34px;
    margin-bottom: 25px
}

.single-blog a {
    font-size: 20px;
    font-weight: 600
}

.single-blog .date {
    color: #666666;
    text-align: left;
    display: inline-block;
    font-size: 13px;
    font-weight: 300
}

.single-blog .tag {
    text-align: left;
    display: inline-block;
    float: left;
    font-size: 13px;
    font-weight: 300;
    margin-right: 22px;
    position: relative
}

.single-blog .tag:after {
    content: '';
    position: absolute;
    width: 1px;
    height: 10px;
    background: #acacac;
    right: -12px;
    top: 7px
}

@media (max-width: 1199px) {
    .single-blog .tag {
        margin-right: 8px
    }

    .single-blog .tag:after {
        display: none
    }
}

.single-blog .likes {
    margin-right: 16px
}

@media (max-width: 800px) {
    .single-blog {
        margin-bottom: 30px
    }
}

.single-blog .single-blog-content {
    padding: 30px
}

.single-blog .single-blog-content .meta-bottom p {
    font-size: 13px;
    font-weight: 300
}

.single-blog .single-blog-content .meta-bottom i {
    color: #fdcb9e;
    font-size: 13px;
    margin-right: 7px
}

@media (max-width: 1199px) {
    .single-blog .single-blog-content {
        padding: 15px
    }
}

.single-blog:hover .thumb:after {
    opacity: .7;
    -webkit-transition: all .3s ease-out 0s;
    -moz-transition: all .3s ease-out 0s;
    -ms-transition: all .3s ease-out 0s;
    -o-transition: all .3s ease-out 0s;
    transition: all .3s ease-out 0s
}

@media (max-width: 1199px) {
    .single-blog h4 {
        transition: all 300ms linear 0s;
        border-bottom: 1px solid #dfdfdf;
        padding-bottom: 14px;
        margin-bottom: 12px
    }

    .single-blog h4 a {
        font-size: 18px
    }
}

.full_image.single-blog {
    position: relative
}

.full_image.single-blog .single-blog-content {
    position: absolute;
    left: 35px;
    bottom: 0;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all .3s ease-out 0s;
    -moz-transition: all .3s ease-out 0s;
    -ms-transition: all .3s ease-out 0s;
    -o-transition: all .3s ease-out 0s;
    transition: all .3s ease-out 0s
}

@media (min-width: 992px) {
    .full_image.single-blog .single-blog-content {
        bottom: 100px
    }
}

.full_image.single-blog h4 {
    -webkit-transition: all .3s ease-out 0s;
    -moz-transition: all .3s ease-out 0s;
    -ms-transition: all .3s ease-out 0s;
    -o-transition: all .3s ease-out 0s;
    transition: all .3s ease-out 0s;
    border-bottom: none;
    padding-bottom: 5px
}

.full_image.single-blog a {
    font-size: 20px;
    font-weight: 600
}

.full_image.single-blog .date {
    color: #fff
}

.full_image.single-blog:hover .single-blog-content {
    opacity: 1;
    visibility: visible;
    -webkit-transition: all .3s ease-out 0s;
    -moz-transition: all .3s ease-out 0s;
    -ms-transition: all .3s ease-out 0s;
    -o-transition: all .3s ease-out 0s;
    transition: all .3s ease-out 0s
}

.l_blog_item .l_blog_text .date {
    margin-top: 24px;
    margin-bottom: 15px
}

.l_blog_item .l_blog_text .date a {
    font-size: 12px
}

.l_blog_item .l_blog_text h4 {
    font-size: 18px;
    border-bottom: 1px solid #eeeeee;
    margin-bottom: 0px;
    padding-bottom: 20px;
    -webkit-transition: all .3s ease-out 0s;
    -moz-transition: all .3s ease-out 0s;
    -ms-transition: all .3s ease-out 0s;
    -o-transition: all .3s ease-out 0s;
    transition: all .3s ease-out 0s
}

.l_blog_item .l_blog_text p {
    margin-bottom: 0px;
    padding-top: 20px
}

.causes_slider .owl-dots {
    text-align: center;
    margin-top: 80px
}

.causes_slider .owl-dots .owl-dot {
    height: 14px;
    width: 14px;
    background: #eeeeee;
    display: inline-block;
    margin-right: 7px
}

.causes_slider .owl-dots .owl-dot:last-child {
    margin-right: 0px
}

.causes_item {
    background: #fff
}

.causes_item .causes_img {
    position: relative
}

.causes_item .causes_img .c_parcent {
    position: absolute;
    bottom: 0px;
    width: 100%;
    left: 0px;
    height: 3px;
    background: rgba(255, 255, 255, 0.5)
}

.causes_item .causes_img .c_parcent span {
    width: 70%;
    height: 3px;
    position: absolute;
    left: 0px;
    bottom: 0px
}

.causes_item .causes_img .c_parcent span:before {
    content: "75%";
    position: absolute;
    right: -10px;
    bottom: 0px;
    color: #fff;
    padding: 0px 5px
}

.causes_item .causes_text {
    padding: 30px 35px 40px 30px
}

.causes_item .causes_text h4 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 15px;
    cursor: pointer
}

.causes_item .causes_text p {
    font-size: 14px;
    line-height: 24px;
    font-weight: 300;
    margin-bottom: 0px
}

.causes_item .causes_bottom a {
    width: 50%;
    border: 1px solid;
    text-align: center;
    float: left;
    line-height: 50px;
    color: #fff;
    font-size: 14px;
    font-weight: 500
}

.causes_item .causes_bottom a+a {
    border-color: #eeeeee;
    background: #fff;
    font-size: 14px
}

.latest_blog_area {
    background: #f9f9ff
}

.single-recent-blog-post {
    margin-bottom: 30px
}

.single-recent-blog-post .thumb {
    overflow: hidden
}

.single-recent-blog-post .thumb img {
    transition: all 0.7s linear
}

.single-recent-blog-post .details {
    padding-top: 30px
}

.single-recent-blog-post .details .sec_h4 {
    line-height: 24px;
    padding: 10px 0px 13px;
    transition: all 0.3s linear
}

.single-recent-blog-post .date {
    font-size: 14px;
    line-height: 24px;
    font-weight: 400
}

.single-recent-blog-post:hover img {
    transform: scale(1.23) rotate(10deg)
}

.tags .tag_btn {
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    border: 1px solid #eeeeee;
    display: inline-block;
    padding: 1px 18px;
    text-align: center
}

.tags .tag_btn+.tag_btn {
    margin-left: 2px
}

.blog_categorie_area {
    padding-top: 30px;
    padding-bottom: 30px
}

@media (min-width: 900px) {
    .blog_categorie_area {
        padding-top: 80px;
        padding-bottom: 80px
    }
}

@media (min-width: 1100px) {
    .blog_categorie_area {
        padding-top: 120px;
        padding-bottom: 120px
    }
}

.categories_post {
    position: relative;
    text-align: center;
    cursor: pointer
}

.categories_post img {
    max-width: 100%
}

.categories_post .categories_details {
    position: absolute;
    top: 20px;
    left: 20px;
    right: 20px;
    bottom: 20px;
    background: rgba(34, 34, 34, 0.75);
    color: #fff;
    transition: all 0.3s linear;
    display: flex;
    align-items: center;
    justify-content: center
}

.categories_post .categories_details h5 {
    margin-bottom: 0px;
    font-size: 18px;
    line-height: 26px;
    text-transform: uppercase;
    color: #fff;
    position: relative
}

.categories_post .categories_details p {
    font-weight: 300;
    font-size: 14px;
    line-height: 26px;
    margin-bottom: 0px
}

.categories_post .categories_details .border_line {
    margin: 10px 0px;
    background: #fff;
    width: 100%;
    height: 1px
}

.categories_post:hover .categories_details {
    background: rgba(222, 99, 32, 0.85)
}

.blog_item {
    margin-bottom: 50px
}

.blog_details {
    padding: 30px 0 20px 10px;
    box-shadow: 0px 10px 20px 0px rgba(221, 221, 221, 0.3)
}

@media (min-width: 768px) {
    .blog_details {
        padding: 60px 30px 35px 35px
    }
}

.blog_details p {
    margin-bottom: 30px
}

.blog_details a {
    color: #ff8b23
}

.blog_details a:hover {
    color: #f76c5e
}

.blog_details h2 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 8px
}

@media (min-width: 768px) {
    .blog_details h2 {
        font-size: 24px;
        margin-bottom: 15px
    }
}

.blog-info-link li {
    float: left;
    font-size: 14px
}

.blog-info-link li a {
    color: #999999
}

.blog-info-link li i,
.blog-info-link li span {
    font-size: 13px;
    margin-right: 5px
}

.blog-info-link li::after {
    content: "|";
    padding-left: 10px;
    padding-right: 10px
}

.blog-info-link li:last-child::after {
    display: none
}

.blog-info-link::after {
    content: "";
    display: block;
    clear: both;
    display: table
}

.blog_item_img {
    position: relative
}

.blog_item_img .blog_item_date {
    position: absolute;
    bottom: -10px;
    left: 10px;
    display: block;
    color: #fff;
    background-color: #f76c5e;
    padding: 8px 15px;
    border-radius: 5px
}

@media (min-width: 768px) {
    .blog_item_img .blog_item_date {
        bottom: -20px;
        left: 40px;
        padding: 13px 30px
    }
}

.blog_item_img .blog_item_date h3 {
    font-size: 22px;
    font-weight: 600;
    color: #fff;
    margin-bottom: 0;
    line-height: 1.2
}

@media (min-width: 768px) {
    .blog_item_img .blog_item_date h3 {
        font-size: 30px
    }
}

.blog_item_img .blog_item_date p {
    font-size: 18px;
    margin-bottom: 0;
    color: #fff
}

@media (min-width: 768px) {
    .blog_item_img .blog_item_date p {
        font-size: 18px
    }
}

.blog_right_sidebar .widget_title {
    font-size: 20px;
    margin-bottom: 40px
}

.blog_right_sidebar .widget_title::after {
    content: "";
    display: block;
    padding-top: 15px;
    border-bottom: 1px solid #f0e9ff
}

.blog_right_sidebar .single_sidebar_widget {
    background: #fbf9ff;
    padding: 30px;
    margin-bottom: 30px
}

.blog_right_sidebar .single_sidebar_widget .btn_1 {
    margin-top: 0px
}

.blog_right_sidebar .search_widget .form-control {
    height: 50px;
    border-color: #f0e9ff;
    font-size: 13px;
    color: #999999;
    padding-left: 20px;
    border-radius: 0;
    border-right: 0
}

.blog_right_sidebar .search_widget .form-control::placeholder {
    color: #999999
}

.blog_right_sidebar .search_widget .form-control:focus {
    border-color: #f0e9ff;
    outline: 0;
    box-shadow: none
}

.blog_right_sidebar .search_widget .input-group button {
    background: #f76c5e;
    border-left: 0;
    border: 1px solid #f0e9ff;
    padding: 4px 15px;
    border-left: 0;
    cursor: pointer
}

.blog_right_sidebar .search_widget .input-group button i {
    color: #fff
}

.blog_right_sidebar .search_widget .input-group button span {
    font-size: 14px;
    color: #999999
}

.blog_right_sidebar .newsletter_widget .form-control {
    height: 50px;
    border-color: #f0e9ff;
    font-size: 13px;
    color: #999999;
    padding-left: 20px;
    border-radius: 0
}

.blog_right_sidebar .newsletter_widget .form-control::placeholder {
    color: #999999
}

.blog_right_sidebar .newsletter_widget .form-control:focus {
    border-color: #f0e9ff;
    outline: 0;
    box-shadow: none
}

.blog_right_sidebar .newsletter_widget .input-group button {
    background: #fff;
    border-left: 0;
    border: 1px solid #f0e9ff;
    padding: 4px 15px;
    border-left: 0
}

.blog_right_sidebar .newsletter_widget .input-group button i,
.blog_right_sidebar .newsletter_widget .input-group button span {
    font-size: 14px;
    color: #fff
}

.blog_right_sidebar .post_category_widget .cat-list li {
    border-bottom: 1px solid #f0e9ff;
    transition: all 0.3s ease 0s;
    padding-bottom: 12px
}

.blog_right_sidebar .post_category_widget .cat-list li:last-child {
    border-bottom: 0
}

.blog_right_sidebar .post_category_widget .cat-list li a {
    font-size: 14px;
    line-height: 20px;
    color: #888888
}

.blog_right_sidebar .post_category_widget .cat-list li a p {
    margin-bottom: 0px
}

.blog_right_sidebar .post_category_widget .cat-list li+li {
    padding-top: 15px
}

.blog_right_sidebar .popular_post_widget .post_item .media-body {
    justify-content: center;
    align-self: center;
    padding-left: 20px
}

.blog_right_sidebar .popular_post_widget .post_item .media-body h3 {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 6px;
    transition: all 0.3s linear
}

.blog_right_sidebar .popular_post_widget .post_item .media-body a:hover {
    color: #fff
}

.blog_right_sidebar .popular_post_widget .post_item .media-body p {
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 0px
}

.blog_right_sidebar .popular_post_widget .post_item+.post_item {
    margin-top: 20px
}

.blog_right_sidebar .tag_cloud_widget ul li {
    display: inline-block
}

.blog_right_sidebar .tag_cloud_widget ul li a {
    display: inline-block;
    border: 1px solid #eeeeee;
    background: #fff;
    padding: 4px 20px;
    margin-bottom: 8px;
    margin-right: 3px;
    transition: all 0.3s ease 0s;
    color: #888888;
    font-size: 13px
}

.blog_right_sidebar .tag_cloud_widget ul li a:hover {
    background: #f76c5e;
    color: #fff !important;
    -webkit-text-fill-color: #fff;
    text-decoration: none;
    -webkit-transition: 0.5s;
    transition: 0.5s
}

.blog_right_sidebar .instagram_feeds .instagram_row {
    display: flex;
    margin-right: -6px;
    margin-left: -6px
}

.blog_right_sidebar .instagram_feeds .instagram_row li {
    width: 33.33%;
    float: left;
    padding-right: 6px;
    padding-left: 6px;
    margin-bottom: 15px
}

.blog_right_sidebar .br {
    width: 100%;
    height: 1px;
    background: #eee;
    margin: 30px 0px
}

.blog-pagination {
    margin-top: 80px
}

.blog-pagination .page-link {
    font-size: 14px;
    position: relative;
    display: block;
    padding: 0;
    text-align: center;
    margin-left: -1px;
    line-height: 45px;
    width: 45px;
    height: 45px;
    border-radius: 0 !important;
    color: #8a8a8a;
    border: 1px solid #f0e9ff;
    margin-right: 10px
}

.blog-pagination .page-link i,
.blog-pagination .page-link span {
    font-size: 13px
}

.blog-pagination .page-item.active .page-link {
    background-color: #fbf9ff;
    border-color: #f0e9ff;
    color: #888888
}

.blog-pagination .page-item:last-child .page-link {
    margin-right: 0
}

.single-post-area .blog_details {
    box-shadow: none;
    padding: 0
}

.single-post-area .social-links {
    padding-top: 10px
}

.single-post-area .social-links li {
    display: inline-block;
    margin-bottom: 10px
}

.single-post-area .social-links li a {
    color: #cccccc;
    padding: 7px;
    font-size: 14px;
    transition: all 0.2s linear
}

.single-post-area .blog_details {
    padding-top: 26px
}

.single-post-area .blog_details p {
    margin-bottom: 20px;
    font-size: 15px
}

.single-post-area .quote-wrapper {
    background: rgba(130, 139, 178, 0.1);
    padding: 15px;
    line-height: 1.733;
    color: #888888;
    font-style: italic;
    margin-top: 25px;
    margin-bottom: 25px
}

@media (min-width: 768px) {
    .single-post-area .quote-wrapper {
        padding: 30px
    }
}

.single-post-area .quotes {
    background: #fff;
    padding: 15px 15px 15px 20px;
    border-left: 2px solid
}

@media (min-width: 768px) {
    .single-post-area .quotes {
        padding: 25px 25px 25px 30px
    }
}

.single-post-area .arrow {
    position: absolute
}

.single-post-area .arrow .lnr {
    font-size: 20px;
    font-weight: 600
}

.single-post-area .thumb .overlay-bg {
    background: rgba(0, 0, 0, 0.8)
}

.single-post-area .navigation-top {
    padding-top: 15px;
    border-top: 1px solid #f0e9ff
}

.single-post-area .navigation-top p {
    margin-bottom: 0
}

.single-post-area .navigation-top .like-info {
    font-size: 14px
}

.single-post-area .navigation-top .like-info i,
.single-post-area .navigation-top .like-info span {
    font-size: 16px;
    margin-right: 5px
}

.single-post-area .navigation-top .comment-count {
    font-size: 14px
}

.single-post-area .navigation-top .comment-count i,
.single-post-area .navigation-top .comment-count span {
    font-size: 16px;
    margin-right: 5px
}

.single-post-area .navigation-top .social-icons li {
    display: inline-block;
    margin-right: 15px
}

.single-post-area .navigation-top .social-icons li:last-child {
    margin: 0
}

.single-post-area .navigation-top .social-icons li i,
.single-post-area .navigation-top .social-icons li span {
    font-size: 14px;
    color: #999999
}

.single-post-area .blog-author {
    padding: 40px 30px;
    background: #fbf9ff;
    margin-top: 50px
}

@media (max-width: 600px) {
    .single-post-area .blog-author {
        padding: 20px 8px
    }
}

.single-post-area .blog-author img {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    margin-right: 30px
}

@media (max-width: 600px) {
    .single-post-area .blog-author img {
        margin-right: 15px;
        width: 45px;
        height: 45px
    }
}

.single-post-area .blog-author a {
    display: inline-block
}

.single-post-area .blog-author a:hover {
    color: #f76c5e
}

.single-post-area .blog-author p {
    margin-bottom: 0;
    font-size: 15px
}

.single-post-area .blog-author h4 {
    font-size: 16px
}

.single-post-area .navigation-area {
    border-bottom: 1px solid #eee;
    padding-bottom: 30px;
    margin-top: 55px
}

.single-post-area .navigation-area p {
    margin-bottom: 0px
}

.single-post-area .navigation-area h4 {
    font-size: 18px;
    line-height: 25px
}

.single-post-area .navigation-area .nav-left {
    text-align: left
}

.single-post-area .navigation-area .nav-left .thumb {
    margin-right: 20px;
    background: #000
}

.single-post-area .navigation-area .nav-left .thumb img {
    -webkit-transition: all .3s ease-out 0s;
    -moz-transition: all .3s ease-out 0s;
    -ms-transition: all .3s ease-out 0s;
    -o-transition: all .3s ease-out 0s;
    transition: all .3s ease-out 0s
}

.single-post-area .navigation-area .nav-left .lnr {
    margin-left: 20px;
    opacity: 0;
    -webkit-transition: all .3s ease-out 0s;
    -moz-transition: all .3s ease-out 0s;
    -ms-transition: all .3s ease-out 0s;
    -o-transition: all .3s ease-out 0s;
    transition: all .3s ease-out 0s
}

.single-post-area .navigation-area .nav-left:hover .lnr {
    opacity: 1
}

.single-post-area .navigation-area .nav-left:hover .thumb img {
    opacity: .5
}

@media (max-width: 767px) {
    .single-post-area .navigation-area .nav-left {
        margin-bottom: 30px
    }
}

.single-post-area .navigation-area .nav-right {
    text-align: right
}

.single-post-area .navigation-area .nav-right .thumb {
    margin-left: 20px;
    background: #000
}

.single-post-area .navigation-area .nav-right .thumb img {
    -webkit-transition: all .3s ease-out 0s;
    -moz-transition: all .3s ease-out 0s;
    -ms-transition: all .3s ease-out 0s;
    -o-transition: all .3s ease-out 0s;
    transition: all .3s ease-out 0s
}

.single-post-area .navigation-area .nav-right .lnr {
    margin-right: 20px;
    opacity: 0;
    -webkit-transition: all .3s ease-out 0s;
    -moz-transition: all .3s ease-out 0s;
    -ms-transition: all .3s ease-out 0s;
    -o-transition: all .3s ease-out 0s;
    transition: all .3s ease-out 0s
}

.single-post-area .navigation-area .nav-right:hover .lnr {
    opacity: 1
}

.single-post-area .navigation-area .nav-right:hover .thumb img {
    opacity: .5
}

@media (max-width: 991px) {
    .single-post-area .sidebar-widgets {
        padding-bottom: 0px
    }
}

.comments-area {
    background: transparent;
    border-top: 1px solid #eee;
    padding: 45px 0;
    margin-top: 50px
}

@media (max-width: 414px) {
    .comments-area {
        padding: 50px 8px
    }
}

.comments-area h4 {
    margin-bottom: 35px;
    font-size: 18px
}

.comments-area h5 {
    font-size: 16px;
    margin-bottom: 0px
}

.comments-area .comment-list {
    padding-bottom: 48px
}

.comments-area .comment-list:last-child {
    padding-bottom: 0px
}

.comments-area .comment-list.left-padding {
    padding-left: 25px
}

@media (max-width: 413px) {
    .comments-area .comment-list .single-comment h5 {
        font-size: 12px
    }

    .comments-area .comment-list .single-comment .date {
        font-size: 11px
    }

    .comments-area .comment-list .single-comment .comment {
        font-size: 10px
    }
}

.comments-area .thumb {
    margin-right: 20px
}

.comments-area .thumb img {
    width: 70px;
    border-radius: 50%
}

.comments-area .date {
    font-size: 14px;
    color: #999999;
    margin-bottom: 0;
    margin-left: 20px
}

.comments-area .comment {
    margin-bottom: 10px;
    color: #777777;
    font-size: 15px
}

.comments-area .btn-reply {
    background-color: transparent;
    color: #888888;
    padding: 5px 18px;
    font-size: 14px;
    display: block;
    font-weight: 400
}

.comment-form {
    border-top: 1px solid #eee;
    padding-top: 45px;
    margin-top: 50px;
    margin-bottom: 20px
}

.comment-form .form-group {
    margin-bottom: 30px
}

.comment-form h4 {
    margin-bottom: 40px;
    font-size: 18px;
    line-height: 22px
}

.comment-form .name {
    padding-left: 0px
}

@media (max-width: 767px) {
    .comment-form .name {
        padding-right: 0px;
        margin-bottom: 1rem
    }
}

.comment-form .email {
    padding-right: 0px
}

@media (max-width: 991px) {
    .comment-form .email {
        padding-left: 0px
    }
}

.comment-form .form-control {
    border: 1px solid #f0e9ff;
    border-radius: 5px;
    height: 48px;
    padding-left: 18px;
    font-size: 13px;
    background: transparent
}

.comment-form .form-control:focus {
    outline: 0;
    box-shadow: none
}

.comment-form .form-control::placeholder {
    font-weight: 300;
    color: #999999
}

.comment-form .form-control::placeholder {
    color: #777777
}

.comment-form textarea {
    padding-top: 18px;
    border-radius: 12px;
    height: 100% !important
}

.comment-form ::-webkit-input-placeholder {
    font-size: 13px;
    color: #777
}

.comment-form ::-moz-placeholder {
    font-size: 13px;
    color: #777
}

.comment-form :-ms-input-placeholder {
    font-size: 13px;
    color: #777
}

.comment-form :-moz-placeholder {
    font-size: 13px;
    color: #777
}

.dropdown .dropdown-menu {
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s
}

.contact-info {
    margin-bottom: 25px
}

.contact-info__icon {
    margin-right: 20px
}

.contact-info__icon i,
.contact-info__icon span {
    color: #8f9195;
    font-size: 27px
}

.contact-info .media-body h3 {
    font-size: 16px;
    margin-bottom: 0;
    font-size: 16px;
    color: #2a2a2a
}

.contact-info .media-body h3 a:hover {
    color: #222
}

.contact-info .media-body p {
    color: #8a8a8a
}

.contact-title {
    font-size: 27px;
    font-weight: 600;
    margin-bottom: 20px
}

.form-contact label {
    font-size: 14px
}

.form-contact .form-group {
    margin-bottom: 30px
}

.form-contact .form-control {
    border: 1px solid #e5e6e9;
    border-radius: 0px;
    height: 48px;
    padding-left: 18px;
    font-size: 13px;
    background: transparent
}

.form-contact .form-control:focus {
    outline: 0;
    box-shadow: none
}

.form-contact .form-control::placeholder {
    font-weight: 300;
    color: #999999
}

.form-contact textarea {
    border-radius: 0px;
    height: 100% !important
}

.modal-message .modal-dialog {
    position: absolute;
    top: 36%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%) !important;
    margin: 0px;
    max-width: 500px;
    width: 100%
}

.modal-message .modal-dialog .modal-content .modal-header {
    text-align: center;
    display: block;
    border-bottom: none;
    padding-top: 50px;
    padding-bottom: 50px
}

.modal-message .modal-dialog .modal-content .modal-header .close {
    position: absolute;
    right: -15px;
    top: -15px;
    padding: 0px;
    color: #fff;
    opacity: 1;
    cursor: pointer
}

.modal-message .modal-dialog .modal-content .modal-header h2 {
    display: block;
    text-align: center;
    padding-bottom: 10px
}

.modal-message .modal-dialog .modal-content .modal-header p {
    display: block
}

.contact-section {
    padding: 130px 0 100px
}

@media (max-width: 991px) {
    .contact-section {
        padding: 70px 0 40px
    }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .contact-section {
        padding: 80px 0 50px
    }
}

.contact-section .btn_2 {
    background-color: #191d34;
    padding: 18px 60px;
    border-radius: 50px;
    margin-top: 0
}

.contact-section .btn_2:hover {
    background-color: #222
}

.breadcam_bg {
    background-image: url(/src/assets/img/banner/bradcam.png)
}

.breadcam_bg_1 {
    background-image: url(/src/assets/img/banner/bradcam2.png)
}

.breadcam_bg_2 {
    background-image: url(/src/assets/img/banner/bradcam3.png)
}

.bradcam_area {
    background-size: cover;
    background-position: center center;
    padding: 160px 0;
    background-position: bottom;
    background-repeat: no-repeat
}

@media (max-width: 767px) {
    .bradcam_area {
        padding: 150px 0
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .bradcam_area {
        padding: 150px 0
    }
}

.bradcam_area h3 {
    font-size: 50px;
    color: #fff;
    font-weight: 900;
    margin-bottom: 0;
    font-family: "Josefin Sans", sans-serif;
    text-transform: capitalize
}

@media (max-width: 767px) {
    .bradcam_area h3 {
        font-size: 30px
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .bradcam_area h3 {
        font-size: 40px
    }
}

.popup_box {
    background: #fff;
    display: inline-block;
    z-index: 9;
    width: 681px;
    padding: 60px 40px
}

@media (max-width: 767px) {
    .popup_box {
        width: 320px;
        padding: 45px 30px
    }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
    .popup_box {
        width: 420px !important;
        padding: 45px 30px
    }
}

.popup_box h3 {
    text-align: center;
    font-size: 22px;
    color: #1F1F1F;
    margin-bottom: 46px
}

.popup_box .boxed-btn3 {
    width: 100%;
    text-transform: capitalize
}

.popup_box .nice-select {
    -webkit-tap-highlight-color: transparent;
    background-color: #fff;
    border: solid 1px #E2E2E2;
    box-sizing: border-box;
    clear: both;
    cursor: pointer;
    display: block;
    float: left;
    font-family: "Roboto", sans-serif;
    font-weight: normal;
    width: 100% !important;
    line-height: 50px;
    outline: none;
    padding-left: 18px;
    padding-right: 30px;
    position: relative;
    text-align: left !important;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    white-space: nowrap;
    width: auto;
    border-radius: 0;
    margin-bottom: 30px;
    height: 50px !important;
    font-size: 16px;
    font-weight: 400;
    color: #919191
}

.popup_box .nice-select::after {
    content: "\f0d7";
    display: block;
    height: 5px;
    margin-top: -5px;
    pointer-events: none;
    position: absolute;
    right: 17px;
    top: 3px;
    transition: all 0.15s ease-in-out;
    width: 5px;
    font-family: fontawesome;
    color: #919191;
    font-size: 15px
}

.popup_box .nice-select.open .list {
    opacity: 1;
    pointer-events: auto;
    -webkit-transform: scale(1) translateY(0);
    -ms-transform: scale(1) translateY(0);
    transform: scale(1) translateY(0);
    height: 200px;
    overflow-y: scroll
}

.popup_box .nice-select.list {
    height: 200px;
    overflow-y: scroll
}

#test-form {
    display: inline-block;
    margin: auto;
    text-align: center;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%)
}

@media (max-width: 767px) {
    #test-form {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        -webkit-transform: none;
        -ms-transform: none;
        transform: none
    }
}

#test-form .mfp-close-btn-in .mfp-close {
    color: #333;
    display: none !important
}

#test-form button.mfp-close {
    display: none !important
}

@media (max-width: 767px) {
    #test-form button.mfp-close {
        display: block !important;
        position: absolute;
        left: 0;
        right: 0;
        margin: auto
    }
}

#test-form button.mfp-close {
    overflow: visible;
    cursor: pointer;
    background: transparent;
    border: 0;
    -webkit-appearance: none;
    display: block;
    outline: none;
    padding: 0;
    z-index: 1046;
    box-shadow: none;
    touch-action: manipulation;
    width: 40px;
    height: 40px;
    background: #4A3600;
    text-align: center;
    line-height: 20px;
    position: absolute;
    right: 0;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    position: absolute;
    right: -6px;
    color: #fff !important
}

.mfp-bg {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1042;
    overflow: hidden;
    position: fixed;
    background: #4A3600;
    opacity: 0.6
}

@media (max-width: 767px) {
    .gj-picker.gj-picker-md.timepicker {
        width: 310px;
        left: 6px !important
    }
}

@media (max-width: 767px) {
    .gj-picker.gj-picker-md.datepicker.gj-unselectable {
        width: 320px;
        left: 0 !important
    }
}

.flex-center-start {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

.overlay::before {
    background-image: -moz-linear-gradient(170deg, rgba(34, 34, 34, 0) 0%, #000 100%);
    background-image: -webkit-linear-gradient(170deg, rgba(34, 34, 34, 0) 0%, #000 100%);
    background-image: -ms-linear-gradient(170deg, rgba(34, 34, 34, 0) 0%, #000 100%)
}

.sample-text-area {
    background: #fff;
    padding: 100px 0 70px 0
}

.text-heading {
    margin-bottom: 30px;
    font-size: 24px
}

b,
sup,
sub,
u,
del {
    color: #222
}

.typography h1,
.typography h2,
.typography h3,
.typography h4,
.typography h5,
.typography h6 {
    color: #828bb2
}

.button-area {
    background: #fff
}

.button-area .border-top-generic {
    padding: 70px 15px;
    border-top: 1px dotted #eee
}

.button-group-area .genric-btn {
    margin-right: 10px;
    margin-top: 10px
}

.button-group-area .genric-btn:last-child {
    margin-right: 0
}

.genric-btn {
    display: inline-block;
    outline: none;
    line-height: 40px;
    padding: 0 30px;
    font-size: .8em;
    text-align: center;
    text-decoration: none;
    font-weight: 500;
    cursor: pointer;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s
}

.genric-btn:focus {
    outline: none
}

.genric-btn.e-large {
    padding: 0 40px;
    line-height: 50px
}

.genric-btn.large {
    line-height: 45px
}

.genric-btn.medium {
    line-height: 30px
}

.genric-btn.small {
    line-height: 25px
}

.genric-btn.radius {
    border-radius: 3px
}

.genric-btn.circle {
    border-radius: 20px
}

.genric-btn.arrow {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.genric-btn.arrow span {
    margin-left: 10px
}

.genric-btn.default {
    color: #415094;
    background: #f9f9ff;
    border: 1px solid transparent
}

.genric-btn.default:hover {
    border: 1px solid #f9f9ff;
    background: #fff
}

.genric-btn.default-border {
    border: 1px solid #f9f9ff;
    background: #fff
}

.genric-btn.default-border:hover {
    color: #415094;
    background: #f9f9ff;
    border: 1px solid transparent
}

.genric-btn.primary {
    color: #fff;
    background: #222;
    border: 1px solid transparent
}

.genric-btn.primary:hover {
    color: #222;
    border: 1px solid #222;
    background: #fff
}

.genric-btn.primary-border {
    color: #222;
    border: 1px solid #222;
    background: #fff
}

.genric-btn.primary-border:hover {
    color: #fff;
    background: #222;
    border: 1px solid transparent
}

.genric-btn.success {
    color: #fff;
    background: #4cd3e3;
    border: 1px solid transparent
}

.genric-btn.success:hover {
    color: #4cd3e3;
    border: 1px solid #4cd3e3;
    background: #fff
}

.genric-btn.success-border {
    color: #4cd3e3;
    border: 1px solid #4cd3e3;
    background: #fff
}

.genric-btn.success-border:hover {
    color: #fff;
    background: #4cd3e3;
    border: 1px solid transparent
}

.genric-btn.info {
    color: #fff;
    background: #38a4ff;
    border: 1px solid transparent
}

.genric-btn.info:hover {
    color: #38a4ff;
    border: 1px solid #38a4ff;
    background: #fff
}

.genric-btn.info-border {
    color: #38a4ff;
    border: 1px solid #38a4ff;
    background: #fff
}

.genric-btn.info-border:hover {
    color: #fff;
    background: #38a4ff;
    border: 1px solid transparent
}

.genric-btn.warning {
    color: #fff;
    background: #f4e700;
    border: 1px solid transparent
}

.genric-btn.warning:hover {
    color: #f4e700;
    border: 1px solid #f4e700;
    background: #fff
}

.genric-btn.warning-border {
    color: #f4e700;
    border: 1px solid #f4e700;
    background: #fff
}

.genric-btn.warning-border:hover {
    color: #fff;
    background: #f4e700;
    border: 1px solid transparent
}

.genric-btn.danger {
    color: #fff;
    background: #f44a40;
    border: 1px solid transparent
}

.genric-btn.danger:hover {
    color: #f44a40;
    border: 1px solid #f44a40;
    background: #fff
}

.genric-btn.danger-border {
    color: #f44a40;
    border: 1px solid #f44a40;
    background: #fff
}

.genric-btn.danger-border:hover {
    color: #fff;
    background: #f44a40;
    border: 1px solid transparent
}

.genric-btn.link {
    color: #415094;
    background: #f9f9ff;
    text-decoration: underline;
    border: 1px solid transparent
}

.genric-btn.link:hover {
    color: #415094;
    border: 1px solid #f9f9ff;
    background: #fff
}

.genric-btn.link-border {
    color: #415094;
    border: 1px solid #f9f9ff;
    background: #fff;
    text-decoration: underline
}

.genric-btn.link-border:hover {
    color: #415094;
    background: #f9f9ff;
    border: 1px solid transparent
}

.genric-btn.disable {
    color: #222, 0.3;
    background: #f9f9ff;
    border: 1px solid transparent;
    cursor: not-allowed
}

.generic-blockquote {
    padding: 30px 50px 30px 30px;
    background: #f9f9ff;
    border-left: 2px solid #222
}

.progress-table-wrap {
    overflow-x: scroll
}

.progress-table {
    background: #f9f9ff;
    padding: 15px 0px 30px 0px;
    min-width: 800px
}

.progress-table .serial {
    width: 11.83%;
    padding-left: 30px
}

.progress-table .country {
    width: 28.07%
}

.progress-table .visit {
    width: 19.74%
}

.progress-table .percentage {
    width: 40.36%;
    padding-right: 50px
}

.progress-table .table-head {
    display: flex
}

.progress-table .table-head .serial,
.progress-table .table-head .country,
.progress-table .table-head .visit,
.progress-table .table-head .percentage {
    color: #415094;
    line-height: 40px;
    text-transform: uppercase;
    font-weight: 500
}

.progress-table .table-row {
    padding: 15px 0;
    border-top: 1px solid #edf3fd;
    display: flex
}

.progress-table .table-row .serial,
.progress-table .table-row .country,
.progress-table .table-row .visit,
.progress-table .table-row .percentage {
    display: flex;
    align-items: center
}

.progress-table .table-row .country img {
    margin-right: 15px
}

.progress-table .table-row .percentage .progress {
    width: 80%;
    border-radius: 0px;
    background: transparent
}

.progress-table .table-row .percentage .progress .progress-bar {
    height: 5px;
    line-height: 5px
}

.progress-table .table-row .percentage .progress .progress-bar.color-1 {
    background-color: #6382e6
}

.progress-table .table-row .percentage .progress .progress-bar.color-2 {
    background-color: #e66686
}

.progress-table .table-row .percentage .progress .progress-bar.color-3 {
    background-color: #f09359
}

.progress-table .table-row .percentage .progress .progress-bar.color-4 {
    background-color: #73fbaf
}

.progress-table .table-row .percentage .progress .progress-bar.color-5 {
    background-color: #73fbaf
}

.progress-table .table-row .percentage .progress .progress-bar.color-6 {
    background-color: #6382e6
}

.progress-table .table-row .percentage .progress .progress-bar.color-7 {
    background-color: #a367e7
}

.progress-table .table-row .percentage .progress .progress-bar.color-8 {
    background-color: #e66686
}

.single-gallery-image {
    margin-top: 30px;
    background-repeat: no-repeat !important;
    background-position: center center !important;
    background-size: cover !important;
    height: 200px
}

.list-style {
    width: 14px;
    height: 14px
}

.unordered-list li {
    position: relative;
    padding-left: 30px;
    line-height: 1.82em !important
}

.unordered-list li:before {
    content: "";
    position: absolute;
    width: 14px;
    height: 14px;
    border: 3px solid #222;
    background: #fff;
    top: 4px;
    left: 0;
    border-radius: 50%
}

.ordered-list {
    margin-left: 30px
}

.ordered-list li {
    list-style-type: decimal-leading-zero;
    color: #222;
    font-weight: 500;
    line-height: 1.82em !important
}

.ordered-list li span {
    font-weight: 300;
    color: #828bb2
}

.ordered-list-alpha li {
    margin-left: 30px;
    list-style-type: lower-alpha;
    color: #222;
    font-weight: 500;
    line-height: 1.82em !important
}

.ordered-list-alpha li span {
    font-weight: 300;
    color: #828bb2
}

.ordered-list-roman li {
    margin-left: 30px;
    list-style-type: lower-roman;
    color: #222;
    font-weight: 500;
    line-height: 1.82em !important
}

.ordered-list-roman li span {
    font-weight: 300;
    color: #828bb2
}

.single-input {
    display: block;
    width: 100%;
    line-height: 40px;
    border: none;
    outline: none;
    background: #f9f9ff;
    padding: 0 20px
}

.single-input:focus {
    outline: none
}

.input-group-icon {
    position: relative
}

.input-group-icon .icon {
    position: absolute;
    left: 20px;
    top: 0;
    line-height: 40px;
    z-index: 3
}

.input-group-icon .icon i {
    color: #797979
}

.input-group-icon .single-input {
    padding-left: 45px
}

.single-textarea {
    display: block;
    width: 100%;
    line-height: 40px;
    border: none;
    outline: none;
    background: #f9f9ff;
    padding: 0 20px;
    height: 100px;
    resize: none
}

.single-textarea:focus {
    outline: none
}

.single-input-primary {
    display: block;
    width: 100%;
    line-height: 40px;
    border: 1px solid transparent;
    outline: none;
    background: #f9f9ff;
    padding: 0 20px
}

.single-input-primary:focus {
    outline: none;
    border: 1px solid #222
}

.single-input-accent {
    display: block;
    width: 100%;
    line-height: 40px;
    border: 1px solid transparent;
    outline: none;
    background: #f9f9ff;
    padding: 0 20px
}

.single-input-accent:focus {
    outline: none;
    border: 1px solid #eb6b55
}

.single-input-secondary {
    display: block;
    width: 100%;
    line-height: 40px;
    border: 1px solid transparent;
    outline: none;
    background: #f9f9ff;
    padding: 0 20px
}

.single-input-secondary:focus {
    outline: none;
    border: 1px solid #f09359
}

.default-switch {
    width: 35px;
    height: 17px;
    border-radius: 8.5px;
    background: #f9f9ff;
    position: relative;
    cursor: pointer
}

.default-switch input {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer
}

.default-switch input+label {
    position: absolute;
    top: 1px;
    left: 1px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #222;
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
    box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.2);
    cursor: pointer
}

.default-switch input:checked+label {
    left: 19px
}

.primary-switch {
    width: 35px;
    height: 17px;
    border-radius: 8.5px;
    background: #f9f9ff;
    position: relative;
    cursor: pointer
}

.primary-switch input {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    opacity: 0
}

.primary-switch input+label {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%
}

.primary-switch input+label:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    border-radius: 8.5px;
    cursor: pointer;
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s
}

.primary-switch input+label:after {
    content: "";
    position: absolute;
    top: 1px;
    left: 1px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #fff;
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
    box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.2);
    cursor: pointer
}

.primary-switch input:checked+label:after {
    left: 19px
}

.primary-switch input:checked+label:before {
    background: #222
}

.confirm-switch {
    width: 35px;
    height: 17px;
    border-radius: 8.5px;
    background: #f9f9ff;
    position: relative;
    cursor: pointer
}

.confirm-switch input {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    opacity: 0
}

.confirm-switch input+label {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%
}

.confirm-switch input+label:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    border-radius: 8.5px;
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
    cursor: pointer
}

.confirm-switch input+label:after {
    content: "";
    position: absolute;
    top: 1px;
    left: 1px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #fff;
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
    box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.2);
    cursor: pointer
}

.confirm-switch input:checked+label:after {
    left: 19px
}

.confirm-switch input:checked+label:before {
    background: #4cd3e3
}

.primary-checkbox {
    width: 16px;
    height: 16px;
    border-radius: 3px;
    background: #f9f9ff;
    position: relative;
    cursor: pointer
}

.primary-checkbox input {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    opacity: 0
}

.primary-checkbox input+label {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    border-radius: 3px;
    cursor: pointer;
    border: 1px solid #f1f1f1
}

.primary-checkbox input:checked+label {
    background: url(/src/assets/img/elements/primary-check.png) no-repeat center center/cover;
    border: none
}

.confirm-checkbox {
    width: 16px;
    height: 16px;
    border-radius: 3px;
    background: #f9f9ff;
    position: relative;
    cursor: pointer
}

.confirm-checkbox input {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    opacity: 0
}

.confirm-checkbox input+label {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    border-radius: 3px;
    cursor: pointer;
    border: 1px solid #f1f1f1
}

.confirm-checkbox input:checked+label {
    background: url(/src/assets/img/elements/success-check.png) no-repeat center center/cover;
    border: none
}

.disabled-checkbox {
    width: 16px;
    height: 16px;
    border-radius: 3px;
    background: #f9f9ff;
    position: relative;
    cursor: pointer
}

.disabled-checkbox input {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    opacity: 0
}

.disabled-checkbox input+label {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    border-radius: 3px;
    cursor: pointer;
    border: 1px solid #f1f1f1
}

.disabled-checkbox input:disabled {
    cursor: not-allowed;
    z-index: 3
}

.disabled-checkbox input:checked+label {
    background: url(/src/assets/img/elements/disabled-check.png) no-repeat center center/cover;
    border: none
}

.primary-radio {
    width: 16px;
    height: 16px;
    border-radius: 8px;
    background: #f9f9ff;
    position: relative;
    cursor: pointer
}

.primary-radio input {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    opacity: 0
}

.primary-radio input+label {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    cursor: pointer;
    border: 1px solid #f1f1f1
}

.primary-radio input:checked+label {
    background: url(/src/assets/img/elements/primary-radio.png) no-repeat center center/cover;
    border: none
}

.confirm-radio {
    width: 16px;
    height: 16px;
    border-radius: 8px;
    background: #f9f9ff;
    position: relative;
    cursor: pointer
}

.confirm-radio input {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    opacity: 0
}

.confirm-radio input+label {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    cursor: pointer;
    border: 1px solid #f1f1f1
}

.confirm-radio input:checked+label {
    background: url(/src/assets/img/elements/success-radio.png) no-repeat center center/cover;
    border: none
}

.disabled-radio {
    width: 16px;
    height: 16px;
    border-radius: 8px;
    background: #f9f9ff;
    position: relative;
    cursor: pointer
}

.disabled-radio input {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    opacity: 0
}

.disabled-radio input+label {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    cursor: pointer;
    border: 1px solid #f1f1f1
}

.disabled-radio input:disabled {
    cursor: not-allowed;
    z-index: 3
}

.disabled-radio input:checked+label {
    background: url(/src/assets/img/elements/disabled-radio.png) no-repeat center center/cover;
    border: none
}

.default-select {
    height: 40px
}

.default-select .nice-select {
    border: none;
    border-radius: 0px;
    height: 40px;
    background: #f9f9ff;
    padding-left: 20px;
    padding-right: 40px
}

.default-select .nice-select .list {
    margin-top: 0;
    border: none;
    border-radius: 0px;
    box-shadow: none;
    width: 100%;
    padding: 10px 0 10px 0px
}

.default-select .nice-select .list .option {
    font-weight: 300;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    line-height: 28px;
    min-height: 28px;
    font-size: 12px;
    padding-left: 20px
}

.default-select .nice-select .list .option.selected {
    color: #222;
    background: transparent
}

.default-select .nice-select .list .option:hover {
    color: #222;
    background: transparent
}

.default-select .current {
    margin-right: 50px;
    font-weight: 300
}

.default-select .nice-select::after {
    right: 20px
}

.form-select {
    height: 40px;
    width: 100%
}

.form-select .nice-select {
    border: none;
    border-radius: 0px;
    height: 40px;
    background: #f9f9ff;
    padding-left: 45px;
    padding-right: 40px;
    width: 100%
}

.form-select .nice-select .list {
    margin-top: 0;
    border: none;
    border-radius: 0px;
    box-shadow: none;
    width: 100%;
    padding: 10px 0 10px 0px
}

.form-select .nice-select .list .option {
    font-weight: 300;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    line-height: 28px;
    min-height: 28px;
    font-size: 12px;
    padding-left: 45px
}

.form-select .nice-select .list .option.selected {
    color: #222;
    background: transparent
}

.form-select .nice-select .list .option:hover {
    color: #222;
    background: transparent
}

.form-select .current {
    margin-right: 50px;
    font-weight: 300
}

.form-select .nice-select::after {
    right: 20px
}

.mt-10 {
    margin-top: 10px
}

.section-top-border {
    padding: 50px 0;
    border-top: 1px dotted #eee
}

.mb-30 {
    margin-bottom: 30px
}

.mt-30 {
    margin-top: 30px
}

.switch-wrap {
    margin-bottom: 10px
}

.submit_btn {
    width: auto;
    display: inline-block;
    background: #fff;
    padding: 0px 50px;
    color: #fff;
    font-size: 13px;
    font-weight: 500;
    line-height: 50px;
    border-radius: 5px;
    outline: none !important;
    box-shadow: none !important;
    text-align: center;
    border: 1px solid #fdcb9e;
    cursor: pointer;
    -webkit-transition: .5s;
    -moz-transition: .5s;
    -o-transition: .5s;
    transition: .5s
}

.submit_btn:hover {
    background: transparent
}

.btn_1 {
    display: inline-block;
    padding: 17px 50px;
    border-radius: 5px;
    background-color: #f9503f;
    border: 2px solid #f9503f;
    font-size: 15px;
    font-weight: 500;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    text-transform: capitalize;
    color: #fff
}

.btn_1:hover {
    background-color: #fff;
    color: #f76c5e
}

.btn_3 {
    display: inline-block;
    padding: 18px 36px;
    border-radius: 5px;
    background-color: transparent;
    border: 1px solid #f9503f;
    font-size: 15px;
    font-weight: 700;
    color: #f9503f;
    text-transform: uppercase;
    font-weight: 400;
    -webkit-transition: .5s;
    -moz-transition: .5s;
    -o-transition: .5s;
    transition: .5s
}

.btn_3:hover {
    background-color: #f9503f;
    color: #fff
}

.btn_2 {
    display: inline-block;
    width: 180px;
    height: 60px;
    line-height: 60px;
    text-align: center;
    background-color: #fff;
    font-size: 15px;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    color: #1e1e1e;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
    text-transform: capitalize
}

.btn_2:hover {
    color: #fff !important;
    background-color: #f9503f
}

@media (max-width: 991px) {
    .btn_2 {
        height: 50px;
        width: 140px;
        line-height: 50px
    }
}

.btn_4 {
    color: #f76c5e;
    font-size: 15px;
    font-weight: 700;
    margin-top: 53px;
    display: inline-block;
    -webkit-transition: .5s;
    -moz-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
    border-radius: 5px
}

@media (max-width: 991px) {
    .btn_4 {
        margin-top: 30px
    }
}

.btn_4 img {
    width: 20px;
    margin-left: 15px
}

.button {
    display: inline-block;
    border: 1px solid transparent;
    font-size: 15px;
    font-weight: 500;
    padding: 12px 54px;
    border-radius: 4px;
    color: #fff;
    border: 1px solid #fdcb9e;
    text-transform: uppercase;
    background-color: #f76c5e;
    cursor: pointer;
    -webkit-transition: .5s;
    -moz-transition: .5s;
    -o-transition: .5s;
    transition: .5s
}

@media (max-width: 767px) {
    .button {
        font-size: 13px;
        padding: 9px 24px
    }
}

.button:hover {
    color: #fff
}

.button-link {
    letter-spacing: 0;
    color: #3b1d82;
    border: 0;
    padding: 0
}

.button-link:hover {
    background: transparent;
    color: #3b1d82
}

.button-header {
    color: #fff;
    border-color: #fdcb9e
}

.button-header:hover {
    background: #b8024c;
    color: #fff
}

.button-contactForm {
    color: #fff;
    border-color: #fdcb9e;
    padding: 12px 25px
}

.blog-area .single-blog {
    box-shadow: 1.395px 19.951px 38px 0px rgba(0, 0, 0, 0.05);
    transition: .4s
}

.blog-area .single-blog:hover .blog-img img {
    transform: rotate(1deg) scale(1.1)
}

.blog-area .single-blog .blog-img {
    overflow: hidden
}

.blog-area .single-blog .blog-img img {
    width: 100%;
    transform: rotate(0deg) scale(1);
    -webkit-transition: .4s;
    -moz-transition: .4s;
    -o-transition: .4s;
    transition: .4s
}

.blog-area .single-blog .blog-caption {
    padding: 22px 40px 20px 40px
}

@media (max-width: 575px) {
    .blog-area .single-blog .blog-caption {
        padding: 22px 22px 20px 20px
    }
}

.blog-area .single-blog .blog-caption .blog-cap-top span {
    color: #fff;
    background: #f76c5e;
    padding: 4px 13px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 300;
    border-radius: 5px
}

.blog-area .single-blog .blog-caption .blog-cap-top ul li {
    color: #c7c7c7;
    font-size: 14px;
    font-family: 'Lato', sans-serif
}

.blog-area .single-blog .blog-caption .blog-cap-top ul li a {
    color: #f76c5e;
    font-size: 14px
}

.blog-area .single-blog .blog-caption .blog-cap-mid p a {
    color: #444444;
    font-size: 18px;
    font-family: 'Lato', sans-serif;
    line-height: 28px;
    margin-bottom: 30px
}

@media (max-width: 575px) {
    .blog-area .single-blog .blog-caption .blog-cap-mid p a {
        font-size: 14px
    }
}

.blog-area .single-blog .blog-caption .blog-cap-mid p a:hover {
    color: #f76c5e
}

.blog-area .single-blog .blog-caption .blog-cap-bottom {
    padding-top: 20px;
    border-top: 1px solid #f9f9f9
}

.blog-area .single-blog .blog-caption .blog-cap-bottom span {
    color: #adadad;
    font-size: 14px
}

.blog-area .single-blog .blog-caption .blog-cap-bottom span img {
    margin-right: 5px
}

.pb-168 {
    padding-bottom: 168px
}

.single_blog_post .desc a {
    font-size: 16px;
    color: #232b2b !important
}

.single_blog_post .single_blog .single_appartment_content {
    padding: 38px 38px 23px;
    border: 0px solid #fdcb9e;
    box-shadow: 0px 10px 20px 0px rgba(221, 221, 221, 0.3)
}

.single_blog_post .single_blog .single_appartment_content p {
    font-size: 12px;
    text-transform: uppercase;
    margin-bottom: 20px
}

.single_blog_post .single_blog .single_appartment_content p a {
    color: #f76c5e
}

.single_blog_post .single_blog .single_appartment_content h4 {
    font-size: 24px;
    font-weight: 600;
    line-height: 1.481;
    margin-bottom: 16px
}

.single_blog_post .single_blog .single_appartment_content h5 {
    font-size: 15px;
    color: #8a8a8a;
    font-weight: 400
}

.single_blog_post .single_blog .single_appartment_content .list-unstyled {
    margin-top: 33px
}

.single_blog_post .single_blog .single_appartment_content .list-unstyled li {
    display: inline;
    margin-right: 17px;
    color: #999
}

.single_blog_post .single_blog .single_appartment_content .list-unstyled li a {
    margin-right: 8px;
    color: #999
}

.single_product_list {
    padding: 200px 0 100px
}

@media (max-width: 991px) {
    .single_product_list {
        padding: 100px 0 0
    }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .single_product_list {
        padding: 100px 0 100px
    }
}

.single_product_list .single_product_img {
    position: relative;
    margin: 35px 0 0 35px
}

@media (max-width: 991px) {
    .single_product_list .single_product_img {
        max-width: 204px
    }
}

.single_product_list .single_product_img .product_overlay {
    position: absolute;
    left: -35px;
    top: -35px;
    z-index: -1
}

.single_product_list .single_product_content h5 {
    font-weight: 500;
    font-size: 20px;
    color: #B08EAD
}

@media (max-width: 991px) {
    .single_product_list .single_product_content h5 {
        font-size: 16px
    }
}

.single_product_list .single_product_content h2 {
    font-size: 40px;
    line-height: 1.5;
    font-weight: 500;
    margin: 10px 0 50px
}

.single_product_list .single_product_content h2 a {
    color: #191d34
}

.single_product_list .single_product_content h2 a:hover {
    color: #828bb2
}

@media (max-width: 991px) {
    .single_product_list .single_product_content h2 {
        font-size: 25px;
        margin: 10px 0 30px
    }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .single_product_list .single_product_content h2 {
        font-size: 30px;
        margin: 10px 0 30px
    }
}

@media (max-width: 991px) {
    .single_product_list .single_product_content {
        margin-top: 80px
    }

    .single_product_list .single_product_content .btn_3 {
        padding: 13px 29px
    }
}

.single_product_list .single_product_iner {
    margin-bottom: 100px
}

@media (max-width: 991px) {
    .single_product_list .single_product_iner {
        margin-bottom: 60px
    }
}

.single_product_list .single_product_iner:nth-child(2n-2) .row {
    flex-direction: row-reverse;
    margin-top: 140px
}

@media (max-width: 991px) {
    .single_product_list .single_product_iner:nth-child(2n-2) .row {
        margin-bottom: 70px;
        margin-top: 100px
    }
}

@media (max-width: 991px) {
    .single_product_list .single_product_iner:nth-child(2n-2) .row .single_product_content {
        margin-top: 40px
    }

    .single_product_list .single_product_iner:nth-child(2n-2) .row .single_product_content .btn_3 {
        padding: 13px 29px
    }
}

.single_product_list .single_product_iner:nth-child(2n-2) .row .single_product_img {
    position: relative;
    margin: 0 35px 35px 0
}

.single_product_list .single_product_iner:nth-child(2n-2) .row .single_product_img .product_overlay {
    position: absolute;
    left: auto;
    right: -35px;
    top: -35px;
    z-index: -1
}

.single_product_breadcrumb {
    height: 460px !important
}

@media (max-width: 991px) {
    .single_product_breadcrumb {
        height: 250px !important
    }
}

.product_image_area {
    margin-top: 150px
}

@media (max-width: 991px) {
    .product_image_area {
        margin-top: -150px !important
    }
}

.product_image_area .owl-nav button.owl-prev,
.product_image_area .owl-nav button.owl-next {
    width: 40px;
    height: 40px;
    font-size: 14px;
    line-height: 40px;
    border-radius: 50%;
    border: 1px solid #E4D3DF;
    color: #fff;
    background-color: #E4D3DF;
    position: absolute;
    left: 30px;
    top: 54%;
    -webkit-transition: .5s;
    transition: .5s
}

.product_image_area .owl-nav button.owl-prev:hover,
.product_image_area .owl-nav button.owl-next:hover {
    background-color: #828bb2;
    color: #fff
}

.product_image_area .owl-nav button.owl-next {
    text-indent: 2px;
    left: auto;
    right: 30px
}

.product_image_area .single_product_text {
    margin: 100px 0 200px
}

@media (max-width: 991px) {
    .product_image_area .single_product_text {
        margin: 40px 0 70px
    }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .product_image_area .single_product_text {
        margin: 50px 0 80px
    }
}

.product_image_area .single_product_text h3 {
    font-size: 40px
}

@media (max-width: 991px) {
    .product_image_area .single_product_text h3 {
        font-size: 25px
    }

    .product_image_area .single_product_text h3 br {
        display: none
    }
}

.product_image_area .single_product_text p {
    margin-top: 50px;
    color: #707070
}

@media (max-width: 991px) {
    .product_image_area .single_product_text p {
        margin-top: 20px
    }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .product_image_area .single_product_text p {
        margin-top: 20px
    }
}

.card_area {
    margin-top: 60px
}

.card_area .product_count_area {
    display: flex;
    align-items: center;
    justify-content: center
}

.card_area .product_count_area p {
    margin-top: 0 !important;
    color: #828bb2
}

.card_area .product_count {
    border: 1px solid #828bb2;
    margin: 0 35px
}

@media (max-width: 991px) {
    .card_area .product_count {
        margin: 0 15px
    }
}

.card_area .product_count .product_count_item {
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    display: inline-block
}

@media (max-width: 991px) {
    .card_area .product_count .product_count_item {
        width: 45px
    }
}

.card_area .product_count input {
    background-color: #828bb2;
    color: #fff;
    border: 1px solid #828bb2;
    color: #fff
}

.card_area .add_to_cart {
    margin-top: 60px
}

input:focus {
    outline-offset: 0
}

.trending_items {
    background-color: #F4EDF2;
    padding: 200px 0 130px
}

@media (max-width: 991px) {
    .trending_items {
        padding: 70px 0 40px
    }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .trending_items {
        padding: 100px 0 70px
    }
}

.single_product_item {
    margin-bottom: 58px
}

@media (max-width: 991px) {
    .single_product_item {
        margin-bottom: 30px
    }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .single_product_item {
        margin-bottom: 30px
    }
}

.single_product_item .single_product_item_thumb {
    background-color: #fff;
    margin: 0 auto;
    text-align: center
}

.single_product_item h3 {
    font-size: 24px;
    font-weight: 400;
    margin-top: 22px;
    line-height: 1.5;
    margin-bottom: 0
}

@media (max-width: 991px) {
    .single_product_item h3 {
        font-size: 18px
    }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .single_product_item h3 {
        font-size: 18px
    }
}

.single_product_item h3 a {
    color: #191d34
}

.single_product_item h3 a:hover {
    color: #f9503f
}

.single_product_item p {
    font-size: 20px
}

@media (max-width: 991px) {
    .single_product_item p {
        font-size: 16px
    }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .single_product_item p {
        font-size: 16px
    }
}

.client_review {
    padding: 100px 0;
    text-align: center;
    background-color: #B08EAD;
    position: relative
}

@media (max-width: 991px) {
    .client_review {
        padding: 70px 0
    }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .client_review {
        padding: 80px 0
    }
}

.client_review img {
    width: 100%;
    border-radius: 50%
}

.client_review p {
    font-size: 24px;
    line-height: 1.5;
    color: #fff
}

@media (max-width: 991px) {
    .client_review p {
        font-size: 18px
    }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .client_review p {
        font-size: 20px
    }
}

.client_review h5 {
    font-size: 16px;
    margin-top: 25px;
    color: #fff;
    margin-bottom: 0
}

.client_review .client_img {
    max-width: 105px;
    margin: 0 auto;
    margin-bottom: 35px;
    position: relative
}

.client_review .client_img:after {
    position: absolute;
    content: "";
    right: -8px;
    bottom: -10px;
    content: '\f100';
    font-family: 'Flaticon';
    height: 50px;
    width: 50px;
    background-color: #E4D3DF;
    font-size: 14px;
    border-radius: 50%;
    line-height: 50px;
    color: #fff
}

.client_review .owl-nav button.owl-prev,
.client_review .owl-nav button.owl-next {
    width: 40px;
    height: 40px;
    font-size: 14px;
    line-height: 40px;
    border-radius: 50%;
    border: 1px solid #828bb2;
    color: #828bb2;
    position: absolute;
    left: -26%;
    top: 48%;
    -webkit-transition: .5s;
    transition: .5s
}

.client_review .owl-nav button.owl-prev:hover,
.client_review .owl-nav button.owl-next:hover {
    background-color: #828bb2;
    color: #fff
}

.client_review .owl-nav button.owl-next {
    text-indent: 2px;
    left: auto;
    right: -26%
}

.feature_part .feature_part_tittle h3 {
    font-size: 40px;
    line-height: 1.5;
    font-weight: 500
}

@media (max-width: 991px) {
    .feature_part .feature_part_tittle h3 {
        font-size: 25px
    }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .feature_part .feature_part_tittle h3 {
        font-size: 30px
    }
}

.feature_part .feature_part_content p {
    color: #707070
}

.feature_part .single_feature_part {
    text-align: center;
    border: 1px solid #828bb2;
    padding: 35px;
    margin-top: 100px
}

@media (max-width: 991px) {
    .feature_part .single_feature_part {
        margin-top: 30px;
        padding: 25px
    }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .feature_part .single_feature_part {
        padding: 25px 14px
    }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .feature_part .single_feature_part {
        margin-top: 30px
    }
}

.feature_part .single_feature_part img {
    max-width: 42px;
    min-height: 40px;
    margin-bottom: 20px
}

.feature_part .single_feature_part h4 {
    font-size: 18px;
    font-weight: 400;
    line-height: 1.5;
    margin-bottom: 0
}

.subscribe_part {
    background-color: #191d34
}

.subscribe_part .subscribe_part_content {
    text-align: center
}

.subscribe_part .subscribe_part_content h2 {
    color: #fff
}

@media (max-width: 991px) {
    .subscribe_part .subscribe_part_content h2 {
        font-size: 25px
    }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .subscribe_part .subscribe_part_content h2 {
        font-size: 30px
    }
}

.subscribe_part .subscribe_part_content p {
    color: #fff
}

.subscribe_part .subscribe_form {
    margin: 0 auto;
    margin-top: 45px;
    position: relative;
    max-width: 558px
}

.subscribe_part .subscribe_form input {
    border: 1px solid #fff;
    width: 100%;
    background-color: transparent;
    color: #fff;
    border-radius: 50px;
    padding: 19px 30px;
    line-height: 20px
}

.subscribe_part .subscribe_form .btn_1 {
    padding: 15px 30px;
    line-height: 16px;
    border-radius: 50px;
    position: absolute;
    right: 5px;
    top: 5px
}

@media (max-width: 991px) {
    .subscribe_part .subscribe_form .btn_1 {
        padding: 15px 15px
    }
}

.subscribe_part .subscribe_form ::placeholder {
    color: #E4D3DF
}

.product_sidebar .single_sedebar {
    position: relative;
    margin-bottom: 20px
}

.product_sidebar .single_sedebar input {
    background-color: #F4EDF2;
    border-radius: 50px;
    border: 1px solid #F4EDF2;
    padding: 13px 25px;
    width: 100%;
    line-height: 22px
}

.product_sidebar .single_sedebar ::placeholder {
    color: #795376
}

.product_sidebar .single_sedebar i {
    position: absolute;
    right: 30px;
    top: 17px
}

.select_option .select_option_list {
    background-color: #F4EDF2;
    border-radius: 50px;
    border: 1px solid #F4EDF2;
    padding: 13px 25px;
    width: 100%;
    line-height: 22px;
    color: #795376
}

.select_option .select_option_dropdown {
    background-color: #F4EDF2;
    border-radius: 10px;
    border: 1px solid #F4EDF2;
    padding: 13px 25px;
    width: 100%;
    margin-top: 10px
}

.select_option .select_option_dropdown a {
    color: #795376
}

.our_offer {
    background-color: #f7f7f7
}

.our_offer .date_countdown {
    margin: 37px 0 55px
}

.our_offer .date_countdown #timer {
    display: flex;
    justify-content: space-between
}

.our_offer h2 {
    font-size: 50px;
    line-height: 70px;
    font-weight: 700
}

@media (max-width: 991px) {
    .our_offer h2 {
        font-size: 30px;
        line-height: 35px;
        margin-top: 30px
    }
}

.our_offer .date {
    font-size: 36px;
    color: #000;
    font-weight: 700;
    border-right: 1px solid #fdcb9e;
    padding-right: 9%
}

@media (max-width: 991px) {
    .our_offer .date {
        padding-right: 4%
    }
}

.our_offer .date:last-child {
    border-right: 0px solid transparent
}

.our_offer span {
    display: block;
    font-size: 13px;
    color: #666;
    text-transform: uppercase
}

.our_offer .input-group input {
    height: 60px;
    padding-left: 25px;
    border: 0px solid transparent;
    background-color: #fff
}

.our_offer .input-group input::placeholder {
    text-transform: capitalize
}

.our_offer .input-group .input-group-text {
    height: 60px;
    line-height: 60px;
    padding: 0px 45px;
    border: 0px solid transparent;
    background-color: #f9503f;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase
}

@media (max-width: 991px) {
    .our_offer .input-group .input-group-text {
        padding: 0px 15px
    }
}

.best_seller .single_product_item {
    border-radius: 5px;
    margin-bottom: 20px;
    -webkit-transition: .5s;
    transition: .5s
}

.best_seller .single_product_item .single_product_text {
    padding: 35px 0 0;
    background-color: #fff;
    -webkit-transition: .5s;
    transition: .5s
}

.best_seller .single_product_item .single_product_text h4 {
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 14px
}

.best_seller .single_product_item .single_product_text h3 {
    font-weight: 300;
    font-size: 18px
}

.best_seller .single_product_item .single_product_text a {
    color: #f9503f;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 500;
    display: block;
    margin-top: 10px;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: .5s;
    transition: .5s
}

.best_seller .single_product_item .single_product_text a i {
    float: right;
    font-size: 18px;
    line-height: 26px;
    color: #000
}

.best_seller .single_product_item:hover {
    box-shadow: none
}

.best_seller .single_product_item:hover .single_product_text {
    padding: 32px 0 0
}

.best_seller .single_product_item:hover a {
    opacity: 1;
    visibility: visible
}

.client_logo .single_client_logo {
    height: 125px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-left: 1px solid #fdcb9e;
    width: 20%;
    float: left;
    border-bottom: 1px solid #fdcb9e
}

@media (max-width: 576px) {
    .client_logo .single_client_logo {
        height: 70px;
        width: 25%;
        padding: 6px;
        border-bottom: 0px solid transparent;
        border-left: 0px solid transparent
    }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
    .client_logo .single_client_logo {
        height: 100px;
        padding: 8px
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .client_logo .single_client_logo {
        height: 100px;
        padding: 10px
    }
}

.client_logo .single_client_logo:nth-child(5n+1) {
    border-left: 0px solid transparent
}

.client_logo .single_client_logo:nth-child(n+6) {
    border-bottom: 0px solid transparent
}

.client_logo .single_client_logo img {
    filter: grayscale(1);
    -webkit-transition: .5s;
    transition: .5s
}

.client_logo .single_client_logo img:hover {
    filter: grayscale(0)
}

.single-product {
    text-align: center;
    margin-bottom: 50px
}

.single-product .product-img {
    position: relative;
    overflow: hidden
}

.single-product .product-img .p_icon {
    width: 90%;
    padding: 7px 30px;
    position: absolute;
    bottom: -100px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
    background: rgba(37, 119, 253, 0.4);
    transition: all 400ms ease
}

.single-product .product-img .p_icon a {
    display: inline-block;
    height: 36px;
    line-height: 40px;
    width: 36px;
    text-align: center;
    background: #fff;
    border-radius: 30px;
    color: #415094;
    margin-right: 25px
}

.single-product .product-img .p_icon a:last-child {
    margin-right: 0px
}

.single-product .product-img .p_icon a:hover {
    color: #fff;
    background: #f9503f
}

.single-product .product-btm {
    padding: 22px 25px 18px;
    border: 1px solid #f0f2f1;
    text-align: left
}

.single-product .product-btm h4 {
    color: #4a4a4a;
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
    margin-bottom: 0px;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s
}

.single-product .product-btm h5 {
    margin-bottom: 0px;
    font-size: 18px;
    color: #828bb2
}

.single-product .product-btm span {
    font-family: "Poppins", sans-serif;
    font-size: 20px;
    font-weight: 500;
    line-height: 16px;
    color: #415094
}

.single-product:hover .product-img .p_icon {
    bottom: 0px
}

.single-product:hover .product-btm h4 {
    color: #f9503f
}

.new_product {
    background: #f9f9ff;
    padding: 90px 15px;
    text-align: center
}

.new_product .product-img {
    padding: 90px 0px
}

.new_product h5 {
    font-size: 20px;
    color: #4a4a4a;
    margin-bottom: 20px
}

.new_product h3 {
    font-size: 32px;
    font-weight: 700
}

.new_product h4 {
    font-size: 30px;
    font-weight: 400;
    margin-bottom: 25px
}

.most_product_inner {
    margin-bottom: -30px
}

.most_p_list .media {
    margin-bottom: 30px
}

.most_p_list .media .d-flex {
    padding-right: 20px
}

.most_p_list .media .media-body {
    vertical-align: middle;
    align-self: center
}

.most_p_list .media .media-body h4 {
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: normal;
    color: #415094
}

.most_p_list .media .media-body h3 {
    font-size: 16px;
    font-weight: 500;
    color: #828bb2;
    margin-bottom: 0px
}

.cat_product_area .btn_2 {
    border: 1px solid #606060;
    color: #606060;
    margin-top: 80px
}

.cat_product_area .latest_product_inner {
    padding-top: 30px
}

.cat_product_area .latest_product_inner .single-product {
    margin-bottom: 50px
}

.sub-menu ul {
    margin-top: 15px;
    padding-top: 15px;
    padding-left: 25px;
    border-top: 1px solid #828bb2
}

.left_widgets {
    margin-bottom: 30px
}

.left_widgets:last-child {
    margin-bottom: 0px
}

.widgets_inner p {
    font-size: 14px;
    text-transform: capitalize;
    color: #000;
    font-weight: 400;
    margin-bottom: 20px
}

.widgets_inner input[type=checkbox],
.widgets_inner input[type=radio] {
    margin-right: 15px
}

.widgets_inner {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 0px;
    padding-bottom: 15px
}

@media (max-width: 991px) {
    .widgets_inner {
        padding-left: 15px;
        padding-right: 15px
    }
}

@media (max-width: 991px) {
    .l_w_title {
        padding-left: 0;
        padding-right: 0
    }
}

.l_w_title h3 {
    margin-bottom: 20px;
    font-size: 14px;
    font-family: "Playfair Display", serif;
    color: #415094;
    font-weight: 500;
    line-height: 40px;
    position: relative;
    background-color: #454545;
    padding: 10px 15px 10px 30px;
    text-transform: uppercase
}

.product_top_bar {
    flex-wrap: wrap
}

.product_bar_single {
    margin-left: 20px;
    border-radius: 0
}

.product_bar_single .nice-select {
    background-color: #fff;
    text-transform: capitalize;
    border: 1px solid #eee;
    color: #606060;
    border-radius: 0;
    padding-left: 30px;
    padding-right: 70px;
    border-radius: 0
}

.product_bar_single .nice-select:after {
    height: 8px;
    margin-top: -6px;
    right: 16px;
    width: 8px
}

.p_filter_widgets .widgets_inner {
    border-bottom: 1px solid #eeeeee
}

.p_filter_widgets .widgets_inner:last-child {
    border-bottom: 0px
}

.p_filter_widgets .list {
    border-top: 1px solid #ddd;
    padding-top: 25px;
    margin-top: 25px
}

.p_filter_widgets .list:first-child {
    padding-top: 0;
    margin-top: 0;
    border-top: 0px solid transparent
}

.p_filter_widgets .list li {
    margin-bottom: 18px;
    -webkit-transition: .5s;
    transition: .5s
}

.p_filter_widgets .list li a {
    font-size: 14px;
    font-weight: normal;
    color: #606060;
    position: relative
}

.p_filter_widgets .list li.active a:before,
.p_filter_widgets .list li:hover a:before {
    background: #f9503f;
    border-color: #f9503f
}

.p_filter_widgets .list li:last-child {
    margin-bottom: 0px
}

.product_top_bar {
    clear: both;
    display: flex;
    padding: 10px 0;
    margin-bottom: 40px
}

@media (max-width: 576px) {
    .product_top_bar {
        display: block !important
    }
}

.product_top_bar h2 {
    margin-bottom: 0
}

@media (max-width: 991px) {
    .product_top_bar h2 {
        margin-bottom: 20px
    }
}

.product_top_bar .left_dorp .sorting {
    display: inline-block;
    clear: none;
    border: 1px solid #eeeeee;
    border-radius: 0px;
    height: 40px;
    width: 210px;
    padding-left: 20px
}

.product_top_bar .left_dorp .sorting span {
    font-size: 14px;
    font-family: "Playfair Display", serif;
    color: #555555
}

.product_top_bar .left_dorp .sorting .list {
    width: 100%;
    border-radius: 0px
}

.product_top_bar .left_dorp .sorting .list li {
    font-size: 14px;
    font-family: "Playfair Display", serif;
    color: #555555
}

.product_top_bar .left_dorp .sorting:after {
    content: "\f0d7";
    font: normal normal normal 14px/1 FontAwesome;
    transform: rotate(0);
    border: none;
    color: #555555;
    margin-top: -6px;
    right: 20px
}

.product_top_bar .left_dorp .show {
    clear: none;
    display: inline-block;
    clear: none;
    border: 1px solid #eeeeee;
    border-radius: 0px;
    height: 40px;
    width: 120px;
    padding-left: 20px;
    margin-left: 10px
}

.product_top_bar .left_dorp .show span {
    font-size: 14px;
    font-family: "Playfair Display", serif;
    color: #555555
}

.product_top_bar .left_dorp .show .list {
    width: 100%;
    border-radius: 0px
}

.product_top_bar .left_dorp .show .list li {
    font-size: 14px;
    font-family: "Playfair Display", serif;
    color: #555555
}

.product_top_bar .left_dorp .show:after {
    content: "\f0d7";
    font: normal normal normal 14px/1 FontAwesome;
    transform: rotate(0);
    border: none;
    color: #555555;
    margin-top: -6px;
    right: 20px
}

@media (max-width: 576px) {
    .product_top_bar .single_product_menu {
        width: 50%;
        float: left;
        margin-bottom: 20px
    }
}

.product_top_bar .single_product_menu p {
    color: #020202;
    font-weight: 500
}

@media (max-width: 576px) {
    .product_top_bar .single_product_menu p {
        font-size: 13px;
        line-height: 13px
    }
}

.product_top_bar .single_product_menu p span {
    color: #f9503f
}

.product_top_bar .single_product_menu h5 {
    font-size: 13px;
    font-weight: 500;
    text-transform: capitalize;
    margin-bottom: 0
}

.product_top_bar .single_product_menu .nice-select {
    height: 0;
    border: 0px;
    line-height: 15px;
    font-weight: 300;
    text-transform: capitalize
}

.product_top_bar .single_product_menu .nice-select:after {
    margin-top: 0;
    border-bottom: 1px solid #999;
    border-right: 1px solid #999;
    right: 0px
}

.product_top_bar .single_product_menu .top_pageniation {
    line-height: 15px
}

.product_top_bar .single_product_menu .top_pageniation ul {
    margin-left: 20px
}

.product_top_bar .single_product_menu .top_pageniation ul li {
    display: inline-block;
    margin-left: 10px
}

.product_top_bar .single_product_menu .input-group {
    width: 150px
}

.product_top_bar .single_product_menu .input-group .form-control {
    border: 0px solid transparent;
    border-bottom: 1px solid #ddd;
    border-radius: 0;
    text-align: center;
    text-transform: capitalize
}

.product_top_bar .single_product_menu .input-group .input-group-text {
    background-color: transparent;
    border: 0px solid transparent;
    border-bottom: 1px solid #ddd
}

.most_p_withoutbox {
    padding-bottom: 120px
}

.range_item p {
    margin-bottom: 0
}

.price_value input {
    border: 0px;
    text-align: center;
    max-width: 30px;
    background-color: transparent
}

.price_value {
    align-items: center
}

.irs-to,
.irs-from,
.irs-max,
.irs-min {
    display: none
}

.price_rangs_aside .l_w_title h3 {
    line-height: 20px;
    margin-bottom: 0px
}

.irs-bar {
    height: 6px;
    top: 33px;
    border-top: 1px solid #ff3368;
    border-bottom: 1px solid #ff3368;
    background: #ff3368;
    background: linear-gradient(to top, #ff3368 0%, #ff3368 100%)
}

.irs-line {
    height: 6px;
    top: 33px;
    background: #e8eff1;
    background: linear-gradient(to bottom, #e8eff1 -50%, #e8eff1 150%);
    border: 1px solid #e8eff1;
    border-radius: 16px;
    -moz-border-radius: 16px
}

.irs-slider {
    height: 15px;
    width: 15px;
    border: 1px solid #ff3368;
    background-color: #ff3368;
    background: linear-gradient(to bottom, #ff3368 0%, #ff3368 20%, #ff3368 100%);
    top: 28px;
    box-shadow: none
}

@media (max-width: 991px) {
    .product_bar_item {
        width: 100% !important
    }
}

@media (max-width: 991px) {
    .product_top_bar {
        margin-top: 70px
    }

    .product_top_bar h2 {
        font-size: 25px
    }

    .product_bar_single {
        margin-left: 0;
        margin-right: 5px
    }

    .product_bar_single .nice-select {
        padding-left: 15px;
        padding-right: 35px
    }
}

.s_product_img .carousel {
    position: relative
}

.s_product_img .carousel .carousel-indicators {
    margin: 0px;
    right: 20px;
    width: auto;
    left: auto;
    bottom: 30px
}

.s_product_img .carousel .carousel-indicators li {
    height: 60px;
    width: 60px;
    background: transparent;
    text-indent: 0;
    margin-right: 10px;
    position: relative
}

.s_product_img .carousel .carousel-indicators li:after {
    display: none
}

.s_product_img .carousel .carousel-indicators li:before {
    content: "";
    background: rgba(197, 50, 45, 0.8);
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    opacity: 0
}

.s_product_img .carousel .carousel-indicators li.active:before {
    opacity: 1
}

@media (max-width: 576px) {
    .s_product_text {
        margin-top: 30px
    }
}

.s_product_text h5 {
    font-weight: 700;
    font-size: 15px;
    text-transform: capitalize;
    margin-bottom: 40px
}

@media (max-width: 576px) {
    .s_product_text h5 {
        margin-bottom: 15px
    }
}

.s_product_text h5 span {
    margin: 0 10px
}

.s_product_text h3 {
    font-size: 24px;
    font-weight: 700;
    color: #415094;
    margin-bottom: 10px
}

.s_product_text h2 {
    font-size: 24px;
    font-weight: bold;
    color: #f9503f;
    margin-bottom: 15px
}

.s_product_text .list li {
    margin-bottom: 5px
}

.s_product_text .list li a {
    font-size: 14px;
    font-family: "Playfair Display", serif;
    font-weight: normal;
    color: #555555
}

.s_product_text .list li a span {
    width: 90px;
    display: inline-block
}

.s_product_text .list li a span:hover {
    color: #555
}

.s_product_text .list li a.active {
    color: #f9503f
}

.s_product_text .list li a.active span {
    color: #555
}

.s_product_text .list li:last-child {
    margin-bottom: 0px
}

.s_product_text p {
    padding-top: 20px;
    border-top: 1px dotted #d5d5d5;
    margin: 20px 0
}

.s_product_text .card_area .main_btn {
    line-height: 38px;
    padding: 0px 38px;
    text-transform: uppercase;
    margin-right: 10px
}

.s_product_text .card_area .icon_btn {
    margin-right: 10px;
    background: #f9f9ff;
    border-radius: 3px;
    color: #415094;
    display: inline-block;
    line-height: 40px;
    text-align: center;
    padding: 0px 13px;
    font-size: 14px
}

.s_product_text .card_area .icon_btn:hover {
    background: #fff;
    box-shadow: -14.142px 14.142px 20px 0px rgba(0, 0, 0, 0.1)
}

.cart_area .product_count {
    display: inline-block;
    position: relative;
    margin-bottom: 24px;
    overflow: hidden
}

.cart_area .product_count input {
    width: 76px;
    border: 1px solid #eeeeee;
    border-radius: 3px;
    padding-left: 10px
}

.cart_area .product_count .input-number-increment {
    position: absolute;
    right: 0;
    top: -7px;
    padding: 10px;
    border-left: 1px solid #ddd;
    display: inline-block
}

.cart_area .product_count .input-number-increment:after {
    position: absolute;
    content: "";
    left: 0;
    bottom: 7px;
    width: 100%;
    height: 1px;
    background-color: #ddd
}

.cart_area .product_count .input-number-decrement {
    position: absolute;
    right: 0;
    bottom: -9px;
    padding: 10px;
    border-left: 1px solid #ddd;
    display: inline-block
}

@media (max-width: 576px) {
    .cart_area .media {
        display: block
    }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
    .cart_area .media {
        display: block
    }
}

.product_description_area {
    padding-bottom: 120px;
    margin-top: 60px
}

@media (max-width: 991px) {
    .product_description_area {
        margin-top: 0px;
        padding-bottom: 70px
    }
}

.product_description_area .nav.nav-tabs {
    display: block;
    border: none;
    padding: 10px 0px
}

.product_description_area .nav.nav-tabs li {
    display: inline-block;
    margin-right: 7px
}

.product_description_area .nav.nav-tabs li:last-child {
    margin-right: 0px
}

.product_description_area .nav.nav-tabs li a {
    padding: 0px;
    border: none;
    line-height: 38px;
    background: #fff;
    border: 1px solid #eeeeee;
    border-radius: 0px;
    padding: 0px 30px;
    color: #415094;
    font-size: 13px;
    font-weight: normal;
    border-radius: 50px
}

@media (max-width: 991px) {
    .product_description_area .nav.nav-tabs li a {
        padding: 0px 20px;
        margin-bottom: 10px
    }
}

.product_description_area .nav.nav-tabs li a.active {
    background: #f9503f;
    color: #fff;
    border-color: #f9503f
}

.product_description_area .tab-content {
    padding-top: 30px
}

.product_description_area .tab-content .total_rate .box_total {
    background: #f7f7f7;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
    border: 1px solid #ddd
}

.product_description_area .tab-content .total_rate .box_total h4 {
    color: #f9503f;
    font-size: 48px;
    font-weight: bold
}

.product_description_area .tab-content .total_rate .box_total h5 {
    color: #415094;
    margin-bottom: 0px;
    font-size: 24px
}

.product_description_area .tab-content .total_rate .box_total h6 {
    color: #415094;
    margin-bottom: 0px;
    font-size: 14px;
    color: #828bb2;
    font-weight: normal
}

.product_description_area .tab-content .total_rate .rating_list {
    margin-bottom: 30px
}

.product_description_area .tab-content .total_rate .rating_list h3 {
    font-size: 18px;
    color: #415094;
    font-family: "Playfair Display", serif;
    font-weight: 700;
    margin-bottom: 10px
}

.product_description_area .tab-content .total_rate .rating_list .list li a {
    font-size: 14px;
    color: #828bb2
}

.product_description_area .tab-content .total_rate .rating_list .list li a i {
    color: #fbd600
}

.product_description_area .tab-content .total_rate .rating_list .list li:nth-child a i:last-child {
    color: #eeeeee
}

.product_description_area .tab-content .table {
    margin-bottom: 0px
}

.product_description_area .tab-content .table tbody tr td {
    padding-left: 65px;
    padding-right: 65px;
    padding-top: 14px;
    padding-bottom: 14px
}

.product_description_area .tab-content .table tbody tr td h5 {
    font-size: 14px;
    font-family: "Playfair Display", serif;
    font-weight: normal;
    color: #828bb2;
    margin-bottom: 0px;
    white-space: nowrap
}

.product_description_area .tab-content .table tbody tr:first-child td {
    border-top: 0px
}

.review_item {
    margin-bottom: 35px
}

.review_item:last-child {
    margin-bottom: 0px
}

.review_item p {
    color: #828bb2
}

.review_item .media {
    position: relative
}

.review_item .media .d-flex {
    padding-right: 15px
}

.review_item .media .media-body {
    vertical-align: middle;
    align-self: center
}

.review_item .media .media-body h4 {
    margin-bottom: 0px;
    font-size: 14px;
    color: #415094;
    font-family: "Playfair Display", serif;
    margin-bottom: 8px
}

.review_item .media .media-body i {
    color: #fbd600
}

.review_item .media .media-body h5 {
    font-size: 13px;
    font-weight: normal;
    color: #828bb2
}

.review_item .media .media-body .reply_btn {
    border: 1px solid #e0e0e0;
    padding: 0px 28px;
    display: inline-block;
    line-height: 32px;
    border-radius: 16px;
    font-size: 14px;
    font-family: "Playfair Display", serif;
    color: #415094;
    position: absolute;
    right: 0px;
    top: 14px
}

.review_item .media .media-body .reply_btn:hover {
    background: #f9503f;
    border-color: #f9503f;
    color: #fff
}

.review_item p {
    padding-top: 10px;
    margin-bottom: 0px
}

.review_item.reply {
    padding-left: 28px
}

.review_box h4 {
    font-size: 24px;
    color: #415094;
    margin-bottom: 20px;
    font-weight: 700
}

.review_box p {
    margin-bottom: 0px;
    display: inline-block;
    font-weight: 500
}

.review_box .list {
    display: inline-block;
    padding-left: 10px;
    padding-right: 10px
}

.review_box .list li {
    display: inline-block
}

.review_box .list li a {
    display: inline-block;
    color: #fbd600
}

.review_box .form-control {
    height: 50px;
    border-radius: 0
}

.review_box ::placeholder {
    font-size: 14px
}

.review_box .btn_3 {
    padding: 13px 39px
}

.review_box .contact_form {
    margin-top: 15px
}

.cart_inner .table {
    margin-bottom: 30px;
    border-bottom: 1px solid #ddd
}

.cart_inner .table thead tr th {
    border-top: 0px;
    font-size: 14px;
    font-weight: 500;
    font-family: "Playfair Display", serif;
    color: #828bb2;
    border-bottom: 0px !important
}

.cart_inner .table tbody tr td {
    padding-top: 30px;
    padding-bottom: 30px;
    vertical-align: middle;
    align-self: center
}

.cart_inner .table tbody tr td .media .d-flex {
    padding-right: 30px
}

.cart_inner .table tbody tr td .media .d-flex img {
    border: 1px solid #eeeeee;
    border-radius: 3px;
    max-width: 150px
}

.cart_inner .table tbody tr td .media .media-body {
    vertical-align: middle;
    align-self: center
}

.cart_inner .table tbody tr td .media .media-body p {
    margin-bottom: 0px
}

.cart_inner .table tbody tr td h5 {
    font-size: 14px;
    color: #415094;
    font-family: "Playfair Display", serif;
    margin-bottom: 0px
}

.cart_inner .table tbody tr td .product_count {
    margin-bottom: 0px
}

.cart_inner .table tbody tr td .product_count input {
    width: 100px;
    padding-left: 30px;
    height: 50px;
    outline: none;
    box-shadow: none
}

.cart_inner .table tbody tr td .product_count .increase {
    top: -2px
}

.cart_inner .table tbody tr td .product_count .increase:before {
    content: "";
    height: 40px;
    width: 1px;
    position: absolute;
    left: -3px;
    top: 0px;
    background: #eeeeee
}

.cart_inner .table tbody tr td .product_count .increase:after {
    content: "";
    height: 1px;
    width: 30px;
    position: absolute;
    left: -3px;
    top: 22px;
    background: #eeeeee
}

.cart_inner .table tbody tr td .product_count .reduced {
    bottom: -6px
}

.cart_inner .table tbody tr.bottom_button .gray_btn {
    line-height: 38px;
    background: #f9f9ff;
    border: 1px solid #eeeeee;
    border-radius: 3px;
    padding: 0px 40px;
    display: inline-block;
    color: #415094;
    text-transform: uppercase;
    font-weight: 500
}

.cart_inner .table tbody tr.bottom_button td:last-child {
    width: 60px
}

.cart_inner .table tbody tr.bottom_button td .cupon_text {
    margin-left: -446px
}

.cart_inner .table tbody tr.bottom_button td .cupon_text input {
    width: 200px;
    padding: 0px 15px;
    border-radius: 3px;
    border: 1px solid #eeeeee;
    height: 40px;
    font-size: 14px;
    color: #cccccc;
    font-family: "Playfair Display", serif;
    font-weight: normal;
    margin-right: -3px;
    outline: none;
    box-shadow: none
}

.cart_inner .table tbody tr.bottom_button td .cupon_text input.placeholder {
    font-size: 14px;
    color: #cccccc;
    font-family: "Playfair Display", serif;
    font-weight: normal
}

.cart_inner .table tbody tr.bottom_button td .cupon_text input:-moz-placeholder {
    font-size: 14px;
    color: #cccccc;
    font-family: "Playfair Display", serif;
    font-weight: normal
}

.cart_inner .table tbody tr.bottom_button td .cupon_text input::-moz-placeholder {
    font-size: 14px;
    color: #cccccc;
    font-family: "Playfair Display", serif;
    font-weight: normal
}

.cart_inner .table tbody tr.bottom_button td .cupon_text input::-webkit-input-placeholder {
    font-size: 14px;
    color: #cccccc;
    font-family: "Playfair Display", serif;
    font-weight: normal
}

.cart_inner .table tbody tr.bottom_button td .cupon_text .main_btn {
    height: 40px;
    line-height: 38px;
    text-transform: uppercase;
    padding: 0px 38px;
    margin-right: -3px
}

.cart_inner .table tbody tr.bottom_button td .cupon_text .gray_btn {
    padding: 0px 40px
}

.cart_inner .table tbody tr.shipping_area td:nth-child(3) {
    vertical-align: top
}

.cart_inner .table tbody tr.shipping_area .shipping_box {
    margin-left: -250px;
    text-align: right
}

.cart_inner .table tbody tr.shipping_area .shipping_box .list li {
    margin-bottom: 12px;
    color: #828bb2
}

.cart_inner .table tbody tr.shipping_area .shipping_box .list li input {
    margin-left: 10px
}

.cart_inner .table tbody tr.shipping_area .shipping_box .list li:last-child {
    margin-bottom: 0px
}

.cart_inner .table tbody tr.shipping_area .shipping_box .list li a {
    padding-right: 30px;
    font-size: 14px;
    color: #828bb2;
    position: relative
}

.cart_inner .table tbody tr.shipping_area .shipping_box .list li a:before {
    content: "";
    height: 16px;
    width: 16px;
    border: 1px solid #cdcdcd;
    display: inline-block;
    border-radius: 50%;
    position: absolute;
    right: 0px;
    top: 50%;
    transform: translateY(-50%)
}

.cart_inner .table tbody tr.shipping_area .shipping_box .list li a:after {
    content: "";
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background: #f9503f;
    display: inline-block;
    position: absolute;
    right: 3px;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0
}

.cart_inner .table tbody tr.shipping_area .shipping_box .list li.active a:after {
    opacity: 1
}

.cart_inner .table tbody tr.shipping_area .shipping_box h6 {
    font-size: 14px;
    font-weight: normal;
    color: #415094;
    font-family: "Playfair Display", serif;
    margin-top: 20px;
    margin-bottom: 20px
}

.cart_inner .table tbody tr.shipping_area .shipping_box h6 i {
    color: #828bb2;
    padding-left: 5px
}

.cart_inner .table tbody tr.shipping_area .shipping_box .shipping_select {
    display: block;
    width: 100%;
    background: #f7f7f7;
    border: 1px solid #f7f7f7;
    height: 40px;
    margin-bottom: 20px
}

.cart_inner .table tbody tr.shipping_area .shipping_box .shipping_select .list {
    width: 100%;
    border-radius: 0px
}

.cart_inner .table tbody tr.shipping_area .shipping_box .shipping_select .list li {
    font-size: 14px;
    font-weight: normal;
    color: #828bb2
}

.cart_inner .table tbody tr.out_button_area .checkout_btn_inner {
    margin-left: -388px
}

.cart_inner .table tbody tr.out_button_area .checkout_btn_inner .main_btn {
    height: 40px;
    padding: 0px 30px;
    line-height: 38px;
    text-transform: uppercase
}

.gray_btn {
    line-height: 38px;
    background: #f9f9ff;
    border: 1px solid #eeeeee;
    border-radius: 3px;
    padding: 0px 40px;
    display: inline-block;
    color: #415094;
    text-transform: uppercase;
    font-weight: 500
}

.checkout_area p {
    font-size: 14px
}

.checkout_area .form-control {
    font-size: 14px;
    height: 40px
}

.checkout_area .form-control {
    border: 1px solid #eee
}

.checkout_area .form-control :focus {
    color: #495057;
    background-color: #fff;
    border: 1px solid #ced4da;
    outline: 0;
    box-shadow: none
}

.checkout_area .form-control .form-control input {
    height: 50px
}

.post_code {
    display: block;
    width: 100%;
    background: #f4f4f4;
    border: 1px solid #f4f4f4;
    height: 40px;
    margin-bottom: 20px;
    padding: 8px 15px
}

.check_title h2 {
    font-size: 14px;
    font-weight: normal;
    font-family: "Playfair Display", serif;
    background: #f7f7f7;
    line-height: 40px;
    padding-left: 30px;
    margin-bottom: 0px
}

@media (max-width: 991px) {
    .check_title h2 {
        line-height: 17px;
        padding: 12px
    }
}

.check_title h2 a {
    color: #f9503f;
    text-decoration: underline
}

.returning_customer p {
    margin-top: 15px;
    padding-left: 30px;
    margin-bottom: 25px
}

@media (max-width: 991px) {
    .returning_customer p {
        padding-left: 0
    }
}

.returning_customer .contact_form {
    max-width: 710px;
    margin-left: 15px
}

@media (max-width: 991px) {
    .returning_customer .contact_form {
        margin-left: -15px
    }
}

.returning_customer .contact_form .form-group {
    margin-bottom: 20px
}

.returning_customer .contact_form .form-group input {
    border: 1px solid #eeeeee;
    height: 40px;
    border-radius: 3px;
    font-size: 14px;
    font-family: "Playfair Display", serif;
    color: #828bb2;
    font-weight: normal
}

.returning_customer .contact_form .form-group input.placeholder {
    font-size: 14px;
    font-family: "Playfair Display", serif;
    color: #828bb2;
    font-weight: normal
}

.returning_customer .contact_form .form-group input:-moz-placeholder {
    font-size: 14px;
    font-family: "Playfair Display", serif;
    color: #828bb2;
    font-weight: normal
}

.returning_customer .contact_form .form-group input::-moz-placeholder {
    font-size: 14px;
    font-family: "Playfair Display", serif;
    color: #828bb2;
    font-weight: normal
}

.returning_customer .contact_form .form-group input::-webkit-input-placeholder {
    font-size: 14px;
    font-family: "Playfair Display", serif;
    color: #828bb2;
    font-weight: normal
}

.returning_customer .contact_form .form-group .submit_btn {
    margin-top: 0px
}

.returning_customer .contact_form .form-group .creat_account {
    display: inline-block;
    margin-left: 15px
}

.returning_customer .contact_form .form-group .creat_account input {
    height: auto;
    margin-right: 10px
}

.returning_customer .contact_form .form-group .lost_pass {
    display: block;
    margin-top: 20px;
    font-size: 14px;
    font-family: "Playfair Display", serif;
    color: #828bb2;
    font-weight: normal
}

.returning_customer .contact_form .form-group .lost_pass2 {
    display: block;
    margin-top: 20px;
    font-size: 14px;
    font-family: "Playfair Display", serif;
    color: #656565;
    font-weight: normal
}

.p_star {
    display: inline-block;
    position: relative
}

.p_star input {
    background: #fff
}

.p_star input:focus+.placeholder {
    display: none
}

.p_star .placeholder {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 30px;
    z-index: 1
}

.p_star .placeholder::before {
    content: attr(data-placeholder);
    color: #777
}

.p_star .placeholder::after {
    content: " *";
    color: tomato
}

.cupon_area {
    margin-bottom: 40px
}

.cupon_area input {
    margin-left: 30px;
    max-width: 730px;
    width: 100%;
    display: block;
    height: 40px;
    border-radius: 3px;
    padding: 0px 15px;
    border: 1px solid #eeeeee;
    outline: none;
    box-shadow: none;
    margin-top: 20px;
    margin-bottom: 20px
}

@media (max-width: 991px) {
    .cupon_area input {
        margin-left: 0
    }
}

.cupon_area .tp_btn {
    margin-left: 30px
}

@media (max-width: 991px) {
    .cupon_area .tp_btn {
        margin-left: 0
    }
}

.tp_btn {
    border: 1px solid #eeeeee;
    display: inline-block;
    line-height: 38px;
    padding: 0px 40px;
    color: #415094;
    text-transform: capitalize;
    font-family: "Playfair Display", serif;
    font-weight: 500;
    border-radius: 50px
}

.tp_btn:hover {
    background: #f9503f;
    color: #fff;
    border-color: #f9503f
}

.billing_details h3 {
    font-size: 18px;
    color: #415094;
    padding-bottom: 10px;
    margin-bottom: 30px
}

.billing_details .contact_form .form-group {
    margin-bottom: 20px
}

.billing_details .contact_form .form-group input {
    border-radius: 3px;
    color: #828bb2
}

.billing_details .contact_form .form-group input.placeholder {
    color: #828bb2
}

.billing_details .contact_form .form-group input:-moz-placeholder {
    color: #828bb2
}

.billing_details .contact_form .form-group input::-moz-placeholder {
    color: #828bb2
}

.billing_details .contact_form .form-group input::-webkit-input-placeholder {
    color: #828bb2
}

.billing_details .contact_form .form-group textarea {
    height: 150px;
    border-radius: 3px;
    margin-top: 15px
}

.billing_details .contact_form .form-group .country_select {
    width: 100%
}

.billing_details .contact_form .form-group .country_select span {
    color: #828bb2
}

.billing_details .contact_form .form-group .country_select .list {
    width: 100%;
    border-radius: 0px
}

.billing_details .contact_form .form-group .country_select .list li {
    font-size: 14px;
    font-family: "Playfair Display", serif;
    font-weight: normal
}

.billing_details .contact_form .form-group .creat_account #f-option2 {
    margin-right: 5px
}

.billing_details .contact_form .form-group .creat_account #f-option3 {
    margin-right: 5px
}

.billing_details .contact_form .form-group .creat_account a {
    color: #f9503f
}

.order_box {
    background: #f7f7f7;
    padding: 30px
}

.order_box h2 {
    border-bottom: 1px solid #dddddd;
    font-size: 18px;
    font-weight: 500;
    color: #415094;
    padding-bottom: 15px
}

.order_box .list li a {
    font-size: 14px;
    color: #828bb2;
    font-weight: normal;
    border-bottom: 1px solid #eeeeee;
    display: block;
    line-height: 42px
}

.order_box .list li a span {
    float: right
}

.order_box .list li a .middle {
    float: none;
    width: 50px;
    text-align: right;
    display: inline-block;
    margin-left: 30px;
    color: #415094
}

.order_box .list li:nth-child(4) a .middle {
    margin-left: 48px
}

.order_box .list_2 li a {
    text-transform: uppercase;
    color: #415094;
    font-weight: 500
}

.order_box .list_2 li a span {
    color: #828bb2;
    text-transform: capitalize
}

.order_box .list_2 li:last-child a span {
    color: #415094
}

.order_box .payment_item h4 {
    font-size: 14px;
    text-transform: uppercase;
    color: #415094;
    font-weight: 500;
    padding-left: 22px;
    position: relative;
    margin-bottom: 15px;
    margin-top: 15px
}

.order_box .payment_item h4:before {
    content: "";
    width: 14px;
    height: 14px;
    border-radius: 50%;
    border: 1px solid #cdcdcd;
    background: #fff;
    display: block;
    position: absolute;
    left: 0px;
    top: 50%;
    transform: translateY(-50%)
}

.order_box .payment_item h4:after {
    content: "";
    height: 4px;
    width: 4px;
    background: #fff;
    border-radius: 50%;
    display: block;
    position: absolute;
    left: 5px;
    top: 8px
}

.order_box .payment_item h4 img {
    padding-left: 60px
}

.order_box .payment_item p {
    background: #fff;
    padding: 20px
}

.order_box .payment_item.active h4:before {
    background: #f9503f;
    border-color: #f9503f
}

.order_box .creat_account {
    margin: 15px 0
}

.order_box .creat_account label {
    padding-left: 10px;
    font-size: 12px;
    color: #415094
}

.order_box .creat_account a {
    color: #f9503f
}

.order_box .btn_3 {
    display: block;
    line-height: 38px;
    text-transform: uppercase;
    text-align: center
}

@media (max-width: 991px) {
    .order_box .btn_3 {
        padding: 9px 34px
    }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .order_box .btn_3 {
        padding: 9px 34px
    }
}

.tracking_box_area .tracking_box_inner p {
    max-width: 870px;
    color: #415094;
    margin-bottom: 25px
}

.tracking_box_area .tracking_box_inner .tracking_form {
    max-width: 645px
}

.tracking_box_area .tracking_box_inner .tracking_form .form-group {
    margin-bottom: 30px
}

.tracking_box_area .tracking_box_inner .tracking_form .form-group input {
    height: 40px;
    border: 1px solid #eee;
    padding: 0px 15px;
    outline: none;
    box-shadow: none;
    border-radius: 0px;
    font-size: 14px;
    color: #828bb2;
    font-family: "Playfair Display", serif;
    font-weight: normal
}

.tracking_box_area .tracking_box_inner .tracking_form .form-group input.placeholder {
    font-size: 14px;
    color: #828bb2;
    font-family: "Playfair Display", serif;
    font-weight: normal
}

.tracking_box_area .tracking_box_inner .tracking_form .form-group input:-moz-placeholder {
    font-size: 14px;
    color: #828bb2;
    font-family: "Playfair Display", serif;
    font-weight: normal
}

.tracking_box_area .tracking_box_inner .tracking_form .form-group input::-moz-placeholder {
    font-size: 14px;
    color: #828bb2;
    font-family: "Playfair Display", serif;
    font-weight: normal
}

.tracking_box_area .tracking_box_inner .tracking_form .form-group input::-webkit-input-placeholder {
    font-size: 14px;
    color: #828bb2;
    font-family: "Playfair Display", serif;
    font-weight: normal
}

.tracking_box_area .tracking_box_inner .tracking_form .form-group:last-child {
    margin-bottom: 0px
}

.tracking_box_area .tracking_box_inner .tracking_form .form-group .btn_3 {
    box-shadow: -1.717px 8.835px 29.76px 2.24px rgba(255, 51, 104, 0.18)
}

.radion_btn input[type="radio"] {
    position: absolute;
    visibility: hidden
}

.radion_btn {
    position: relative;
    margin-top: 10px;
    margin-bottom: 15px
}

.radion_btn img {
    position: absolute;
    right: 40px;
    top: 0px
}

.radion_btn label {
    display: block;
    position: relative;
    font-weight: 300;
    font-size: 1.35em;
    padding: 0px 25px 21px 25px;
    height: 14px;
    z-index: 9;
    cursor: pointer;
    -webkit-transition: all 0.25s linear;
    font-family: "Playfair Display", serif;
    font-weight: 500;
    color: #415094;
    font-size: 13px;
    letter-spacing: 0.25px;
    text-transform: uppercase
}

.radion_btn .check {
    display: block;
    position: absolute;
    border: 1px solid #cdcdcd;
    border-radius: 100%;
    height: 14px;
    width: 14px;
    top: 5px;
    left: 0px;
    background: #fff;
    z-index: 5;
    transition: border 0.25s linear;
    -webkit-transition: border 0.25s linear
}

.radion_btn .check::before {
    display: block;
    position: absolute;
    content: "";
    border-radius: 100%;
    height: 4px;
    width: 4px;
    top: 4px;
    left: 4px;
    margin: auto;
    transition: background 0.25s linear;
    -webkit-transition: background 0.25s linear
}

.radion_btn input[type="radio"]:checked~.check {
    border: 1px solid #f9503f;
    background: #f9503f
}

.radion_btn input[type="radio"]:checked~.check::before {
    background: #fff
}

.radion_btn input[type="radio"]:checked~label {
    color: #000
}

.login_part_text {
    display: table;
    margin: 10px 0;
}

.login_part .login_part .login_part_form {
    padding: 20px 20px;
    display: table;
}

@media (max-width: 991px) {

    .login_part .login_part_text,
    .login_part .login_part_form {
        height: 400px
    }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {

    .login_part .login_part_text,
    .login_part .login_part_form {
        padding: 20px;
        height: 400px
    }
}

.login_part .login_part_text .login_part_text_iner,
.login_part .login_part_text .login_part_form_iner,
.login_part .login_part_form .login_part_text_iner,
.login_part .login_part_form .login_part_form_iner {
    display: table-cell;
    vertical-align: middle
}

.login_part .login_part_text {
    background: #ec4737 !important;
}

.login_part .login_part_text h2 {
    font-size: 24px;
    font-weight: 700;
    line-height: 35px;
    color: #fff;
    margin-bottom: 17px
}

.login_part .login_part_text h4 {
    font-size: 18px;
    font-weight: 700;
    line-height: 25px;
    color: #fff;
    margin-bottom: 17px
}

.login_part .login_part_text p {
    color: #fff;
    line-height: 25px
}

.login_part .login_part_text .btn_3 {
    border: 1px solid #fff;
    background-color: transparent;
    font-size: 14px;
    margin-top: 28px;
    color: #fff !important
}

.login_part .login_part_text .btn_3:hover {
    background-color: #fff;
    color: #f76c5e !important
}

@media (max-width: 991px) {
    .login_part .login_part_form {
        padding: 0
    }
}

.login_part .login_part_form h3 {
    font-size: 26px;
    line-height: 36px;
    font-weight: 700;
    margin-bottom: 80px
}

@media (max-width: 991px) {
    .login_part .login_part_form h3 {
        margin-bottom: 20px
    }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .login_part .login_part_form h3 {
        margin-bottom: 20px
    }
}

.login_part .login_part_form .form-control {
    border: 0px solid transparent;
    border-bottom: 1px solid #ddd;
    border-radius: 0
}

.login_part .login_part_form .form-control ::placeholder {
    color: #c3c3c3
}

.login_part .creat_account {
    margin-top: 11px
}

.login_part .creat_account label {
    color: #c2c2c2;
    font-size: 14px;
    margin-bottom: 0;
    margin-left: 10px
}

.login_part .form-group .btn_3 {
    display: block;
    width: 100%;
    margin: 30px 0 17px
}

.login_part .lost_pass {
    color: #eeeeee;
    text-align: right;
    float: right;
    text-transform: capitalize
}

.login_part .lost_pass2 {
    color: #656565;
    text-align: right;
    float: right;
    text-transform: capitalize
}

.lSSlideOuter .lSPager.lSGallery img {
    display: block;
    height: auto;
    max-width: 100%;
    padding: 5px
}

.s_product_text .card_area {
    padding: 20px 0
}

.s_product_text .card_area .product_count input {
    border: 0px solid transparent;
    text-align: center
}

.s_product_text .product_count {
    border: 1px solid #ddd;
    border-radius: 50px;
    display: flex;
    align-items: center;
    padding: 12px 15px
}

.s_product_text .product_count .input-number {
    display: inline-block;
    width: 60px
}

@media (max-width: 576px) {
    .s_product_text .product_count .input-number {
        width: 30px
    }
}

.s_product_text .Wishlist {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 1920px;
    height: 3162px;
    z-index: 365
}

.s_product_text .add_to_cart {
    margin: 25px 0
}

.s_product_text .like_us {
    border-radius: 50%;
    background-color: #fff;
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    display: inline-block;
    margin-left: 20px;
    box-shadow: -14.142px 14.142px 20px 0px rgba(0, 0, 0, 0.1)
}

.s_product_text .like_us:hover {
    background-color: #f9503f;
    color: #fff
}

.s_product_text .btn_3 {
    padding: 13px 36px
}

.s_product_text .btn_3:hover {
    color: #f9503f;
    background-color: #fff;
    box-shadow: -14.142px 14.142px 20px 0px rgba(0, 0, 0, 0.1);
    border: 1px solid #fff
}

@media (max-width: 576px) {
    .s_product_text .btn_3 {
        padding: 13px 20px
    }
}

.lslide {
    background-color: #fff;
    margin: 0 auto;
    text-align: center
}

.lSPager .lSGallery {
    margin-top: 20px
}

.card_area .social_icon {
    margin-top: 40px
}

.card_area .social_icon a {
    display: inline-block;
    border-radius: 50%;
    background-color: #3b5998;
    width: 45px;
    height: 45px;
    line-height: 45px;
    text-align: center;
    color: #fff;
    margin-right: 15px
}

.card_area .social_icon a:hover {
    background-color: #f9503f
}

.card_area .social_icon .tw {
    background-color: #55acee
}

.card_area .social_icon .li {
    background-color: #0077b5
}

.cart_area .btn_1 {
    border: 1px solid transparent
}

.cart_area .btn_1:hover {
    border: 1px solid #f9503f !important
}

.arrival_tittle,
.arrival_filter_item {
    margin-bottom: 80px
}

@media (max-width: 991px) {

    .arrival_tittle,
    .arrival_filter_item {
        margin-bottom: 30px
    }
}

.arrival_tittle h2,
.arrival_filter_item h2 {
    font-size: 50px;
    font-weight: 700;
    text-transform: capitalize
}

@media (max-width: 991px) {

    .arrival_tittle h2,
    .arrival_filter_item h2 {
        font-size: 30px
    }
}

.arrival_tittle ul li,
.arrival_filter_item ul li {
    font-size: 16px;
    color: #000;
    text-transform: capitalize;
    font-family: "Poppins", sans-serif;
    margin-left: 35px;
    display: inline-block;
    cursor: pointer
}

@media (max-width: 991px) {

    .arrival_tittle ul li,
    .arrival_filter_item ul li {
        margin-left: 0;
        margin-right: 30px
    }
}

.new_arrival .col-lg-12 {
    padding-right: 0 !important;
    padding-left: 0 !important;
    overflow: hidden
}

@media (max-width: 991px) {
    .new_arrival .col-lg-12 {
        padding-right: 15px !important;
        padding-left: 15px !important
    }
}

.new_arrival .new_arrival_iner {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between
}

.new_arrival .new_arrival_iner .weidth_1 {
    flex: 39% 0 0;
    max-width: 39%
}

@media (max-width: 576px) {
    .new_arrival .new_arrival_iner .weidth_1 {
        flex: 100% 0 0;
        max-width: 100%
    }
}

.new_arrival .new_arrival_iner .weidth_2 {
    flex: 28% 0 0;
    max-width: 28%
}

@media (max-width: 576px) {
    .new_arrival .new_arrival_iner .weidth_2 {
        flex: 100% 0 0;
        max-width: 100%
    }
}

.new_arrival .new_arrival_iner .weidth_3 {
    flex: 29.25% 0 0;
    max-width: 29.25%
}

@media (max-width: 576px) {
    .new_arrival .new_arrival_iner .weidth_3 {
        flex: 100% 0 0;
        max-width: 100%
    }
}

.new_arrival .single_arrivel_item {
    margin-bottom: 30px;
    text-align: center;
    position: relative;
    z-index: 1;
    overflow: hidden
}

@media (max-width: 991px) {
    .new_arrival .single_arrivel_item {
        margin-bottom: 15px
    }
}

.new_arrival .single_arrivel_item:after {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #f9503f;
    opacity: 0;
    -webkit-transition: .5s;
    transition: .5s;
    visibility: hidden
}

.new_arrival .single_arrivel_item img {
    width: 100%;
    max-height: 550px;
    -webkit-transition: .5s;
    transition: .5s
}

.new_arrival .single_arrivel_item .hover_text {
    position: absolute;
    top: 28%;
    z-index: 2;
    left: 0;
    right: 0;
    margin: 0 auto;
    color: #fff;
    -webkit-transition: .5s;
    transition: .5s;
    opacity: 0;
    visibility: hidden
}

.new_arrival .single_arrivel_item .hover_text p {
    font-size: 16px;
    color: #fff;
    letter-spacing: 2
}

.new_arrival .single_arrivel_item .hover_text h3 {
    font-size: 30px;
    color: #fff;
    margin: 2px 0 14px
}

@media (max-width: 991px) {
    .new_arrival .single_arrivel_item .hover_text h3 {
        font-size: 20px;
        margin: 2px 0 8px
    }
}

.new_arrival .single_arrivel_item .hover_text .rate_icon i {
    color: #ffe400 !important
}

.new_arrival .single_arrivel_item .hover_text h5 {
    font-size: 24px;
    color: #fff;
    margin: 18px 0 26px
}

@media (max-width: 991px) {
    .new_arrival .single_arrivel_item .hover_text h5 {
        font-size: 20px;
        margin: 2px 0 8px
    }
}

.new_arrival .single_arrivel_item .hover_text .social_icon a {
    display: inline-block;
    width: 50px;
    height: 50px;
    line-height: 50px;
    color: #000;
    background-color: #fff;
    border-radius: 50%;
    margin: 0 7px
}

.new_arrival .single_arrivel_item .hover_text .social_icon a:hover {
    background-color: #f9503f;
    color: #fff
}

.new_arrival .single_arrivel_item:hover:after {
    opacity: .7;
    visibility: visible
}

.new_arrival .single_arrivel_item:hover .hover_text {
    top: 31%;
    opacity: 1;
}

.new_arrival .active {
    position: relative;
    z-index: 1;
    color: #f9503f
}

.new_arrival .active:after {
    position: absolute;
    content: "";
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background-color: #f9503f;
}

@media (max-width: 991px) {
    .shipping_details .single_shopping_details {
        margin-bottom: 30px
    }
}

.shipping_details .single_shopping_details i,
.shipping_details .single_shopping_details span {
    font-size: 45px
}

.shipping_details .single_shoppin´g_details img {
    max-width: 48px
}

.shipping_details .single_shopping_details h4 {
    font-size: 20px;
    font-weight: 700;
    margin: 34px 0 10px
}

@media (max-width: 991px) {
    .shipping_details .single_shopping_details h4 {
        margin: 15px 0 5px
    }
}

.instagram_photo {
    background-color: #fff
}

.instagram_photo .col-lg-12 {
    padding: 0
}

.instagram_photo .instagram_photo_iner {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between
}

@media (max-width: 576px) {
    .instagram_photo .instagram_photo_iner {
        justify-content: space-around
    }
}

.instagram_photo .instagram_photo_iner .single_instgram_photo {
    flex: 19.5% 0 0;
    position: relative;
    z-index: 1
}

@media (max-width: 576px) {
    .instagram_photo .instagram_photo_iner .single_instgram_photo {
        flex: 33% 0 0;
        margin-bottom: 15px
    }
}

.instagram_photo .instagram_photo_iner .single_instgram_photo:after {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: #f9503f;
    opacity: 0;
    visibility: hidden;
    z-index: 1;
    -webkit-transition: .5s;
    transition: .5s
}

.instagram_photo .instagram_photo_iner .single_instgram_photo i {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
    top: 45%;
    color: #fff;
    font-size: 30px;
    z-index: 2;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: .5s;
    transition: .5s
}

.instagram_photo .instagram_photo_iner .single_instgram_photo:hover:after {
    visibility: visible;
    opacity: .7
}

.instagram_photo .instagram_photo_iner .single_instgram_photo:hover i {
    top: 48%;
    opacity: 1;
    visibility: visible
}

@media (max-width: 991px) {
    .instagram_photo .instagram_photo_iner .single_instgram_photo:hover i {
        top: 40%
    }
}

.confirmation_part .confirmation_tittle {
    text-align: center;
    margin-bottom: 35px
}

.confirmation_part .confirmation_tittle span {
    color: #f76c5e;
}

.confirmation_part .single_confirmation_details {
    background-color: #f7f7f7;
    padding: 35px 40px
}

@media (max-width: 991px) {
    .confirmation_part .single_confirmation_details {
        margin-bottom: 15px
    }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .confirmation_part .single_confirmation_details {
        margin-bottom: 30px
    }
}

.confirmation_part .single_confirmation_details h4 {
    font-size: 18px;
    font-weight: 500;
    color: "Roboto", sans-serif;
    text-transform: capitalize;
    margin-bottom: 16px
}

.confirmation_part .single_confirmation_details ul li {
    position: relative;
    text-transform: capitalize;
    padding-left: 55%;
    margin-bottom: 3px
}

.confirmation_part .single_confirmation_details ul li p {
    position: absolute;
    left: 0;
    top: 0;
    line-height: 21px;
    color: #777
}

.confirmation_part .single_confirmation_details ul li span {
    color: "Roboto", sans-serif
}

.confirmation_part .order_details_iner {
    margin-top: 50px;
    background-color: #f7f7f7;
    padding: 30px 30px 15px;
    text-transform: capitalize
}

.confirmation_part .order_details_iner h3 {
    font-size: 18px
}

.confirmation_part .order_details_iner .table thead th {
    color: "Roboto", sans-serif;
    font-size: 16px
}

.confirmation_part .order_details_iner span {
    color: #777
}

.confirmation_part .order_details_iner thead {
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd
}

.confirmation_part .order_details_iner tfoot {
    border-top: 1px solid #ddd
}

.confirmation_part .order_details_iner th {
    font-weight: 500
}

@media (max-width: 576px) {
    .blog_part {
        padding-bottom: 50px
    }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
    .blog_part {
        padding-bottom: 50px
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .blog_part {
        padding-bottom: 50px
    }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .blog_part {
        padding-bottom: 50px
    }
}

.blog_part .card {
    border: 0px solid transparent
}

.blog_part .blog_right_sidebar .widget_title {
    font-size: 20px;
    margin-bottom: 40px;
    font-style: inherit !important
}

@media (max-width: 576px) {
    .blog_part .single-home-blog {
        margin-bottom: 20px
    }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
    .blog_part .single-home-blog {
        margin-bottom: 20px
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .blog_part .single-home-blog {
        margin-bottom: 20px
    }
}

.blog_part .single-home-blog .card-img-top {
    border-radius: 0px
}

.blog_part .single-home-blog .card {
    border-radius: 0px;
    background-color: transparent;
    position: relative
}

.blog_part .single-home-blog .card .card-body {
    padding: 35px 30px 23px;
    background-color: #fff;
    -webkit-transition: .5s;
    transition: .5s;
    border: 1px solid #fdcb9e
}

.blog_part .single-home-blog .card .card-body:hover {
    box-shadow: 0px 10px 30px 0px rgba(12, 46, 96, 0.1);
    border: 1px solid transparent
}

@media (max-width: 576px) {
    .blog_part .single-home-blog .card .card-body {
        padding: 15px 10px
    }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
    .blog_part .single-home-blog .card .card-body {
        padding: 15px
    }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .blog_part .single-home-blog .card .card-body {
        padding: 20px
    }
}

.blog_part .single-home-blog .card .card-body .btn_4 {
    margin-bottom: 20px
}

.blog_part .single-home-blog .card .card-body a {
    color: #fff;
    text-transform: capitalize;
    -webkit-transition: .8s;
    transition: .8s
}

.blog_part .single-home-blog .card .dot {
    position: relative;
    padding-left: 20px
}

.blog_part .single-home-blog .card .dot:after {
    position: absolute;
    content: "";
    width: 10px;
    height: 10px;
    top: 5px;
    left: 0;
    background-color: #f76c5e;
    border-radius: 50%
}

.blog_part .single-home-blog .card span {
    color: #8a8a8a;
    margin-bottom: 10px;
    display: inline-block;
    margin-top: 10px
}

@media (max-width: 576px) {
    .blog_part .single-home-blog .card span {
        margin-bottom: 5px;
        margin-top: 5px
    }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
    .blog_part .single-home-blog .card span {
        margin-bottom: 5px;
        margin-top: 5px
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .blog_part .single-home-blog .card span {
        margin-bottom: 5px;
        margin-top: 5px
    }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .blog_part .single-home-blog .card span {
        margin-bottom: 5px;
        margin-top: 5px
    }
}

.blog_part .single-home-blog .card h5 {
    font-weight: 600;
    line-height: 1.5;
    font-size: 19px;
    -webkit-transition: .8s;
    transition: .8s;
    text-transform: capitalize
}

@media (max-width: 576px) {
    .blog_part .single-home-blog .card h5 {
        margin-bottom: 5px;
        font-size: 17px
    }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
    .blog_part .single-home-blog .card h5 {
        margin-bottom: 10px;
        font-size: 16px
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .blog_part .single-home-blog .card h5 {
        margin-bottom: 10px
    }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .blog_part .single-home-blog .card h5 {
        margin-bottom: 10px;
        font-size: 18px
    }
}

.blog_part .single-home-blog .card h5:hover {
    -webkit-transition: .8s;
    transition: .8s;
    color: #f76c5e
}

.blog_part .single-home-blog .card ul {
    border-top: 1px solid #fdcb9e;
    padding-top: 20px;
    margin-top: 24px
}

.blog_part .single-home-blog .card ul li {
    display: inline-block;
    color: #8a8a8a;
    margin-right: 39px
}

@media (max-width: 576px) {
    .blog_part .single-home-blog .card ul li {
        margin-right: 10px
    }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
    .blog_part .single-home-blog .card ul li {
        margin-right: 10px
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .blog_part .single-home-blog .card ul li {
        margin-right: 10px
    }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .blog_part .single-home-blog .card ul li {
        margin-right: 10px
    }
}

.blog_part .single-home-blog .card ul li span {
    margin-right: 10px
}

.about-details .about-details-cap h4 {
    font-size: 30px;
    font-family: "Josefin Sans", sans-serif;
    font-weight: 700;
    margin-bottom: 30px;
    color: #000a2d;
    display: inline-block;
    position: relative;
    padding-left: 68px
}

.about-details .about-details-cap h4::before {
    position: absolute;
    content: "";
    width: 54px;
    height: 2px;
    background: #eb566c;
    top: 0;
    left: 0px;
    top: 50%;
    transform: translateY(-50%)
}

.about-details .about-details-cap p {
    color: #464d65;
    font-size: 14px
}

.about_us .about_us_video {
    position: relative
}

.about_us .about_us_content h5 {
    font-size: 20px;
    color: #f76c5e;
    font-weight: 400
}

@media (max-width: 576px) {
    .about_us .about_us_content h5 {
        font-size: 18px
    }
}

.about_us .about_us_content h3 {
    font-size: 24px;
    line-height: 1.5;
    color: #191d34;
    margin: 5px 0 60px;
    font-weight: 400
}

@media (max-width: 991px) {
    .about_us .about_us_content h3 {
        margin: 5px 0 30px;
        font-size: 20px
    }
}

.about_us .about_video_icon {
    height: 84px;
    width: 84px;
    line-height: 84px;
    border-radius: 50%;
    background-color: #795376;
    display: inline-block;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 41%
}

@media (max-width: 576px) {
    .about_us .about_video_icon {
        height: 50px;
        width: 50px;
        line-height: 50px
    }
}

.about_us .about_video_icon:after {
    position: absolute;
    content: "";
    width: 14px;
    height: 18px;
    background-color: #fff;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 41%;
    clip-path: polygon(100% 50%, 0 0, 0 100%)
}

@media (max-width: 576px) {
    .about_us .about_video_icon:after {
        top: 32%
    }
}

.breadcrumb_part {
    height: 350px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #B08EAD
}

.breadcrumb_part .breadcrumb_iner {
    text-align: center
}

.breadcrumb_part .breadcrumb_iner h2 {
    font-size: 50px;
    line-height: 1.2;
    color: #fff;
    font-weight: 500;
    text-transform: capitalize
}

@media (max-width: 991px) {
    .breadcrumb_part .breadcrumb_iner h2 {
        font-size: 30px
    }
}

.topo {
    /*
     * Retirado por Edilson Bitencourt  
     * margin-top: 70px!important;
     */
}

.destaque {
    background: #f76c5e !important;
    color: #fff !important;
    padding: 10px;
}

.img_redonda {
    border-radius: 50%;
    width: 80%;
    padding-bottom: 20px !important;
}

.esquerda {
    text-align: left !important;
}

.cor_l a {
    color: #ebe9e9 !important;
}

.cor_l a:hover {
    color: #fff !important;
}

#div-finalizar-negociacao-chat {
    margin-top: 30px;
    float: right;
    width: 100%;
    text-align: right;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

#div-finalizar-negociacao-chat p {
    margin: 0 5px;
}

#div-finalizar-negociacao-chat .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
    background-color: #448709 !important;
}

.page-loja-header .slider {
    display: flex;
    height: 15rem;
    background-repeat: no-repeat;
    background-position: center center;
    margin-top: 50px;
}

.page-loja-header .brand {
    width: 300px;
    border: 1px solid #DDD;
    text-align: center;
    position: absolute;
    margin: -30px 20px;
    padding: 15px;
    border-radius: 5px;
    background-color: #FFF;
}

.page-loja-header .brand .logo {
    height: 75px;
}

.page-loja-body {
    margin-top: 20px;
}

.page-loja-body .p-orderlist-list {
    border: 0 !important;
}

.p-orderlist .p-orderlist-list .p-orderlist-item:focus {
    box-shadow: none !important;
}

.page-loja-body .product-item {
    display: flex;
    align-items: center;
    padding: 0.5rem;
    width: 100%;
}

.page-loja-body .product-item .product-list-detail {
    flex: 1 1 0;
}

.page-loja-body .product-item .product-list-detail h5 {
    margin: 0;
    font-family: inherit;
    font-weight: 600;
    line-height: 1.2;
    color: inherit;
}

.page-loja-body .product-item .product-category-icon {
    vertical-align: middle;
    margin-right: 0.5rem;
}

.page-loja-body .product-item .product-category {
    vertical-align: middle;
    line-height: 1;
}

.page-loja-body .product-image {
    width: 75px;
    box-shadow: 0 3px 6px #00000029, 0 3px 6px #0000003b;
    margin-right: 1rem;
}

.page-loja-body .product-list-detail h5 {
    margin: 1.5rem 0 1rem;
    font-family: inherit;
    font-weight: 600;
    line-height: 1.2;
    color: inherit;
}

.page-loja-body .p-orderlist-controls {
    display: none;
}

.page-loja-header {
    width: 100% !important;
    display: block;
}

.page-loja-header .page-loja-aside-produtos {
    float: left;
}

.page-loja-header .page-loja-aside-classificacao {
    float: right;
}

.classificacao-componente {
    display: flex;
    align-items: center;
}

.classificacao-componente .title {
    margin: 0 10px;
}

.p-orderlist-list-container {
    border-top: 1px solid #DDD;
}

.product-list-action .p-button {
    background-color: #f76c5e;
    border: 0;
}

.product-list-action .p-button:enabled:hover {
    background-color: #e63d3d;
}

.empresa-dados .nome {
    margin: 0;
}

.pagina-em-construcao {
    padding: 25px 0;
}

.descricao-compra {
    margin: 20px 0;
}

.descricao-compra h5 {
    margin: 0;
    padding: 0;
}

.subcategorias-opcoes-compra {
    margin: 10px 0;
    padding:10px;
}

.titulo-atributos-compra {
    border-bottom: 1px solid #EEE;
}

.link-edit-user {
    color: #000;
    padding: 5px;
}

.link-edit-user:hover {
    color: #333;
}

.caractere_max {
    text-align: right;
    width: 100%;
}


/* Editar Dados */

.editar-dados {
    display: flex;
    flex-direction: column;
    align-items: left;
}

.btn-editar-dados {
    width: 150px;
}

.input-editar-cadastro {
    width: 400px;
}

.editar-dados-lembrete {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.editar-dados-lembrete .close,
.editar-dados-lembrete .form-check {
    display: none;
}

/* Layout Mobile */
@media (max-width: 420px) {

    #div-dashboard-menulateral {
        width: 100%;
    }

    #div-dashboard-conteudo {
        width: 100%;
    }

    #dashboard {

        flex-direction: column;
    }

    .list-group {
        flex-direction: row;
        gap: 30px;

    }

    .list-group-item {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .is-logado {
        display: flex !important;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 8px;
    }

    #menu-area {
        width: 120px;
    }

    .item-menu-area-text span {
        display: flex !important;
        flex-direction: column;
        align-items: center;
        justify-content: center;

    }

    .item-menu-area-text {
        width: 120px !important;
        border-bottom: 2px solid #EEE;
        border-radius: 3px;

    }

    .btn_centralizar {
        display: flex;
        justify-content: center;
        align-items: center;
    }

}

.borda-etapa-concluida {
    border: 1px solid #0c4a0c;
    position: absolute;
    width: 100%;
    left: 0;
    top: -3px;
}

.border-green {
    border: 2px solid #097b44 !important;
}

.p-badge-success {
    font-size: 20px !important;
    margin: 10px !important;
}

.p-badge-no-gutter {
    border-radius:20% !important;
}

app-separador-section {
    margin:auto 10px;
}

.invisible{
    border:1px solid transparent;
}

.p-selectbutton .p-ripple
{
    width:200px;
    height:75px;    
}

#estabelecimento-entrega .p-selectbutton .p-ripple
{
    width: auto !important;
    height: auto !important;
}

#estabelecimento-entrega .p-inputwrapper
{
    width:70% !important;
}
#estabelecimento-entrega .p-inputwrapper .p-inputnumber
{
    width:100% !important;
}
/*  */

#comprar-fieldset .p-selectbutton .p-ripple.p-highlight
{
    background-color: #5a6fa5 !important;
    border: 1px solid #5a6fa5 !important;
}

#comprar-fieldset .p-selectbutton{
    display: flex;
    justify-content: center;
}

.comprar-parceiros
{
    display: flex;
    margin:30px 0;
}
.comprar-parceiros .item {
    width:20%;
    border:1px solid #DDD;
    text-align:center;
    padding:10px;
}
.comprar-parceiros img
{
    height: 75px;
}
.p-steps .p-steps-item .p-menuitem-link:focus,
.p-steps .p-steps-item .p-menuitem-link:active
{
    border:none !important;
    outline: 0
}

.slick-home .item .notify-badge p-badge
{
    right: -100px !important;
    top: -20px !important;
    position: absolute;
}