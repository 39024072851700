.slick-home .item,.slick-home
{
    border:none;
}

.slick-home .item .item-content
{
    /* -webkit-box-shadow: 0px 0px 0px 0px #E4E7ED, 0px 0px 0px 1px #E4E7ED;
    box-shadow: 0px 0px 0px 0px #E4E7ED, 0px 0px 0px 1px #E4E7ED;
    -webkit-transition: 0.2s all;
    transition: 0.2s all; */

    border:2px solid #E4E7ED;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding:15px;
    text-align:center;
    border-radius: 5px;
    cursor:pointer;
    margin:2px;
}

.slick-home .item .item-content:hover{
    -webkit-box-shadow: 0px 0px 6px 0px #16b86a, 0px 0px 0px 2px #16b86a;
    box-shadow: 0px 0px 6px 0px #16b86a, 0px 0px 0px 2px #16b86a;
    background-color: #f9fffb;
    border:2px solid #097b44 !important;
}

.slick-home .item .item-img
{
    max-width:100%;
    object-fit: cover;
    height:6rem;
}

.slick-home .item .item-subtitle{
    /* color:#8D99AE;
    font-size:10px !important;
    padding: 5px;
    text-align: right; */
    position: absolute;
    /* top:10px;
    height: 25px;
    margin-left:5px; */
}
.item-subtitle .p-component{
    font-size:0.8rem !important;
}

.slick-home .item .item-title{
    color:#333;
    font-size:0.9rem;
    font-weight: bold;
    display: table-cell;
    vertical-align: middle;
    height: 75px;    
}
.slick-home .item .item-body .item-text{
    font-size:0.5rem;
    color:#999;
}

.slick-home .item .item-button
{
    border-radius:5px;
}

.slick-home .item  .notify-badge{
    position: absolute;
    /* top: 0;
    right:0; */
}