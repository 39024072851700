	/*
  	Flaticon icon font: Flaticon
  	Creation date: 19/04/2020 06:48
  	*/

@font-face {
  font-family: "Flaticon";
  src: url("/assets/fonts/Flaticon.eot");
  src: url("/assets/fonts/Flaticon.eot?#iefix") format("embedded-opentype"),
       url("/assets/fonts/Flaticon.woff2") format("woff2"),
       url("/assets/fonts/Flaticon.woff") format("woff"),
       url("/assets/fonts/Flaticon.ttf") format("truetype"),
       url("/assets/fonts/Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("src/assets/fonts/Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
  font-family: Flaticon;
font-style: normal;
}

.flaticon-search:before { content: "\f100"; }
.flaticon-shopping-cart:before { content: "\f101"; }
.flaticon-user:before { content: "\f102"; }
.flaticon-heart:before { content: "\f103"; }
.flaticon-arrow:before { content: "\f104"; }