.cor-principal
{
    color:#f76c5e;
}
/* **************************************************
    CATEGORIA
************************************************** */

.categoria-list {
    display: flex;
    flex-wrap: wrap;
    align-content: stretch;
}

.categoria-list-item {
    width: 20%;
    min-height: 100px;
    display: flex;
    justify-content: center;
    text-align: center;
    border: 1px solid #F9F9F9;
    cursor: pointer;
}

.categoria-list-item:hover {
    background-color: #F6F6F6 !important;
}

.itemsel {
    background-color: #5a6fa5 !important;
    color: #FFF;
}

.itemsel:hover {
    background-color: #5a6fa5 !important;
}

.categoria-check-icon {
    position: absolute;
    right: 5px;
    top: 5px;
    color: #FFF;
}


/* **************************************************
    ATRIBUTO
************************************************** */

#div-lista-atributos {
    display: flex;
    justify-content: space-between;
}

.atributo-item-lista {
    flex-grow: 1;
    padding: 5px;
}

#div-lista-atributos .dropdown-btn span {
    color: #999;
}

#div-lista-atributos .dropdown-btn .selected-item-container .selected-item span {
    color: #FFF !important;
}

#div-lista-atributos .dropdown-btn {
    border: 1px solid #DDD;
}


/* **************************************************
    ITEM
************************************************** */
.botoes-grupo input[type=button] {
    margin: 10px;
}

.item-list .card .card-body {
    background-color: #EEE;
}

.iupload {
    display: none;
}


/* **************************************************
    NEGOCIAÇÃO
************************************************** */

.negociacoes-component {}

.negociacao-open {
    color: green;
}

.negociacao-close {
    color: red;
}


/* **************************************************
    DASHBOARD
************************************************** */

.titulo-dashboard {
    text-transform: capitalize;
}

.td-validation-error {
    background-color: #ffe6e6 !important;
    border-bottom: 2px solid #FF0000 !important;
}

.td-validation-success {
    background-color: #f1ffee !important;
    border-bottom: 2px solid green !important;
}

.div-error {
    display: none;
}

.menu-dashboard a {
    color: #000;
}

.menu-dashboard a.active {
    background-color: transparent !important;
    color: #000 !important;
}

.menu-dashboard .nav-link {
    border-radius: 0px !important;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border: 1px solid #EEE;
    height: 70px;
    padding: 0px;
    margin-right: 15px;
    text-align: left;
}

.menu-dashboard .nav-link fa-icon {
    background-color: #ec4737;
    color: #FFF;
    padding: 10px 15px;
    font-size: 32px;
}

.menu-dashboard .nav-link {
    width: 100%;
    float: left;
    clear: both;
}

.menu-dashboard .nav-link:hover {
    background-color: #ffe8e6 !important;
    color: #FFF !important;
}

.textos {
    width: 100%;
    float: left;
    clear: left;
    margin: 0px;
    padding-left: 15px;
}

p.textos {
    font-weight: bold;
}

small.textos {
    color: #999;
}


/* **************************************************
    PRODUTO - SUBCATEGORIA 
************************************************** */

.form-subcategoria {
    margin-top: 50px;
    width: 100% !important;
}

.form-subcategoria label {
    font-size: 12px;
    width: 100%;
}

.form-subcategoria .nice-select {
    width: 100%;
    line-height: 28px;
}

.form-subcategoria .nice-select ul {
    width: 100%;
}

.btn-subcategoria-produto {
    margin-top: 50px;
    float: right;
}

.separador-lista-subcategoria {
    border-bottom: 1px solid #f76c5e;
}

.lista-produtos .card-header {
    cursor: pointer;
}

.botoes-acoes {
    margin-left: 25px;
    cursor: pointer;
}

.botoes-acoes.btn-card-header {
    float: right;
}

.btn-add-atributo {
    height: 38px;
    width: 40px;
    padding: 0px;
}

.coluna-btn-subcategoria {
    margin-top: 50px;
}

.lista-produtos .card {
    margin-bottom: 15px;
}

.btn-add-atributo-opcao {
    cursor: pointer;
}

.btn-add-opcao {
    cursor: pointer;
}

.aviso-opcoes {
    margin: auto 10px;
}


/* **************************************************
    MULTISELECT MASTER CHECKBOX
************************************************** */

.multiselect-item-checkbox input[type=checkbox]:checked+div:before {
    background-color: #ec4737 !important;
}

.multiselect-item-checkbox input[type=checkbox]+div:before {
    color: #ec4737 !important;
    border: 2px solid #ec4737 !important;
}

.multiselect-dropdown .dropdown-btn .selected-item {
    border: 1px solid #ec4737 !important;
    background: #ec4737 !important;
}


/* **************************************************
    RETORNO PEDIDO
************************************************** */

.img-check-retorno {
    width: 300px;
}


/* **************************************************
    MENU LATERAL DO DASHBOARD
************************************************** */

.menu-lateral {
    color: #000;
    border-radius: 0;
}

.menu-lateral li {
    border: 0px;
    text-align: center;
    padding: 15px;
    border-bottom: 3px solid #EEE;
    border-radius: 0;
    cursor: pointer;
}

.menu-lateral li a,
.menu-lateral-sidebar li a {
    color: #000;
}

.menu-lateral li fa-icon,
.menu-lateral-sidebar li fa-icon {
    font-size: 20px;
    color: #555;
}


.cordali{
    background-color: #f94e3fd0 !important;
    transition: 0.4s ;
    transition-timing-function: ease-out;
}

.cordafc{
    transition: 0.4s ;
    color: white !important;
}

.menu-lateral li:last-child {
    border-radius: 0;
}

.menu-lateral-sidebar,
.menu-lateral-sidebar li {
    background-color: transparent;
    color: #FFF;
    border: 0px;
}

.menu-lateral-sidebar li {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.menu-lateral-sidebar li fa-icon {
    width: 25px;
    float: left;
}

.menu-lateral-sidebar li:hover {
    background-color: rgba(0, 0, 0, 0.1);
}

.app-divisor {
    border: 1px solid #FDFDFD;
}

.logo-qeru-menu {
    width: 25px;
    height: 25px;
    margin: 0px;
    color: #F98174 !important;
}

.div-autenticacao {
    background-color: #ec4737;
}

#btn-show-menu .p-component {
    margin-top: 5px;
    background-color: #FFF;
    border: 0px;
    color: #666;
}


/* **************************************************
    NEGOCIAÇÃO HOME DASHBOARD
************************************************** */

.img-logo-negociacao-home {
    max-width: 100%;
}

.item-lista-imagens .item {
    border: 1px solid #EEE;
    padding: 5px;
    height: 100px;
}

.item-lista-imagens .item img {
    object-fit: cover;
    min-width: 100%;
    min-height: 100%;
    max-height: 100%;
}

.item-lista-imagens .item:hover {
    border: 1px solid #AAA;
}

.item-lista-imagens {
    border: 1px dotted #F0F0F0;
}

.excluir-imagem-lista-item {
    color: #FF0000;
    position: absolute;
    right: 5px;
    cursor: pointer;
}


/* **************************************************
    DASHBOARD - GERAL
************************************************** */

.div-titulo-pagina {
    margin: 30px 0px;
    height: 50px;
}

.div-titulo-pagina .titulo-pagina 
{
    color: #404040 !important;
    margin-top: -5px !important;
    text-align: left !important;
    font-size:38px;
}

.subtitulo-pagina {
    color: #404040;
    margin-top: -10px !important;
    margin-left: 5px !important;
    float: left;
    text-align: left !important;
    width: 100%;
    margin-bottom:5px !important;
}

.suptitulo-pagina {
    margin-bottom: -15px !important;
    color: #404040;
    text-align: left !important;    
}


/* **************************************************
    DASHBOARD - TÓPICO PÁGINA
************************************************** */

.topico-pagina mat-divider {
    margin: -15px auto 15px auto;
    border-top-color: rgba(0,0,0,.05);
}


/* **************************************************
    DASHBOARD - ESPECIFICAÇÃO
************************************************** */

.box-especificacao {
    background-color: #F0F0F0;
    border-bottom: 1px solid #8B8B8B;
    padding-left: 10px;
    padding-top: 5px;
    height: 60px;
    margin: 5px;
}

.box-especificacao p {
    color: #1F1F1F !important;
}

.dialog-mat-close {
    float: right;
    display: block;
    width: 100%;
    text-align: right;
    cursor: pointer;
}

.header-area {
    box-shadow: 0 0 3px 0 #BBB;
}

.fundo-vermelho {
    background-color: #ec4737;
}

.login_part_text_iner .user-name {
    font-weight: bold;
    color: #FFF;
    margin: 0px;
    padding: 0px;
}

.login_part_text_iner .user-email {
    color: #FFF;
    margin-top: -10px !important;
    float: left;
    text-align: center;
    width: 100%;
}

#menu-principal-dashboard {
    display: table;
    margin-top: 20px;
    width: 100%;
}

/* **************************************************
    PRE-NEGOCIAÇÃO
************************************************** */

section#prenegociacao .pedido-card {
    display: flex;
    margin-top:50px;
}

section#prenegociacao .pedido-card .left-side {
    width: 30%;
}

section#prenegociacao .pedido-card .middle-side {
    width: 20%;
}

section#prenegociacao .pedido-card .right-side {
    display: flex;
    justify-content: center;
    width: 50%;
}

section#prenegociacao .pedido-card .middle-side .produto-imagemprincipal {
    width: 100%;
    max-height: 250px;
    max-width: 100%;
    object-fit: fill;
}

section#prenegociacao .pedido-card .left-side .p-card {
    box-shadow: none;
}

section#prenegociacao app-prenegociacao-atributos {
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 250px;
}

.p-datatable-responsive-scroll>.p-datatable-wrapper
{
    overflow-x:inherit;
}
/* **************************************************
    FORMULÁRIO GERAL
************************************************** */

.login_part_form_iner h3 {
    margin-bottom: 5px;
}

.login_part_form_iner form legend {
    font-size: 18px;
    border-bottom: 1px solid #999;
    color: #333;
    text-align: left;
}


/* **************************************************
    SITE - PÚBLICO
************************************************** */

.titulo-pagina {
    text-align: right;
    font-weight: bold !important;
    font-size: 20px;
}

.div-conteudo .subtitulo-publico {
    text-align: right !important;
}

.public-page
{
    margin:30px;
}

/* **************************************************
    PROPOSTA - COMPONENTE
************************************************** */

.ichat-proposta {
    cursor: pointer;
    font-size: 24px;
}

.shadow-4 {
    box-shadow: 0 1px 10px #0000001f, 0 4px 5px #00000024, 0 2px 4px -1px #0003!important;
}

.proposta-lista-foto {
    width: 85px;
    height: 50px;
    object-fit: cover;
}
.negociacao-lista-foto-usuario
{
    width: 50px;
    height: 50px;
    object-fit: cover;
}
.proposta-lista-td-content {
    padding-left: 10px;
}

.proposta-lista-td-content h4 {
    margin: 0px;
    font-weight: bold;
}


/* **************************************************
    PRÉ CADASTRO - COMPONENTE
************************************************** */

.precadastro-card-select-perfil fa-icon {
    font-size: 48px;
    margin: 15px;
    color: #444 !important;
}

.perfil_selected {
    border: 2px solid #103a00;
    background-color: #ceffd2;
}

.perfil_no_selected {
    background-color: #eee;
}

.btn_disabled {
    background-color: #AAA;
}

#lista-senha-forte {
    display: none;
}

#lista-senha-forte .list-group-item {
    border: 2px solid #FFF !important;
}

#aceite-checkbox
{
    border: 1px solid #FFF !important;
    padding: 5px;
	margin-left:15px;
}
#aceite-checkbox.nao-aceite
{
    border: 1px solid #FF0000 !important;
    background-color: #ffe3e1;
    border-radius: 5px;
}

#icon-eye
{
    cursor:pointer;
}

#error-email{
    border: 1px solid #d82323 !important;
    background-color: #ffe3e1;
    border-radius: 5px;
    padding: 5px;
    margin: 5px auto;
    color: #FF0000;
    display: none;
}

#error-cadastro,
#confirma-cadastro
{
    display: none;
}
/* **************************************************
    UPLOAD IMAGEM - COMPONENTE
************************************************** */

.upload-image-componente {
    width: 100px;
    object-fit: contain;
}

.excluir-imagem-upload-image {
    position: absolute;
    color: #FF0000;
    top: 50px;
    right: 25px;
    z-index: 1000;
    cursor: pointer;
}


/* **************************************************
    LOGON - HEADER
************************************************** */

.opcoes-logon a {
    color: #000;
    margin: 10px;
}

.is-logado,
.isnot-logado {
    display: none;
}
.logon-opcoes
{
    padding: 0px;
    text-align: right;
    margin: -10px;
}
.logon-topo .form-group 
{
    padding-right:5px;
}

#logon-btn-entrar
{
    width: 50px;
    height: 30px;
}

#logon-btn-voltar
{
    width: 30px;
    height: 30px;
    margin-left: 5px;
}

#error_msg
{
    border:0px;
    background-color: transparent;
    display: flex;
    margin:0px;
    padding:0px;
	justify-content:flex-end;
}

#error_msg p 
{
    color:#bc1010;
}
/* **************************************************
    CHAT COMPONENTE
************************************************** */

#dialog-semsaldo-chat button[mat-button] {
    padding: 5px 10px;
    border: 0px;
    margin: 5px;
}

.mat-dialog-cancel {
    background-color: #FF0000 !important;
    color: #FFF;
}

.mat-dialog-confirm {
    background-color: #103a00 !important;
    color: #FFF;
}

.comparilhar-link-propaganda .mat-form-field {
    display: block;
}

.comparilhar-link-propaganda .mat-form-field button {
    background-color: transparent !important;
    border: none !important;
    cursor: pointer;
}

.comparilhar-link-propaganda .icone-copiar {
    color: #666;
}

.comparilhar-link-propaganda .mat-form-field+.mat-form-field {
    margin-left: 8px;
}

#div-brand-dashboard {
    align-items: center;
    align-content: center;
    display: flex;
    float:right;
}

#div-brand-dashboard #icone-upload-logo-dashboard {
    transform: translate(-15px, 0);
    cursor: pointer;
    opacity: .7;
    visibility: hidden;
    position: absolute;
    margin-left: 45px;
}

#div-brand-dashboard #icone-upload-logo-dashboard:hover {
    opacity: 1;
}

#div-brand-userinfo
{
    float: right;
    margin-right: 15px;
    margin-top: 10px;
    text-align: right;    
}
#div-brand-userinfo .user-name
{
    margin:0;
    padding:0;
}

#div-brand-userinfo .user-email
{
    margin:0;
    padding:0;
}

#user-img-dashboard {
    width: 75px;
    height:75px;
    margin: 0 auto;
    border-radius: 100% !important;
    object-fit: cover;
}

.conversacao-chat
{
    width:50px;
    height:50px;
    object-fit: cover;
}

/* **************************************************
    TOPO - CARD
************************************************** */

.card-topo {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 15px !important;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(100, 100, 100, 0.1);
}

#item-credito {
    flex-grow: 4;
}

.card-topo .card-topo-item {
    flex-grow: 1;
}

.card-topo .card-topo-item-content {
    float: right;
}

#item-classificacao .card-topo-item-content h5,
#item-classificacao .card-topo-item-content p-rating {
    float: right !important;
    clear: right !important;
}

#item-pontos .card-topo-item-content {
    float: left !important;
    display: flex;
    align-items: baseline;
}

#icone-card-topo-conquista {
    font-size: 38px;
}

#item-pontos p-knob
{
    margin-right:10px;
}

/* **************************************************
    COMPRAR
************************************************** */

#comprar-retorno {    
    width: 100%;
    margin:50px 0;
}

.parceiros-compra
{
    margin-top:50px;
}
#comprar-retorno p-message .p-component
{
    width:100%;
    padding:20px;
    display: none;
}

.etapa-concluida{
    background-color: #0c4a0c !important;
    color: rgb(255, 255, 255) !important;
}





/* **************************************************
    HEADER - DASHBOARD
************************************************** */

#icone-card-topo-conquista {
    color: #666;
}


/* **************************************************
    PROPAGANDA - VIEW
************************************************** */

.propaganda-close-view {
    position: absolute;
    margin-left: 700px;
    margin-top: -20px;
    z-index: 1000;
    background-color: transparent !important;
    border: none !important;
    cursor: pointer;
}

.propaganda-close-view .icone-close {
    color: #666;
}


/* **************************************************
    FINANCEIRO COMPONENTE
************************************************** */

.border-bottom-selected {
    background-color: #EAEAEA !important;
    border-bottom: 2px solid #f76c5e !important;
}


/* **************************************************
    EXTRATO - FINANCEIRO COMPONENTE
************************************************** */

#extrato table {
    width: 100%;
}

#extrato table tr.mat-footer-row {
    font-weight: bold;
}

#extrato table .mat-column-operacao {
    text-align: center;
    width: 10%;
}

#extrato table .mat-column-data {
    width: 15%;
    text-align: center;
}

#extrato table .mat-column-valor {
    width: 15%;
    text-align: right;
}

#extrato table .mat-column-transacao {
    text-align: left;
    width: 50%;
}

#extrato table .tr-operacao-credito td {
    color: #103a00 !important;
}

#extrato table .tr-operacao-debito td {
    color: #f76c5e !important;
}

/* **************************************************
    LOJA SLIDER - COMPONENTE
************************************************** */

.loja-item .loja-item-content {
    border: 1px solid var(--surface-d);
    border-radius: 3px;
    margin: .3rem;
    text-align: center;
    padding: 2rem 0;
}

.loja-item .product-image {
    width: 25%;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)
}


/* **************************************************
    ESTABELECIMENTO
************************************************** */

#estabelecimento-loja,
#estabelecimento-imagens,
#estabelecimento-entrega {
    margin: 15px 0px !important;
}

.estabelecimento-row-button {
    margin-bottom: 20px;
    margin-left: 0px;
}

.btn-qeru-primary
{
    background-color: #f9503f;
    color:#FFF;
    border:0;
    border-radius: 3px;
}


/* **************************************************
    FOOTER
************************************************** */
footer
{
    margin-top:20px;
}

/* **************************************************
    LOJAS OFICIAIS
************************************************** */
.p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button
{
    background-color: #000 !important;
}

.p-link:focus
{
    background-color: #DDD !important;
}


.header-area .main-header .menu-wrapper
{
    padding:10px;
}

/* **************************************************
    CADASTRO CONFIRMAÇÃO
************************************************** */
#msg-confirmacao-cadastro-sucesso
{
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
    height: 50px;
    line-height: 0px;
    padding: 10px;
    border-radius: 7px;
}

#logo-topo
{
    height: 50px;
}

header,
#dashboard
{
    display: flex;
}

#div-dashboard-menulateral
{
    width: 10%;
    min-width:10%;
}

#div-dashboard-conteudo
{
    width:100%;
    height: 50% !important;
}

#div-dashboard-conteudo .div-btn-compra {
    display:flex;
    justify-content: center;    
    gap: 40px;
    padding:0;
}
#div-dashboard-conteudo .div-btn-compra button {
    margin-left:10px;
}
#form-auth
{
	display:flex;
	justify-content:flex-end;
}

#header-logo
{
	flex-grow:1;
}
#header-logon
{
	flex-grow:2;
	text-align: right;
}

header
{
	padding: 1rem;
}

#div-dashboard-conteudo
{
	padding:15px;
}

#lembrete-tornese-vendedor p
{
	color:#333;
}

#lembrete-tornese-vendedor .btn
{
	background-color: #0c5460;
}

#msg-home-sem-negociacao
{
	margin:15px auto;
	text-align:center;
}
#msg-home-sem-negociacao p
{
	color:#000;
	padding:0;
	margin:0;
}
#msg-home-sem-negociacao a
{
	font-weight:bold;
	color:#000;
}

#titulo-pagina-cadastro
{
	font-size:3rem;
	font-weight:bold;
}

#btn-criar-propaganda
{
    margin:20px auto;
}

mat-tab .categoria-list {
    display: inline !important;
}

#timer
{
    padding:15px 50px;
    font-size:32px;
    float:right;
    font-weight:bold;
}

#timer .tempo
{
    margin-left:10px;
}

section.chat
{
    display: flow-root;
}

.interacoes-separador
{
    border:1px solid #F9F9F9;
    margin:30px auto;
}

.p-button-label {
    font-family: Roboto, "Helvetica Neue", sans-serif;
    font-weight: 200;
    font-size: 14px;
}

.p-menuitem-text {
    font-family: Roboto, "Helvetica Neue", sans-serif; 
    font-weight: 600;
    font-size: 15px;
}

.mat-tab-body-content
{
    overflow: hidden;
}

#busca-vendedor .p-autocomplete
{
    width: 100% !important;
}

#busca-vendedor .p-button
{
    background-color: #f9503f;
    border:1px solid #ca3123;
}

#busca-vendedor .p-button,
#busca-vendedor .p-inputtext
{
    height: 50px !important;
}

#busca-vendedor .p-inputtext:enabled:hover
{
    border-color: #ca3123;
}

#busca-vendedor ng-template
{
    z-index: 1000;
}

.foto-perfil-vendedor-lista
{
    width: 48px;
    height: 48px;
}

.p-splitter .p-component
{
    display:flex;
    flex-direction:column;    
}

.indicador-area{
    width:100%;
    padding:15px;
}

.p-rating:not(.p-disabled):not(.p-readonly) .p-rating-icon:hover,
.p-rating .p-rating-icon.pi-star-fill
{
    color:#c9bd1a !important;
}

.p-button-label {
    font-family: Roboto, "Helvetica Neue", sans-serif;
    font-weight: 200;
    font-size: 14px;
}

.p-menuitem-text {
    font-family: Roboto, "Helvetica Neue", sans-serif; 
    font-weight: 600;
    font-size: 15px;
}

.p-menu {
    margin-top: 20px;
    margin-right: 400px ;
    width: 200px;
}
.perfil-opt-selected,
.perfil-opt-selected::before
{
    color:#103a00 !important;
    font-weight: bold;
    visibility: visible !important;
}

.perfil-opt-no-selected::before
{
    visibility: hidden;
}

.p-selectbutton .p-highlight,
.p-inputnumber .p-button,
.p-calendar .p-button
{

    background-color: #F98174 !important;
    border:1px solid #F98174 !important;
    color:#FFF !important;
}

/* **************************************************
    Extrato-meuspontos - FINANCEIRO COMPONENTE
************************************************** */
#extrato-meuspontos table {
    width: 100%;
}

#extrato-meuspontos table tr.mat-footer-row {
    font-weight: bold;
}

#extrato-meuspontos table .mat-column-operacao {
    text-align: center;
    width: 10%;
}

#extrato-meuspontos table .mat-column-data {
    width: 15%;
    text-align: center;
}

#extrato-meuspontos table .mat-column-valor {
    width: 15%;
    text-align: right;
}

#extrato-meuspontos table .mat-column-transacao {
    text-align: left;
    width: 50%;
}

#extrato-meuspontos table .tr-operacao-credito td {
    color: #103a00 !important;
}

#extrato-meuspontos table .tr-operacao-debito td {
    color: #f76c5e !important;
}

#spinner-salvar-loja
{
    position:absolute;
    display: none;
}

#mat-tab-group-estabelecimento
{
    display: inline-grid;
}

#btn-enviar-email-vendedor
{
    background-color:#f76c5e;
    color:#FFF;
    cursor:pointer;
}

#opcao-perfil
{
    position: absolute;
    width: 100px;
    margin-top: 20px;
    margin-right: 100px;
}

#button-user {
    background-color: #fff;
    color: black;
    border: none;
    padding: 0px;
    margin-right: 5px;
    }

.item-menu-area-text {
    align-items: center;
    gap: 5px;
    cursor:pointer;
    display: inline-flex;
    width:100px;
}   

#menu-area i
{
    margin-right:5px;
}
.p-message-close-icon {
   opacity: 1000%;
}

.p-button-success .p-button-rounded .mr-2 {
    color: #b48484;
}

.text-pontos {
    color: #EF4444;
  }

  .p-button-success .p-button-rounded .mr-2 {
      color: #b48484;
  }

  .link-dev
{
	color:#0098db !important;
    display: contents;
}

#comprar-fieldset
{
    padding: 10px 0;
}

.centralizar{
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn-comprar-etapa
{
    border-radius: 5px;
    cursor: pointer;
}

.btn-comprar-etapa-anterior
{
    background: #FFF !important;
    color:#f76c5e !important;
    border:1px solid #f76c5e;
}

.btn-comprar-etapa-anterior::before
{
    background: #FFF !important;
    color:#ec584a !important;
    
}


.btn-comprar-etapa-anterior:hover::before {
    transform: scaleX(1);
    color:#ec584a !important;
    z-index: -1
}

section.comprar{
    min-height: 400px;
}

.p-inputtext:enabled:hover,
.p-inputtext:enabled:focus,
.produto-imagemprincipal
{
    border-color:#999 !important;
    box-shadow: 0 0 0 0.1rem #DDD !important;
}

#uploadform .item-img{
    cursor:pointer;
    border:1px solid #FEFEFE !important;
    width:10rem;
}

#uploadform .item-img:hover,
.produto-imagemprincipal
{
    border-color:#DDD !important;
    box-shadow:  0 0 0.5rem #CCC !important;
}

.card-comprar-add-fotos
{
    border:none !important;
}
.link-alterar-senha-perfil-cliente{
    color:#555555;
    min-width: 100%;
    background-color: #c4ffc9;
}
.link-alterar-senha-perfil-cliente:hover{
    color:#FFF;
}

.slick-track
{
    /* Alinha o slick para a esquerda */
    margin-left:0;
}

.moldura{
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center center !;
}

.p-inputnumber-input{
    text-align:center;
}


#slick-pedido-home .item,
#negociacao-home .item,
.slick-oportunidade .item
{
    width:20%;
    display: inline-block;
    /* width:20%;
    float:left;     */
}

#slick-transferir
{
    width:100%;
    display: flex;    
}

#slick-transferir .item-content
{
    flex-direction:row;
}

#slick-pedido-home .item .item-subtitle,
#negociacao-home .item .item-subtitle,
.slick-oportunidade .item .item-subtitle,
#slick-transferir .item .item-subtitle
{
    font-size:12px;
    background-color:#FFF;
    padding:5px 5px 23px 5px;
    margin:5px;
    height:20px;
    line-height: 20px;
    border-radius: 5px;
}

#slick-transferir .item .item-content{
    justify-content: space-around;
}
#slick-transferir .item .item-body{
    height: 6rem;
    margin:0 10px;
}
#slick-transferir .item .item-body p {
    margin:0;
}
#slick-transferir .item .item-body .item-text{
    font-size:0.9rem;
    color:#666;
    text-align: left;
}
#slick-transferir .item .item-body .item-title{
    height: 25px;
    font-size:1.2rem;
}

#negociacao-home,
.slick-oportunidade
{
    width: 100%;
    float: left;
}


.notify-modal{
    position: absolute;
    margin: -20px 2px;
}

.notify-modal .p-badge{
    font-size:0.7rem !important;
    min-width: 1rem;
    height: 1rem;
    line-height: 1rem;    
}

.btn-redondo
{
    background: #f76c5e;
    height: 50px;
    width: 50px;
    right: 31px;
    bottom: 18px;
    color: #fff;
    font-size: 20px;
    text-align: center;
    border-radius: 50%;
    line-height: 48px;
    border: 2px solid transparent;    
}

.loader-page
{
    display: flex;
    justify-content: center;
    margin:2rem;
}
.p-progress-spinner-td
{
    width:2rem;
    height:2rem;
}

.label-progress-spinner-td
{
    margin-left:0.6rem !important;
}

.menu-badge
{
    position: absolute;
    margin: -22px 27px;
    z-index:1000;    
}

.comprar .p-steps-number
{
    cursor:pointer;
}

.comprar .p-steps-number:hover
{
    background-color: #555555 !important;
    color:#FFF !important;
}


.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider
{
    background-color: #F98174 !important;
}

.notify-badge-pedidos p-badge,
.notify-badge-negociacoes p-badge
{
    position: absolute;
    margin: -20px 5px;
    z-index:1000;
    cursor:pointer;
}

.link-ver-todos
{
    display: block;
    width:100%;
    color:#666;
    margin:30px auto !important;
    text-align:right;
    border-top:1px solid #EEE;
    padding-top:10px;
}
.link-ver-todos:hover{
    color:#000;
}

.p-inputswitch
{
    width: 2.5rem !important;
    height: 1.5rem !important;
}

.p-inputswitch .p-inputswitch-slider:before {
    background: #ffffff;
    width: 1rem !important;
    height: 1rem !important;
    left: 0.25rem;
    margin-top: -0.5rem !important;
    border-radius: 50%;
    transition-duration: 0.2s;
}

.badge-nova-oportunidade .p-badge
{
    font-size:0.8rem !important;
}

.p-checkbox .p-checkbox-box.p-highlight,
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover
{
    border-color: #f76c5e !important;
    background: #f76c5e !important;
}

.notify-badge-propostas
{
    cursor:pointer;
}

.negociacao-lista-td-content
{
    padding:5px;
}

.upload-div-add
{
    cursor:pointer;
}

.add-banner-propaganda
{
    height: 190px;
}
.add-banner-propaganda .upload-img
{
    height: 155px;
}

.politica-box
{
    height: 75px;
    font-size:8px;
    overflow-y: auto;
    margin:15px auto;
}

.banner-visualizar-propaganda
{
    margin-top:75px !important;
    float:left;
}

#slick-resumo-compra .item-img
{
    width:25%;
}

#comprar-fieldset .botoes-grupo 
{
    text-align: right;
}

.icon-teia-rodape
{
    width:32px;
    height: 32px;
}

.titulo-comofunciona
{
    font-weight: bold !important;
}